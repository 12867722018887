export const Cdata = [
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
  {
    TrainingName: "Training Name",
    TrainingProvider: "Training Providder",
    Action: "icon",
    selected: false,
  },
];
