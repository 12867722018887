import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getFunctions } from "firebase/functions";

// const firebaseConfig = {
//   apiKey: "AIzaSyCJXEBQQNsj5tzAG-l62ZxkleVRTqEGaVM",
//   authDomain: "dev-farris-gcp.firebaseapp.com",
//   projectId: "dev-farris-gcp",
//   storageBucket: "dev-farris-gcp.appspot.com",
//   messagingSenderId: "814187992326",
//   appId: "1:814187992326:web:21fa3fa23fa8f2fe2eba87",
//   measurementId: "G-8M1J4Z2MLN"
// };

const firebaseConfig = {
  // apiKey: "AIzaSyB6YGKR-z_Ty8Yv44CpKV3-9o-f7z_94u0",
  // authDomain: "prod-linguish-gcp.firebaseapp.com",
  // projectId: "prod-linguish-gcp",
  // storageBucket: "prod-linguish-gcp.appspot.com",
  // messagingSenderId: "773379688380",
  // appId: "1:773379688380:web:2fd03ad59de10278544db9",
  // measurementId: "G-MDNGESR35L"

  apiKey: "AIzaSyCIZNhxufx2u1MlRcwphVh4M5dNzhALKtY",

  authDomain: "phrasal-descent-381322.firebaseapp.com",

  projectId: "phrasal-descent-381322",

  storageBucket: "phrasal-descent-381322.appspot.com",

  messagingSenderId: "1064336880455",

  appId: "1:1064336880455:web:4108d3748ffd9c0b3d5262",

  measurementId: "G-SP8TTX2DMV",
};
// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// const appCheck = initializeAppCheck(
//   firebaseApp,
//   { provider: "6LduGdUlAAAAAK6ofbUO8BoNFnTis96ZgAFAlLXT" } // ReCaptchaV3Provider or CustomProvider
// );
// Use these for db & auth
const db = firebaseApp.firestore();
const auth = firebase.auth();
export const functions = getFunctions(firebaseApp, "asia-south1");
export { auth, db };
