import React, { useEffect, useState } from "react";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadBytes,
  deleteObject,
} from "@firebase/storage";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import "./createQuiz.css";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import { ExpandMoreSvg } from "./icons";
import DeleteLesson from "./lessonDeletePopUp";
import CreateMcqQuestion from "./mcqQuestion";
import FillInTheBlackquestion from "./fillInTheblank";
import Choosethecorrectsequence from "./Choosethecorrectsequence";
import Matchthepair from "./Matchthepair";
import TrueAndFalse from "./TrueAndFalse";
import PictureMCQ from "./PictureMCQ";
import Matchthepairspicturebased from "./Matchthepairspicturebased";
import Choosethecorrectimage from "./Choosethecorrectimage";
import LoadingScreen from "./loading";
import { IP } from "../../../config";
import axios from "axios";
import { getCookie } from "../../../Cookies";
import MatchtheAudioWithTextbased from "./MatchTheAudioWIthText";
import CreateCrossWordQuestion from "./CrossWordQuestion";
import ReadListenSpeck from "./readListenSpeck";
import TypeWhatYouHear from "./TypeWhatYouHear";
import RearrageOfWord from "./rearrangeWords";
const CreateQuestion = ({ questionData, quizId }) => {
  const storage = getStorage();

  const [questionDataFromApi, setQuestionDataFromApi] = useState({});

  const [fetchDataFromApi, setfetchDataFromApi] = useState(0);
  const [editQuestion, setEditQuestion] = useState(false);

  const [deleteQuestionId, setDeleteQuestionId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);

  const [openLessonPopUpLoading, setopenLessonPopUpLoading] = useState(false);

  const [flagForSeconstEdit, setflagForSeconstEdit] = useState(0);

  const initialList = [];
  const [list, setList] = useState(initialList);

  useEffect(async () => {
    // alert("j");
    let temparr = [];

    setopenLessonPopUpLoading(true);

    let configCreateExtraInfo = {
      method: "get",

      url: `${IP}/courseContent/questionPool/poolInfo/${quizId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "",
    };
    axios(configCreateExtraInfo)
      .then((res) => {
        let questionData = res.data;
        setopenLessonPopUpLoading(false);

        for (let i = 0; i < questionData.length; i++) {
          temparr.push({
            data: questionData[i].data,
            id: questionData[i].questionId,
            name: questionData[i].questionName,
            type: questionData[i].questionType,
          });

          if (questionData.length - 1 == i) {
            setopenLessonPopUpLoading(false);
            setList(temparr);
          }
        }
      })
      .catch((err) => {
        setopenLessonPopUpLoading(false);
      });
    // let questionSetInfo = await getQuestionSetInfo({ questionSetId: quizId});
  }, []);

  useEffect(async () => {
    if (fetchDataFromApi > 0) {
      setopenLessonPopUpLoading(true);
      let temparr = [];

      let configCreateExtraInfo = {
        method: "get",

        url: `${IP}/courseContent/questionPool/poolInfo/${quizId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: "",
      };
      axios(configCreateExtraInfo)
        .then((res) => {
          let questionData = res.data;
          for (let i = 0; i < questionData.length; i++) {
            temparr.push({
              data: questionData[i].data,
              id: questionData[i].questionId,
              name: questionData[i].questionName,
              type: questionData[i].questionType,
            });

            console.table(questionData[i]);
            if (questionData.length - 1 == i) {
              setopenLessonPopUpLoading(false);
              setList(temparr);
            }
          }
        })
        .catch((e) => {
          setopenLessonPopUpLoading(false);
        });

      //toggleQuestionForm()
    }
  }, [fetchDataFromApi]);

  const [qustype, setqustype] = useState("0");

  function submit(e) {
    e.preventDefault();
  }

  async function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }

    let configCreateExtraInfo = {
      method: "patch",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/question`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        questionPoolAssociated: quizId,
        questionarr: orderList,
        edit: true,
      }),
    };
    await axios(configCreateExtraInfo);
  }

  const toggleQuestionForm = (e) => {
    // resetData();
    document.getElementById(quizId + "questionForm").style.display =
      document.getElementById(quizId + "questionForm").style.display == "none"
        ? "block"
        : "none";
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(lesson) {
    // console.log(lesson)
    setqustype(lesson.type);
    setEditQuestion(true);
    setflagForSeconstEdit(flagForSeconstEdit + 1);
    setQuestionDataFromApi(lesson);
    toggleQuestionForm();
  }

  async function del(question) {
    setopenLessonPopUp(true);
    setDeleteQuestionId(question);
  }

  const delfunction = async (question) => {
    // db.collection("question").doc(question.id).delete();

    let configCreateExtraInfo = {
      method: "delete",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/question`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        questionId: question.id,
      }),
    };
    await axios(configCreateExtraInfo);
    alert("Deleted " + question.name);
    const item1 = [];
    for (let i = 0; i < list.length; i++) {
      if (question.id !== list[i].id) {
        item1.push(list[i]);
      }
    }
    setList(item1);
    saveOrder(item1);
  };
  return (
    <section className="quizForm-container">
      <DeleteLesson
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteQuestionId}
      />
      <LoadingScreen
        open={openLessonPopUpLoading}
        onClose={() => setopenLessonPopUpLoading(false)}
      />
      <button
        className="quizFrom-button"
        onClick={() => {
          toggleQuestionForm();
          setEditQuestion(false);
        }}
      >
        Add Question
      </button>
      <div
        className="quizFrom-mainFrom"
        id={quizId + "questionForm"}
        style={{ display: "none" }}
      >
        <div className="quizFrom-mainFrom-head">Question Form</div>

        <form id={quizId + "question-form"} onSubmit={submit}>
          <div className="quizForm-mainForm-infoRow">
            <label className="quizForm-mainForm-label">
              Type of question :{" "}
            </label>
            <select
              className="quizForm-mainForm-select"
              value={qustype}
              onChange={(event) => {
                setqustype(event.target.value);
              }}
            >
              <option value="0">Multiple Choice Question</option>
              {/* <option value="trueAndFalse">True and False</option> */}
              <option value="6">Choose the correct sequence</option>
              <option value="10">Choose the arrangement of words </option>

              <option value="3">Match the pair(Text to Text)</option>
              <option value="2">Fill in the blank</option>
              <option value="7">Picture MCQ</option>
              <option value="1">Pairing (word to images)</option>
              <option value="4">Match audio with text</option>
              <option value="8">Crossword mcq</option>

              {/* <option value="7">Choose the correct image</option> */}
              <option value="9">Read listen speak</option>

              <option value="5">Type what you hear</option>
            </select>
          </div>

          {qustype == "0" ? (
            <CreateMcqQuestion
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
          {qustype == "6" ? (
            <Choosethecorrectsequence
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
          {qustype == "10" ? (
            <RearrageOfWord
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
          {qustype == "2" ? (
            <FillInTheBlackquestion
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
          {qustype == "3" ? (
            <Matchthepair
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
          {/* {qustype == "trueAndFalse" ? (
            <TrueAndFalse
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )} */}

          {qustype == "7" ? (
            <PictureMCQ
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
          {qustype == "1" ? (
            <Matchthepairspicturebased
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
          {qustype == "Choose the correct image" ? (
            <Choosethecorrectimage
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}

          {qustype == "4" ? (
            <MatchtheAudioWithTextbased
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
          {qustype == "8" ? (
            <CreateCrossWordQuestion
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
          {qustype == "9" ? (
            <ReadListenSpeck
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
          {qustype == "5" ? (
            <TypeWhatYouHear
              quizId={quizId}
              questionData={questionDataFromApi}
              editQuestion={editQuestion}
              setfetchDataFromApi={setfetchDataFromApi}
              fetchDataFromApi={fetchDataFromApi}
              flagForSeconstEdit={flagForSeconstEdit}
            />
          ) : (
            ""
          )}
        </form>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <ul
              id="questionList"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                display: "block",
                overflow: "hidden",
                width: "100%",
                listStyleType: "none",
                margin: "0px",
                padding: "0px",
              }}
            >
              {list.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      key={item.id}
                    >
                      <Accordion>
                        <AccordionSummary
                          {...provided.dragHandleProps}
                          expandIcon={<ExpandMoreSvg />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{
                            backgroundColor: "#0B2D2D",
                            marginTop: "10px",
                            color: "white",
                            borderRadius: "5px",
                          }}
                        >
                          <Typography>{item.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <MdDelete
                            style={{ float: "right", margin: "20px" }}
                            size={28}
                            onClick={() => del(item)}
                          />
                          <div onClick={() => edit(item)}>
                            {" "}
                            <MdEdit
                              style={{ float: "right", margin: "20px" }}
                              size={28}
                            />{" "}
                          </div>
                          <p>{item.id}</p>
                        </AccordionDetails>
                      </Accordion>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </section>
  );
};

export default CreateQuestion;
