import { Box, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import "../../styles/Navbar/Navbar.css";
// import { useTranslation } from "react-i18next";
import "../../Translater/Translater";
import notify from "../img/navbar/notify.svg";
import pp from "../img/navbar/profilePicture.svg";
import settings from "../img/navbar/settings.svg";
import NotificationPopup from "../NotificationPopup/NotificationPopup";
function Navbar({ page }) {
  // const [lagn, setLang] = useState("en");
  // const { i18n } = useTranslation();

  const [showOption, setShowOption] = useState(false);

  return (
    <Box style={{ zIndex: 9999999 }} sx={{ height: "80px", py: 1, background: "#fff", position: "sticky", top: 0, left: 0 }}>
      <Container>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box>
            <Typography sx={{ fontSize: "22px", fontWeight: "600", color: "#00495F", textTransform: "capitalize" }}>{page}</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "25px", alignItems: "center", }}>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Box>
                <img src={pp} alt="loading" />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "22px", fontWeight: "600", color: "#00495F", }}>
                  {getCookie("NAME")}
                </Typography>
                <Typography>
                  {getCookie("EMPID")}
                </Typography>
              </Box>
            </Box>
            <Box>
              <img onClick={() => setShowOption(!showOption)} src={notify} alt="loading" />
              {
                showOption && <Box sx={{ position: "absolute", left: "0px", top: "100px", right: "0px", bottom: "0px", zIndex: 9999, width: "100%" }}>
                  <NotificationPopup />
                </Box>
              }
            </Box>
            <Box className="nav-cnt-right-settings">
              <Link to="/user/settings">
                <img src={settings} alt="loading" />{" "}
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Navbar;
