import React, { createRef, useEffect } from "react";
import { useState } from "react";
import $ from "jquery";
import { MdEdit, MdDelete } from "react-icons/md";
import CreateQuiz from "./CreateQuiz";
import { SvgVector, EditSvg, ExpandMoreSvg, DeleteSVg } from "./icons";
import { functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./createTopic.css";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";
import CreateQuestion from "./createQuestion";
import DeleteLesson from "./lessonDeletePopUp";

const CreateQuestionPool = ({ quizId, editData }) => {
  const initialList = [];
  const [list, setList] = useState(initialList);
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [deleteQuestionId, setDeleteQuestionId] = useState("");

  const createQustionPool = httpsCallable(functions, "createQustionPool");
  const addQuizPoolId = httpsCallable(functions, "addQuizPoolId");
  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const getQuestionPool = httpsCallable(functions, "getQuestionPool");
  const updateQustionPool = httpsCallable(functions, "updateQustionPool");
  // const addCourseQuizOrder = httpsCallable(functions, "addCourseQuizOrder");
  // const addLessonQuizOrder = httpsCallable(functions, "addLessonQuizOrder");
  // const addTopicQuizOrder = httpsCallable(functions, "addTopicQuizOrder");
  // // addCourseQuizOrder

  const [questionPoolId, setQuestionPoolId] = useState("");
  const [editable, setEditable] = useState(false);
  const [checkFailed, setCheckFailed] = useState(false);
  const [poolEditTrue, setpoolEditTrue] = useState(false);
  const [quizDetails, setQuizDetails] = useState({
    quizName: "",
    passScore: "",
    retry: "",
    answerAll: "yes",
    timeLimit: "",
    randomizeQuestions: "yes",
    skipQuestion: "yes",
    randomAnswers: "yes",
    successMessage: "",
    retakeMessage: "",
  });

  const questionPoolFormRef = createRef();

  useEffect(() => {
    if (quizId) {
      let configProfile = {
        method: "get",
        // params: { userId: uid },
        url: `${IP}/courseContent/questionPool/${quizId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
      };

      axios(configProfile).then((ress) => {
        console.log(ress.data);

        if (ress.data != null) {
          for (let i = 0; i < ress.data.length; i++) {
            setList((prev) =>
              prev.concat({
                id: ress.data[i].questionPoolId
                  ? ress.data[i].questionPoolId
                  : "",
                name: ress.data[i].poolName ? ress.data[i].poolName : "",
              })
            );
          }
        }
      });
    }
  }, [editData]);

  const handleInput = (e) => {
    const { value, name } = e.target;
    setQuizDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function checkForm() {
    let keys = Object.keys(quizDetails);
    let res = "allIsWell";
    if (quizDetails.quizName == "") {
      return "name";
    }
    return res;
  }

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    console.log(questionPoolId, quizId);
    let configProfile = {
      method: "patch",
      // params: { userId: uid },
      url: `${IP}/courseBuilder/questionPool`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        questionPoolId: orderList,
        quizId: quizId,
        edit: true,
      }),
    };

    axios(configProfile);
    // addQuizPoolId({
    //   questionPoolId: orderList,
    //   quizId: quizId,
    //   edit: true,
    // });
  }
  const addQuiz = async () => {
    let check = checkForm();
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }
    setCheckFailed(false);

    if (poolEditTrue) {
      // updateQustionPool({
      //   questionPoolId: questionPoolId,

      //   poolName: quizDetails.quizName,
      // });

      let configProfile = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/questionPool`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          questionPoolId: questionPoolId,
          associatedQuizId: quizId,
          poolName: quizDetails.quizName,
        }),
      };

      axios(configProfile);
      alert("updated");
      let newArr = list.map((el) => {
        if (el.id == questionPoolId) {
          return {
            id: questionPoolId,

            name: quizDetails.quizName,
          };
        }
        return el;
      });
      setList(newArr);
      setpoolEditTrue(false);
    } else {
      const newList = list.concat({
        id: questionPoolId,
        name: quizDetails.quizName,
      });

      saveOrder(newList);

      // createQustionPool({

      //     });

      let configProfile = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/questionPool`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          questionPoolId: questionPoolId,
          associatedQuizId: quizId,
          poolName: quizDetails.quizName,
        }),
      };

      axios(configProfile);

      setList(newList);

      alert("Added");
    }
    setQuestionPoolId("");
    setQuizDetails({
      quizName: "",
    });
    toggleForm();
  };

  const toggleForm = (e) => {
    questionPoolFormRef.current.style.display =
      questionPoolFormRef.current.style.display === "none" ? "" : "none";
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(quiz) {
    setEditable(true);
    setpoolEditTrue(true);
    questionPoolFormRef.current.style.display = "";
    let params = {
      quizName: quiz.name,
    };
    setQuizDetails(params);
    setQuestionPoolId(quiz.id);
  }

  function updateQuiz() {
    let check = checkForm();
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }
    setCheckFailed(false);
    for (let i = 0; i < list.length; i++) {
      if (questionPoolId === list[i].id) {
        list[i] = {
          id: questionPoolId,
          name: quizDetails.quizName,
        };
      }
    }
    setList(list);

    alert("Updated");
    setEditable(false);
    questionPoolFormRef.current.style.display = "none";
  }

  async function del(questionPoolId, index) {
    let newArr = [...list];
    setDeleteQuestionId({ id: questionPoolId });
    setopenLessonPopUp(true);
  }
  const delfunction = async (question) => {
    // db.collection("question").doc(question.id).delete();

    let configProfile = {
      method: "delete",
      // params: { userId: uid },
      url: `${IP}/courseBuilder/questionPool`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        questionPoolId: question.id,
      }),
    };

    axios(configProfile)
      .then((res) => {
        let newArr = list.filter((el) => el.id != question.id);

        setList(newArr);
        saveOrder(newArr);
        alert("deleted");
      })
      .catch((er) => {
        alert("something went wrong");
      });
  };
  return (
    <>
      <section className="createQuiz-container">
        <DeleteLesson
          open={openLessonPopUp}
          onClose={() => setopenLessonPopUp(false)}
          delfun={(topic) => {
            delfunction(topic);
          }}
          topicId={deleteQuestionId}
        />
        <button
          className="createQuiz-btn"
          onClick={() => {
            setEditable(false);
            toggleForm();
          }}
        >
          Add QuestionPool
        </button>
        <div
          className="createQuiz-quizForm"
          ref={questionPoolFormRef}
          id={"quizForm"}
          style={{ display: "none" }}
        >
          <div className="createQuiz-heading">QuestionPool</div>
          <div className="createQuiz-row">
            <label className="createQuiz-inputLabel">Question Pool Name</label>
            <input
              type="text"
              className="createQuiz-inputBar"
              id={"quizName"}
              name="quizName"
              value={quizDetails.quizName}
              onChange={(event) => {
                handleInput(event);
                if (!editable) {
                  let value = event.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();
                  setQuestionPoolId(value + "_" + time);
                }
              }}
            />
          </div>

          <div className="createQuiz-row">
            <label className="createQuiz-inputLabel">Question Pool Id</label>
            <input
              type="text"
              className="createQuiz-inputBar"
              id={"quizName"}
              disabled={true}
              name="quizName"
              value={questionPoolId}
            />
          </div>

          {checkFailed && <p>Enter the Required Fields</p>}
          <div className="createQuiz-row">
            <button className="createQuiz-btn-save" onClick={addQuiz}>
              Save QuestionPool
            </button>
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                id="quizList"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  listStyleType: "none",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {list.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={item.id}
                        className="quiz-dragable"
                      >
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreSvg />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              backgroundColor: "#34BE82",
                              color: "white",
                              borderRadius: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <SvgVector />
                              <div className="quiz-accordion-heading">
                                {item.name}
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="quiz-accordion-details-head">
                              <p>{item.name}</p>
                            </div>
                            <p>Associated quiz {item.id} - </p>
                            <MdEdit
                              style={{ float: "right", margin: "20px" }}
                              size={28}
                              onClick={() => edit(item)}
                            />
                            <MdDelete
                              style={{ float: "right", margin: "20px" }}
                              size={28}
                              onClick={() => del(item.id, index)}
                            />
                            <p style={{ marginTop: "20px" }}></p>
                            {/* 
                            <CreateQuiz
                              questionPoolId={item.id}
                              editData={editData}
                            /> */}
                            <CreateQuestion
                              quizId={item.id}
                              editData={editData}
                              questionData={editData}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>
    </>
  );
};

export default CreateQuestionPool;
