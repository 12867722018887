import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Navbar from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import "./Trainings.css";

function Trainings() {
  const { clrs } = useSelector((state) => state.createClr);

  return (
    <div className="courselist">
      <Navbar page={t("Training")} />
      <div className="courselist-main">
        <Sidebar page={"training"} hidepage={false} />
        <div className="courselist-main-cnt">
          {/* <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">{t("InternalTrainingLMS")}</div>
          </div> */}
          <div className="admin-dashboard-main-bottom">
            <div className="admin-dashboard-main-bottom-cnt">
              <div className="admin-dashboard-main-bottom-left-icon">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="50"
                    height="50"
                    rx="12"
                    fill="url(#paint0_linear_134_2559)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_134_2559"
                      x1="-8.38864e-08"
                      y1="24.359"
                      x2="52.7322"
                      y2="24.359"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#007848" />
                      <stop offset="1" stop-color="#004577" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="admin-dashboard-main-bottom-right">
                <div className="admin-dashboard-main-bottom-right-top">
                  {t("Content Uploader LMS")}{" "}
                </div>
                <div className="admin-dashboard-main-bottom-right-btm">
                  {t("ListOf LMS")}
                </div>
                <div className="admin-dashboard-main-bottom-right-btn">
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/training/internal"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("View")}</div>
                      </button>
                    </Link>
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/courseBuilder"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Add")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="admin-dashboard-main-bottom-cnt">
              <div className="admin-dashboard-main-bottom-left-icon">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="50"
                    height="50"
                    rx="12"
                    fill="url(#paint0_linear_134_2559)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_134_2559"
                      x1="-8.38864e-08"
                      y1="24.359"
                      x2="52.7322"
                      y2="24.359"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#007848" />
                      <stop offset="1" stop-color="#004577" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="admin-dashboard-main-bottom-right">
                <div className="admin-dashboard-main-bottom-right-top">
                  {t("Category")}
                </div>
                <div className="admin-dashboard-main-bottom-right-btm">
                  {t("List of category")}
                </div>
                <div className="admin-dashboard-main-bottom-right-btn">
                  <div className="admin-dashboard-main-bottom-item-left-btm"></div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/training/category"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="admin-dashboard-main-bottom-cnt">
              <div className="admin-dashboard-main-bottom-left-icon">
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="50"
                    height="50"
                    rx="12"
                    fill="url(#paint0_linear_134_2559)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_134_2559"
                      x1="-8.38864e-08"
                      y1="24.359"
                      x2="52.7322"
                      y2="24.359"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#007848" />
                      <stop offset="1" stop-color="#004577" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="admin-dashboard-main-bottom-right">
                <div className="admin-dashboard-main-bottom-right-top">
                  {t("Users")}
                </div>
                <div className="admin-dashboard-main-bottom-right-btm">
                  {t("List of delete request")}
                </div>
                <div className="admin-dashboard-main-bottom-right-btn">
                  <div className="admin-dashboard-main-bottom-item-left-btm"></div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/training/DeleteUserReq"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trainings;
