import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
//import axios from 'axios';
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../../Firebase";

import { t } from "i18next";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import "../category/category.css";

import { EditSvg, DeleteSVg } from "../Builder/icons";
import Sidebar from "../../Component/Sidebar";
import Navbar from "../../Component/NavbarTop";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";

function DeleteUserReq() {
  const navigate = useNavigate();
  const { clrs } = useSelector((state) => state.createClr);
  const [template, settemplate] = useState([]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);
  const [courseName, setCourseName] = useState("");
  const [courseId, setCourseId] = useState("");
  const [editDataOfCourse, seteditDataOfCourse] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [categoryEnum, setcategoryEnum] = useState("");

  const deletetemplate = (id) => {
    //console.log(id);
    let configProfile = {
      method: "delete",
      // params: { userId: uid },
      url: `${IP}/adminUserProfile`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        uid: id,
      }),
    };

    axios(configProfile)
      .then((res) => {
        // console.log(res);
        alert("deleted successfully");
        setdeltemp(!deltemp);
      })
      .catch((er) => {
        alert("something went wrong");
      });
  };

  const rejectUserDelete = (id) => {
    //console.log(id);
    let configProfile = {
      method: "post",
      // params: { userId: uid },
      url: `${IP}/adminUserProfile`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        uid: id,
      }),
    };

    axios(configProfile)
      .then((res) => {
        // console.log(res);
        alert("rejected successfully");
        setdeltemp(!deltemp);
      })
      .catch((er) => {
        alert("something went wrong");
      });
  };
  const fetchdata = () => {
    setLoading(true);
    let configProfile = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/adminUserProfile`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };

    axios(configProfile)
      .then((res) => {
        setLoading(false);
        // console.log("cerificate fetch:", res);
        settemplate(res.data);
      })
      .catch((err) => {
        setLoading(false);
        //   console.log(err);
      });
  };

  useEffect(() => {
    fetchdata();
  }, [deltemp]);

  return (
    <>
      <section className="dashboard">
        {/* <NavbarTop page={t("Certificate Settings")} /> */}
        {/* <Navbar /> */}
        <Navbar page={t("User list")} />

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"LMSCourseBuilder"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              <div className="setting-table-main">
                <div
                  className="bigger-container"
                  style={{ textAlign: "center" }}
                >
                  <div style={{ width: "40%" }}> User name</div>

                  <div style={{ width: "40%" }}>Action</div>
                  {/* <div style={{ width: "20%" }}>Make Default</div> */}
                </div>
                <div className="">
                  {showLoading && (
                    <div
                      className="admin-overdue-bottom-table-cnt-certi"
                      key={0}
                    >
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td">
                        <div className="spinner-container">
                          <div className="loading-spinner"></div>
                        </div>
                      </div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#004577" }}
                      ></div>
                    </div>
                  )}

                  {template?.map((ele) => {
                    return (
                      <div
                        className="outer-inner-container"
                        style={{ textAlign: "center" }}
                      >
                        <div className="templatename" style={{ width: "40%" }}>
                          {ele.name}
                        </div>

                        <div
                          className="button-box"
                          style={{
                            width: "40%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "20px",
                          }}
                        >
                          <div className="button-box-inner mx-3 my-1">
                            <button
                              className="action-btn"
                              style={{
                                background: "red",
                                width: "100px",
                                height: "40px",
                                border: "none",
                                borderRadius: "10px",
                                color: "white",
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                deletetemplate(ele?.uid);
                              }}
                            >
                              {/* <img src={} alt="" /> */}
                              Delete
                            </button>
                          </div>
                          <div className="button-box-inner mx-3 my-1">
                            <button
                              className="action-btn"
                              style={{
                                background: "green",
                                width: "100px",
                                height: "40px",
                                border: "none",
                                borderRadius: "10px",
                                color: "white",
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                rejectUserDelete(ele?.uid);
                              }}
                            >
                              <img src={EditSvg} />
                              Reject
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  marginTop: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  className="course-builder-button"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DeleteUserReq;
