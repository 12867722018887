import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { DrawerContext } from "../../../utilities/context/DrawerProvider";
import {
  CertificatesIcons,
  CourseLibraryIcons,
  HomeIcons,
  LeftArrowIcons,
  ProfileIcons,
  SettingsIcons,
  TrainingIcons,
  UserReportsIcons,
} from "../../../utilities/Icons/Icons";
import logo from "../../../utilities/images/AGE-logo_1-removebg-preview 1.png";
import hidelogo from "../../../utilities/images/hidepage-logo.png";
import "../../Translater/Translater";
import classes from "./Sidebar.module.css";

function SideNavbar({ page }) {
  const { t } = useTranslation();
  const { clrs } = useSelector((state) => state.createClr);
  const { hidepage, setHidepage } = useContext(DrawerContext);
  const { pathname } = useLocation();

  if (
    pathname ===
    "/user/course-library/single-course-overview/single-course-content"
  ) {
    setHidepage(false);
  } else if (
    pathname ===
    "/user/course-library/single-course-overview/single-course-content/course-content"
  ) {
    setHidepage(false);
  } else if (
    pathname ===
    "/user/course-library/single-course-overview/single-course-content/course-materials"
  ) {
    setHidepage(false);
  } else {
    setHidepage(true);
  }
  setHidepage(false);

  // useEffect(() => {
  //   console.log(clrs);
  // }, [clrs]);
  const [svgClr, setSvgClr] = useState([
    // { iconColor: "#188775", cntColor: "#E6FCF5", txtClr: "#188775" },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    // { iconColor: null, cntColor: null, txtClr: null },
    {
      iconColor: page === "home" ? clrs.SidebariconClr : null,
      cntColor: page === "home" ? clrs.SidebarcntClr : null,
      txtClr: page === "home" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "courselibrary" ? clrs.SidebariconClr : null,
      cntColor: page === "courselibrary" ? clrs.SidebarcntClr : null,
      txtClr: page === "courselibrary" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "training" ? clrs.SidebariconClr : null,
      cntColor: page === "training" ? clrs.SidebarcntClr : null,
      txtClr: page === "training" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "profile" ? clrs.SidebariconClr : null,
      cntColor: page === "profile" ? clrs.SidebarcntClr : null,
      txtClr: page === "profile" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "yourassignment" ? clrs.SidebariconClr : null,
      cntColor: page === "yourassignment" ? clrs.SidebarcntClr : null,
      txtClr: page === "yourassignment" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "certificate" ? clrs.SidebariconClr : null,
      cntColor: page === "certificate" ? clrs.SidebarcntClr : null,
      txtClr: page === "certificate" ? clrs.SidebartxtClr : null,
    },
    {
      iconColor: page === "userreports" ? clrs.SidebariconClr : null,
      cntColor: page === "userreports" ? clrs.SidebarcntClr : null,
      txtClr: page === "userreports" ? clrs.SidebartxtClr : null,
    },
  ]);

  const handleclick = (id) => {
    const narr = svgClr.map((item, i) => {
      if (i === id - 1) {
        return {
          iconColor: clrs.SidebariconClr,
          cntColor: clrs.SidebarcntClr,
          txtClr: clrs.SidebartxtClr,
        };
      } else {
        return {
          iconColor: null,
          cntColor: null,
          txtClr: null,
        };
      }
    });
    console.log(narr);
    setSvgClr(narr);
  };
  return (
    <Box
      sx={{
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0,
      }}
    >
      {hidepage && (
        <Box
          sx={{
            width: "270px",
          }}
        >
          <Box className={classes.sidebar}>
            <Box sx={{ padding: "0px 16px" }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={logo}
                  style={{ width: "200px", margin: "10px 0px" }}
                  alt=""
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#007C84",
                    fontWeight: 300,
                    padding: "0px 15px",
                    marginTop: "10px",
                  }}
                >
                  {t("DASHBOARD")}
                </Typography>
              </Box>
              <Box>
                <NavLink
                  to="home"
                  className={({ isActive }) =>
                    isActive ? classes.active : classes.inactive
                  }
                >
                  <Box
                    sx={{
                      width: "90%",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <HomeIcons />
                    <Typography sx={{ fontWeight: 600 }}>
                      {t("Home")}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "10%" }}>
                    <LeftArrowIcons />
                  </Box>
                </NavLink>
                <NavLink
                  to="course-library"
                  className={({ isActive }) =>
                    isActive ? classes.active : classes.inactive
                  }
                >
                  <Box
                    sx={{
                      width: "90%",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <CourseLibraryIcons />

                    <Typography sx={{ fontWeight: 600 }}>
                      {t("CourseLibrary")}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "10%" }}>
                    <LeftArrowIcons />
                  </Box>
                </NavLink>
                <NavLink
                  to="training"
                  className={({ isActive }) =>
                    isActive ? classes.active : classes.inactive
                  }
                >
                  <Box
                    sx={{
                      width: "90%",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <TrainingIcons />
                    <Typography sx={{ fontWeight: 600 }}>
                      {t("Training")}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "10%" }}>
                    <LeftArrowIcons />
                  </Box>
                </NavLink>
                <NavLink
                  to="profile"
                  className={({ isActive }) =>
                    isActive ? classes.active : classes.inactive
                  }
                >
                  <Box
                    sx={{
                      width: "90%",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <ProfileIcons />
                    <Typography sx={{ fontWeight: 600 }}>
                      {t("Profile")}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "10%" }}>
                    <LeftArrowIcons />
                  </Box>
                </NavLink>
                <NavLink
                  to="certificates"
                  className={({ isActive }) =>
                    isActive ? classes.active : classes.inactive
                  }
                >
                  <Box
                    sx={{
                      width: "90%",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <CertificatesIcons />
                    <Typography sx={{ fontWeight: 600 }}>
                      {t("Certificates")}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "10%" }}>
                    <LeftArrowIcons />
                  </Box>
                </NavLink>
                <NavLink
                  to="user-reports"
                  className={({ isActive }) =>
                    isActive ? classes.active : classes.inactive
                  }
                >
                  <Box
                    sx={{
                      width: "90%",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <UserReportsIcons />
                    <Typography sx={{ fontWeight: 600 }}>
                      {t("UserReports")}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "10%" }}>
                    <LeftArrowIcons />
                  </Box>
                </NavLink>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  position: "absolute",
                  bottom: "50px",
                }}
              >
                <NavLink to="settings">
                  <Box
                    sx={{
                      background: "#F5FAFA",
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <SettingsIcons />
                  </Box>
                </NavLink>
                <Box>
                  <button
                    style={{
                      width: "170px",
                      background: "linear-gradient(to right, #007848, #004577)",
                      color: "#fff",
                      padding: "10px 0px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      border: "none",
                      outline: "none",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    {t("GenerateReport")}
                  </button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {!hidepage && (
        <Box
          sx={{
            width: "100px",
          }}
        >
          <Box className={classes.sidebar}>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={hidelogo}
                  style={{ width: "50px", margin: "10px 0px" }}
                  alt=""
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#007C84",
                    fontWeight: 300,
                    marginTop: "10px",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  {t("DASHBOARD")}
                </Typography>
              </Box>
              <Box sx={{ padding: "0px 16px" }}>
                <NavLink
                  to="home"
                  className={({ isActive }) =>
                    isActive ? classes.activeHide : classes.inactive
                  }
                >
                  <Box
                    sx={{
                      width: "90%",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                      justifyContent: "center",
                    }}
                  >
                    <HomeIcons />
                  </Box>
                </NavLink>
                <NavLink
                  to="course-library"
                  className={({ isActive }) =>
                    isActive ? classes.active : classes.inactive
                  }
                >
                  <Box
                    sx={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <CourseLibraryIcons />
                  </Box>
                </NavLink>
                <NavLink
                  to="training"
                  className={({ isActive }) =>
                    isActive ? classes.active : classes.inactive
                  }
                >
                  <Box
                    sx={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <TrainingIcons />
                  </Box>
                </NavLink>
                <NavLink
                  to="profile"
                  className={({ isActive }) =>
                    isActive ? classes.active : classes.inactive
                  }
                >
                  <Box
                    sx={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <ProfileIcons />
                  </Box>
                </NavLink>
                <NavLink
                  to="certificates"
                  className={({ isActive }) =>
                    isActive ? classes.active : classes.inactive
                  }
                >
                  <Box
                    sx={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <CertificatesIcons />
                  </Box>
                </NavLink>
                <NavLink
                  to="user-reports"
                  className={({ isActive }) =>
                    isActive ? classes.active : classes.inactive
                  }
                >
                  <Box
                    sx={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <UserReportsIcons />
                  </Box>
                </NavLink>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "15px",
                  position: "absolute",
                  bottom: "50px",
                  left: "16px",
                }}
              >
                <NavLink to="settings">
                  <Box
                    sx={{
                      background: "#F5FAFA",
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <SettingsIcons />
                  </Box>
                </NavLink>
                <Box>
                  <button
                    style={{
                      width: "70px",
                      background: "linear-gradient(to right, #007848, #004577)",
                      color: "#fff",
                      padding: "15px 0px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      border: "none",
                      outline: "none",
                      fontSize: "8px",
                      fontWeight: 600,
                    }}
                  >
                    {t("GenerateReport")}
                  </button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default SideNavbar;
