import React, { useEffect } from "react";
import { useState } from "react";
import CreateTopic from "./CreateTopic";
import CreateStage from "./CreateStage";
import $, { hasData } from "jquery";
import { Timestamp } from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import Item from "./Item";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import "./styles/item.css";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";
import DeleteLesson from "./lessonDeletePopUp";
const CreateLesson = ({ courseId, unitId, editData }) => {
  const storage = getStorage();
  //Topic
  //deleteLevel
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [deleteQuestionId, setDeleteQuestionId] = useState("");

  const [levelName, setLevelName] = useState("");
  const [levelDescription, setLevelDescription] = useState("");
  const [daysAfterTopic, setDaysAfterTopic] = useState(0);
  const [startDateTopic, setStartDateTopic] = useState("");
  const [levelId, setLevelId] = useState("");
  const [editable, setEditable] = useState(false);
  const [checkFailed, setCheckedFailed] = useState(false);
  const [list, setList] = useState([]);
  const [isDraft, setIsDraft] = useState([]);
  const [addcompletionText, setAddcompletionText] = useState("");

  const [adddate, setAdddate] = useState("");
  const [leveldataedit, setlevelDataEdit] = useState(false);

  const [levelSetList, setLevelList] = useState([]);
  const [unitquizdata, setUnitquizdata] = useState([]);
  console.log("i am level");

  useEffect(() => {
    setList([]);
    setUnitquizdata([]);
    let configProfile = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/courseContent/units/extraInfo/${unitId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };

    axios(configProfile).then(async (res) => {
      for (let i = 0; i < res.data.unitLevels.length; i++) {
        let configProfile = {
          method: "get",
          // params: { userId: uid },
          url: `${IP}/courseContent/level/${res.data.unitLevels[i]}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
        };

        let levelData = await axios(configProfile);

        let configIntem = {
          method: "get",
          // params: { userId: uid },
          url: `${IP}/courseContent/item/level/${res.data.unitLevels[i]}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
        };

        let itemfromfirestore = await axios(configIntem);

        let levelquizarr = [];

        //////////////////////
        if (unitId != null) {
          let quizz = levelData.data.levelQuizzes;
          console.log(quizz);
          if (quizz?.length > 0) {
            for (let j = 0; j < quizz.length; j++) {
              let configQuiz = {
                method: "get",
                // params: { userId: uid },
                url: `${IP}/courseContent/quiz/${quizz[j]}`,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${getCookie("bearer")}`,
                },
              };

              let levelquizdata = await axios(configQuiz);
              // let levelquizdata = await getQuizInfo({ quizId: quizz[j] });
              console.log(levelquizdata.data);

              // let milisecon =
              //   levelquizdata.data.scheduledDateOfRelease.seconds * 1000;

              // let fulldate = new Date(
              //   levelquizdata.data.scheduledDateOfRelease._seconds * 1000
              // );

              // let dateString = fulldate.toISOString().split("T")[0];

              levelquizarr.push({
                id: levelquizdata.data.quizId,
                name: levelquizdata.data.quizName,
                passScore: levelquizdata.data.passingScore,

                timeLimit: levelquizdata.data.timeLimit,
                success: levelquizdata.data.completionText,
                // scheduledDateOfRelease: dateString,
              });
            }
          }
        }

        /////////////////////////////////////
        // let milisecon = levelData.data.scheduledDateOfRelease.seconds * 1000;
        let fulldate = new Date(
          levelData?.data?.scheduledDateOfRelease?._seconds * 1000
        );

        let dateString = fulldate.toISOString().split("T")[0];
        console.log(levelData.data);
        setList((prev) =>
          prev.concat({
            id: levelData.data.levelId,
            name: levelData.data.levelName,
            description: levelData.data.levelDescription,
            isDraft: levelData.data.isDraft,
            hasQuiz: levelData.data.hasQuiz,
            completionText: levelData.data.completionText,
            schedule: dateString,
            levelquizarr: levelquizarr,
            itemsArr: itemfromfirestore.data,
          })
        );
        // saveOrder(newList);
        console.log("rahul", list);

        /////////////////////////////////

        ////////////////
      }
    });

    /////////////// level bquiz strat from here//////////////////

    ///////////// level quiz end

    ///////////////////////////

    ///////////////  unitquiz/////////////

    if (unitId != null) {
      (async () => {
        // let ressdata = await fetchUnitExtraInfo({ unitId: unitId });
        let configProfile = {
          method: "get",
          // params: { userId: uid },
          url: `${IP}/courseContent/units/extraInfo/${unitId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
        };

        let ressdata = await axios(configProfile);
        let quizz = ressdata?.data?.unitQuizzes;
        // alert("inthe");

        // console.log(quizz);
        if (quizz?.length > 0) {
          for (let j = 0; j < quizz.length; j++) {
            let configQuiz = {
              method: "get",
              // params: { userId: uid },
              url: `${IP}/courseContent/quiz/${quizz[j]}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
            };

            let levelquizdata = await axios(configQuiz);
            // let levelquizdata = await getQuizInfo({ quizId: quizz[j] });
            // alert("inthe");
            // console.log(levelquizdata.data);

            // let fulldate = new Date(
            //   levelquizdata.data.scheduledDateOfRelease._seconds * 1000
            // );

            // let dateString = fulldate.toISOString().split("T")[0];

            setUnitquizdata((prev) =>
              prev.concat({
                id: levelquizdata.data.quizId,
                name: levelquizdata.data.quizName,
                passScore: levelquizdata.data.passingScore,
                quizDescription: levelquizdata.data.quizDescription,

                timeLimit: levelquizdata.data.timeLimit,
                success: levelquizdata.data.completionText,
                // scheduledDateOfRelease: dateString,
              })
            );
          }
        }
      })();
    }

    ////////////////////////// unit quiz end///////
  }, [editData]);

  function checkForm() {
    if (levelName == "") return "levelName";
    if (levelDescription == "") return "levelDescription";
    return "allIsWell";
  }

  console.log("unitdataquizRahul", unitquizdata);

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    console.log(orderList);

    let configProfile = {
      method: "post",
      // params: { userId: uid },
      url: `${IP}/courseBuilder/addUnitLevelOrder`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        unitId: unitId,
        unitLevels: orderList,
        edit: true,
      }),
    };

    axios(configProfile);
  }

  const addLevel = async () => {
    let check = checkForm();
    console.log(check);
    if (check != "allIsWell") {
      setCheckedFailed(true);
      return;
    }
    setCheckedFailed(false);
    // const schedule = document.getElementById(unitId + "leveladddate").value;

    var newDate = adddate;

    let arr = list.map((el) => {
      if (el.id == levelId) {
        return {
          id: levelId,
          name: levelName,
          description: levelDescription,
          isDraft: isDraft == "yes" ? true : false,
          hasQuiz: true,

          schedule: adddate,
        };
      } else {
        return el;
      }
    });

    let newList = arr;
    // console.log(editdataflag);
    if (!leveldataedit) {
      newList = arr.concat({
        id: levelId,
        name: levelName,
        description: levelDescription,
        isDraft: isDraft == "yes" ? true : false,
        hasQuiz: true,

        schedule: adddate,
      });
    }

    console.log(newList);

    if (leveldataedit) {
      let configQuiz = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/level`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          associatedUnitId: unitId,
          levelDescription: levelDescription,
          associatedCourseId: courseId,
          levelName: levelName,
          levelId: levelId,
          isDraft: isDraft == "yes" ? true : false,
        }),
      };

      axios(configQuiz).then((res) => {
        let configLevel = {
          method: "patch",
          // params: { userId: uid },
          url: `${IP}/courseBuilder/level`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: JSON.stringify({
            levelId: levelId,
            hasQuiz: true,

            completionText: addcompletionText,
            creationDate: new Date(),
            lastUpdateDate: new Date(),
            scheduledDateOfRelease: newDate,
          }),
        };

        axios(configLevel);
        setLevelName("");
        setLevelDescription("");
      });
      toggle();
      setlevelDataEdit(false);
    } else {
      const newListt = list.concat({
        id: levelId,
        name: levelName,
        description: levelDescription,

        hasQuiz: true,

        schedule: newDate,
      });

      let configQuiz = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/level`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          associatedUnitId: unitId,
          levelDescription: levelDescription,
          associatedCourseId: courseId,
          levelName: levelName,
          levelId: levelId,
          isDraft: isDraft == "yes" ? true : false,
        }),
      };

      let levelquizdata = await axios(configQuiz);

      let configLevel = {
        method: "patch",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/level`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          levelId: levelId,
          hasQuiz: true,

          completionText: addcompletionText,
          creationDate: new Date(),
          lastUpdateDate: new Date(),
          scheduledDateOfRelease: newDate,
        }),
      };

      let level = await axios(configLevel);
      // console.log(newList);

      saveOrder(newListt);

      alert("Level Added");
      setLevelName("");
      setLevelDescription("");
      setDaysAfterTopic("");
      setStartDateTopic("");
    }
    setList(newList);
  };

  const toggle = (e) => {
    document.getElementById(unitId + "topicForm").style.display =
      document.getElementById(unitId + "topicForm").style.display == "none"
        ? "block"
        : "none";
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }
  function edit(topic) {
    setlevelDataEdit(true);
    setEditable(true);
    document.getElementById(unitId + "topicForm").style.display = "";
    setLevelName(topic.name);
    setLevelId(topic.id);
    setLevelDescription(topic.description);
    setLevelName(topic.name);
    setLevelId(topic.id);
    setAddcompletionText(topic.completionText);
    setIsDraft(topic.isDraft == true ? "yes" : "no");
    // document.getElementById(unitId + "hasTopicQuiz").value = "yes";

    setAdddate(topic.schedule);
  }

  async function del(topic, index) {
    setDeleteQuestionId(topic);
    setopenLessonPopUp(true);
  }

  const delfunction = async (question) => {
    // db.collection("question").doc(question.id).delete();

    let configCreateExtraInfo = {
      method: "delete",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/level`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        levelId: question.id,
      }),
    };
    await axios(configCreateExtraInfo);
    let newArr = list.filter((el) => el.id != question.id);

    setList(newArr);

    saveOrder(newArr);
    alert("Deleted ");
  };
  return (
    <>
      <section
        className="section"
        style={{ marginBlockStart: "0px", width: "100%" }}
      >
        <DeleteLesson
          open={openLessonPopUp}
          onClose={() => setopenLessonPopUp(false)}
          delfun={(topic) => {
            delfunction(topic);
          }}
          topicId={deleteQuestionId}
        />
        <button
          className="addItem-btn"
          onClick={() => {
            setEditable(false);
            toggle();
          }}
        >
          Add LEVEL
        </button>
        <div
          className="addItem-container"
          id={unitId + "topicForm"}
          style={{ display: "none" }}
        >
          <div className="addItem-heading">Level</div>
          <div className="addItem-row">
            <div className="addItem-inputLabel">Level Name</div>
            <input
              type="text"
              className="addItem-inputBar"
              value={levelName}
              id={unitId + "levelName"}
              onChange={(event) => {
                setLevelName(event.target.value);
                if (!editable) {
                  let value = event.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();
                  setLevelId(value + "_" + time);
                }
              }}
            />
          </div>
          <div className="addItem-row">
            <div className="addItem-inputLabel">Short Description</div>
            <textarea
              type="text"
              className="addItem-inputBar"
              value={levelDescription}
              id={unitId + "levelDescription"}
              onChange={(event) => {
                setLevelDescription(event.target.value);
              }}
            />
          </div>

          <div className="addItem-row">
            <div className="addItem-inputLabel" style={{ marginTop: "2vw" }}>
              isDraft
            </div>
            <select
              className="addItem-inputSelect"
              id={unitId + "isDraft"}
              value={isDraft}
              onChange={(e) => {
                setIsDraft(e.target.value);
              }}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          {/* <div className="addItem-row">
            <div className="addItem-inputLabel">Quiz</div>
            <select
              className="addItem-inputSelect"
              id={unitId + "hasTopicQuiz"}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div> */}

          {/* <div className="addItem-row">
            <div className="addItem-inputLabel">completion Text</div>
            <textarea
              type="text"
              className="addItem-inputBar"
              value={addcompletionText}
              id={unitId + "levelcompletionText"}
              onChange={(event) => {
                setAddcompletionText(event.target.value);
              }}
            />
          </div> */}

          <div className="addItem-row">
            <div className="addItem-inputLabel">scheduled Date Of Release</div>
            <input
              type="date"
              className="addItem-inputBar"
              value={adddate}
              id={unitId + "leveladddate"}
              onChange={(event) => {
                setAdddate(event.target.value);
              }}
            />
          </div>

          <br></br>
          {checkFailed && (
            <p style={{ color: "red" }}>Fill all the necessary fields. </p>
          )}
          <div className="addItem-row">
            <button
              className="addItem-btn"
              style={{ marginLeft: "auto" }}
              onClick={() => {
                addLevel();
              }}
            >
              Save Level
            </button>
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                id="topicList"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  listStyleType: "none",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {list.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={item.id}
                        className="draggableTopic-li"
                      >
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreSharp />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              backgroundColor: "#7783F4",
                              color: "white",
                              borderRadius: "5px",
                            }}
                          >
                            <Typography>{item.name}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div
                              style={{ float: "right", margin: "20px" }}
                              onClick={() => del(item, index)}
                            >
                              <MdDelete
                                style={{ float: "right", margin: "20px" }}
                                size={28}
                                onClick={() => del(item)}
                              />
                            </div>
                            <div
                              style={{ float: "right", margin: "20px" }}
                              onClick={() => edit(item)}
                            >
                              <MdEdit
                                style={{ float: "right", margin: "20px" }}
                                size={28}
                              />
                            </div>

                            <p>{item.description}</p>
                            <p>Id- {item.id}</p>
                            <p>Associated unit- {unitId}</p>

                            <br></br>
                            <CreateStage
                              unitId={unitId}
                              courseId={courseId}
                              levelId={item.id}
                              editData={editData}
                            />
                            <br></br>
                            <Item
                              courseId={courseId}
                              levelId={item.id}
                              itemsArr={item.itemsArr}
                              editData={editData}
                            />
                            <br></br>
                            <CreateTopic
                              unitId={unitId}
                              courseId={courseId}
                              levelId={item.id}
                              editData={editData}
                              levelSetList={item.levelquizarr}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>

      <CreateTopic
        courseId={courseId}
        unitId={unitId}
        editData={editData}
        unitquizdata={unitquizdata}
      />
    </>
  );
};

export default CreateLesson;
