import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import React from 'react';
import { NotificationAIcons } from '../../../utilities/Icons/Icons';
import classes from "./Notification.module.css";

const NotificationPopup = () => {
    return (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Container>
                <Box sx={{ width: "100%", background: "#fff" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid rgba(0, 124, 132, 0.5)", p: "10px" }}>
                        <Typography sx={{ fontSize: "22px", fontWeight: 600, color: "#007C84" }}>Notification</Typography>
                        <Box sx={{ mt: 3, display: "flex", alignItems: "center" }}>
                            <FormControlLabel
                                control={<Checkbox color="success" sx={{ color: "#007C84", }} />} />
                            <label style={{ fontWeight: 500, color: "#007C84", fontSize: "16px", marginLeft: "-20px" }}>Mark all as read</label>
                        </Box>
                    </Box>
                    <Box className={classes.example} sx={{ py: "30px", height: "70vh", overflowY: "scroll" }}>
                        <Box sx={{ padding: "0px 20px", width: "85%" }}>
                            <Box sx={{ display: "flex", padding: "20px 0px", borderBottom: "1px solid rgba(0, 124, 132, 0.5)" }}>
                                <Box sx={{ width: "5%" }}>
                                    <Box sx={{ width: "25px", height: "25px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)" }}>
                                        <NotificationAIcons />
                                    </Box>
                                </Box>
                                <Box sx={{ width: "95%" }}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#007C84" }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas quam provident, minus, animi necessitatibus accusantium non consectetur ipsum rerum hic vero facilis,</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", padding: "20px 0px", borderBottom: "1px solid rgba(0, 124, 132, 0.5)" }}>
                                <Box sx={{ width: "5%" }}>
                                    <Box sx={{ width: "25px", height: "25px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)" }}>
                                        <NotificationAIcons />
                                    </Box>
                                </Box>
                                <Box sx={{ width: "95%" }}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#007C84" }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas quam provident, minus, animi necessitatibus accusantium non consectetur ipsum rerum hic vero facilis,</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", padding: "20px 0px", borderBottom: "1px solid rgba(0, 124, 132, 0.5)" }}>
                                <Box sx={{ width: "5%" }}>
                                    <Box sx={{ width: "25px", height: "25px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)" }}>
                                        <NotificationAIcons />
                                    </Box>
                                </Box>
                                <Box sx={{ width: "95%" }}>
                                    <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#007C84" }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas quam provident, minus, animi necessitatibus accusantium non consectetur ipsum rerum hic vero facilis</Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{ padding: "0px 20px", width: "85%", mt: "15px", }}>
                            <Box>
                                <Typography sx={{ fontSize: "16px", fontWeight: 600, color: "#007C84" }}>Yesterday Notification</Typography>
                            </Box>
                            <Box sx={{ "&:last-child": { borderBottom: "none" } }}>
                                <Box sx={{ display: "flex", padding: "20px 0px", borderBottom: "1px solid rgba(0, 124, 132, 0.5)", }}>
                                    <Box sx={{ width: "5%" }}>
                                        <Box sx={{ width: "25px", height: "25px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)" }}>
                                            <NotificationAIcons />
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "95%" }}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#007C84" }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas quam provident, minus, animi necessitatibus accusantium non consectetur ipsum rerum hic vero facilis,</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", padding: "20px 0px", borderBottom: "1px solid rgba(0, 124, 132, 0.5)" }}>
                                    <Box sx={{ width: "5%" }}>
                                        <Box sx={{ width: "25px", height: "25px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)" }}>
                                            <NotificationAIcons />
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "95%" }}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#007C84" }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas quam provident, minus, animi necessitatibus accusantium non consectetur ipsum rerum hic vero facilis,</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", padding: "20px 0px", borderBottom: "1px solid rgba(0, 124, 132, 0.5)" }}>
                                    <Box sx={{ width: "5%" }}>
                                        <Box sx={{ width: "25px", height: "25px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)" }}>
                                            <NotificationAIcons />
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "95%" }}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#007C84" }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas quam provident, minus, animi necessitatibus accusantium non consectetur ipsum rerum hic vero facilis,</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", padding: "20px 0px", borderBottom: "1px solid rgba(0, 124, 132, 0.5)" }}>
                                    <Box sx={{ width: "5%" }}>
                                        <Box sx={{ width: "25px", height: "25px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)" }}>
                                            <NotificationAIcons />
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "95%" }}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#007C84" }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas quam provident, minus, animi necessitatibus accusantium non consectetur ipsum rerum hic vero facilis,</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", padding: "20px 0px", borderBottom: "1px solid rgba(0, 124, 132, 0.5)" }}>
                                    <Box sx={{ width: "5%" }}>
                                        <Box sx={{ width: "25px", height: "25px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)" }}>
                                            <NotificationAIcons />
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "95%" }}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#007C84" }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas quam provident, minus, animi necessitatibus accusantium non consectetur ipsum rerum hic vero facilis,</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", padding: "20px 0px", borderBottom: "1px solid rgba(0, 124, 132, 0.5)" }}>
                                    <Box sx={{ width: "5%" }}>
                                        <Box sx={{ width: "25px", height: "25px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)" }}>
                                            <NotificationAIcons />
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "95%" }}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#007C84" }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas quam provident, minus, animi necessitatibus accusantium non consectetur ipsum rerum hic vero facilis,</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", padding: "20px 0px", borderBottom: "1px solid rgba(0, 124, 132, 0.5)" }}>
                                    <Box sx={{ width: "5%" }}>
                                        <Box sx={{ width: "25px", height: "25px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)" }}>
                                            <NotificationAIcons />
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "95%" }}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#007C84" }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas quam provident, minus, animi necessitatibus accusantium non consectetur ipsum rerum hic vero facilis,</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", padding: "20px 0px", borderBottom: "1px solid rgba(0, 124, 132, 0.5)" }}>
                                    <Box sx={{ width: "5%" }}>
                                        <Box sx={{ width: "25px", height: "25px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)" }}>
                                            <NotificationAIcons />
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "95%" }}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#007C84" }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas quam provident, minus, animi necessitatibus accusantium non consectetur ipsum rerum hic vero facilis,</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", padding: "20px 0px", borderBottom: "1px solid rgba(0, 124, 132, 0.5)" }}>
                                    <Box sx={{ width: "5%" }}>
                                        <Box sx={{ width: "25px", height: "25px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)" }}>
                                            <NotificationAIcons />
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "95%" }}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#007C84" }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas quam provident, minus, animi necessitatibus accusantium non consectetur ipsum rerum hic vero facilis,</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", padding: "20px 0px", borderBottom: "1px solid rgba(0, 124, 132, 0.5)" }}>
                                    <Box sx={{ width: "5%" }}>
                                        <Box sx={{ width: "25px", height: "25px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)" }}>
                                            <NotificationAIcons />
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "95%" }}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#007C84" }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas quam provident, minus, animi necessitatibus accusantium non consectetur ipsum rerum hic vero facilis,</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", padding: "20px 0px", borderBottom: "1px solid rgba(0, 124, 132, 0.5)" }}>
                                    <Box sx={{ width: "5%" }}>
                                        <Box sx={{ width: "25px", height: "25px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", background: "linear-gradient(90deg, #007848 0%, #004577 105.46%)" }}>
                                            <NotificationAIcons />
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "95%" }}>
                                        <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#007C84" }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas quam provident, minus, animi necessitatibus accusantium non consectetur ipsum rerum hic vero facilis</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default NotificationPopup;