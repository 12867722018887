import {
    collection,
    getDocs, query
} from "firebase/firestore";
import { t } from "i18next";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getCookie } from "../../Cookies";
import { db } from "../../Firebase";
import Navbar from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import { data } from "./ExtrenalTrainingData";
import "./StyleAdminComponents/GroupList.css";


function InternalTraining() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  var [Details, setDetails] = useState([]);

  useEffect(async () => {
    if (getCookie("UID") == null || getCookie("ROLE") != "admin") {
      goToHome();
      return <></>;
    }
    const q = query(collection(db, "externalTraining"));

    const data = await getDocs(q);
    console.log(data);

    setDetails(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  }, []);

  var table = "";
  $(document).ready(async function () {
    console.log(Details);

    const q = query(collection(db, "externalTraining"));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (doc) => {
      var actions =
        "<select class='actions'><option value='" +
        doc.id +
        "_edit'>edit</option><option value='" +
        doc.id +
        "_archive'>archive</option></select>";

      table +=
        "<tr><td>" +
        doc.data().trainingName +
        "</td><td>" +
        actions +
        "</td></tr>";

      //document.getElementById("external-trainings").innerHTML = table;
    });

    /*db.collection("trainings").get().then((snapshot)=>{
            snapshot.forEach(doc=>{
                table += "<tr><td>"+doc.data().trainingName+"</td><td>"+actions+"</td></tr>"

                document.getElementById("external-trainings").innerHTML = table;
            })
        })*/
  });

  const handleOnChange = (info) => {
    const data = info.split(",");
    var url = `/admin/externalTrainingEdits/${data[0]}`;
    if (data[1] == "edit") {
      navigate(url);
    }
  };

  const { clrs } = useSelector((state) => state.createClr);
  const [arr, setArr] = useState();
  const [pageNo, setPageNo] = useState();
  const [curPage, setCurPage] = useState();
  const [displayNotify, setDisplayNotify] = useState(false);

  useEffect(() => {
    setCurPage(1);
    let k = Math.round(data.length / 8);
    let i = 1;
    let arr = [];
    while (k > 0) {
      arr.push(i++);
      k--;
    }
    setPageNo(arr);
    let tmparr = [];
    for (let i = 0; i < 8 && i < data.length; i++) {
      tmparr.push(data[i]);
    }
    setArr(tmparr);
  }, []);

  const clickhandler = (i) => {
    if (i < 1) return;
    setCurPage(i);
    let tmparr = [];
    if (i == 1) {
      for (let j = 0; j < 8 && j < data.length; j++) {
        tmparr.push(data[j]);
      }
    } else {
      for (let j = (i - 1) * 8 - 1; j < i * 8 - 1 && j < data.length; j++) {
        tmparr.push(data[j]);
      }
    }
    if (tmparr != []) setArr(tmparr);
  };
  const NotifyClick = () => {
    setDisplayNotify(!displayNotify);
  };

  return (
    <div className="admin-users">
      <Navbar page={t("Enrollments")} />
      <div
        className="admin-users-main"
        style={{ gridTemplateColumns: "6vw 1fr" }}
      >
        <Sidebar page={"enrollments"} hidepage={true} />
        <div className="admin-overdue">
          <div className="admin-dashboard-main-mid">
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                Course Name
              </div>
              <div style={{ position: "relative" }}>
                <input type="text" placeholder="Search By Course Name" />
                <div
                  className="admin-dashboard-main-top-last"
                  style={{
                    position: "absolute",
                    top: "-0.35vw",
                    right: "5vw",
                  }}
                >
                  <button
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                      height: "4.1vh",
                    }}
                  >
                    <div style={{ marginRight: "-2.9vw" }}>Search</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                Training Provider
              </div>
              <div>
                <input type="text" placeholder="Select Training Provider" />
              </div>
            </div>
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">Status</div>
              <div>
                <input type="text" placeholder="Select Status" />
              </div>
            </div>
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">Group ID</div>
              <div>
                <input type="text" placeholder="Filter by Group ID" />
              </div>
            </div>
            <div className="admin-dashboard-main-mid-item-last">
              {" "}
              <div className="admin-dashboard-main-top-last">
                <button
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                    marginTop: "2vw",
                  }}
                >
                  <div>
                    <svg
                      width="auto"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div>{t("Filter")}</div>
                </button>
              </div>
            </div>
          </div>
          <div className="admin-overdue-bottom">
            <div className="admin-overdue-bottom-table">
              <div className="admin-overdue-bottom-table-cnt-ext">
                <div className="admin-overdue-bottom-table-head">SR.No</div>
                <div className="admin-overdue-bottom-table-head">User Name</div>
                <div className="admin-overdue-bottom-table-head">
                  Employee ID
                </div>
                <div className="admin-overdue-bottom-table-head">
                  Course Name
                </div>
                <div className="admin-overdue-bottom-table-head">Course ID</div>
                <div className="admin-overdue-bottom-table-head">
                  Training Provider
                </div>
                <div className="admin-overdue-bottom-table-head">
                  Start Date
                </div>
                <div className="admin-overdue-bottom-table-head">
                  Completion Date
                </div>
                <div className="admin-overdue-bottom-table-head">Group ID</div>
                <div className="admin-overdue-bottom-table-head">Status</div>
                <div className="admin-overdue-bottom-table-head">Action</div>
              </div>
              {arr &&
                arr.map((item, id) => {
                  return (
                    <div
                      className="admin-overdue-bottom-table-cnt-ext"
                      key={id}
                    >
                      <div className="admin-overdue-bottom-table-td">
                        0{item.SRNo * id}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item.UserName}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item.EmployeeID}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item.CourseName}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item.CourseID}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item.TrainingProvider}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item.StartDate}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item.CompletionDate}
                      </div>
                      <div className="admin-overdue-bottom-table-td">
                        {item.GroupID}
                      </div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{
                          color: `${
                            item.Status != "Completed" ? "#FD5353" : ""
                          }`,
                        }}
                      >
                        {item.Status}
                      </div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#004577" }}
                        onClick={NotifyClick}
                      >
                        <div>
                          <select
                            style={{
                              border: "none",
                              backgroundColor: "#F2F2F2",
                              height: "4vh",
                              width: "7vw",
                              padding: "0 1vh",
                              fontWeight: "700",
                              color: "#717579",
                              borderRadius: "6px",
                            }}
                          >
                            <option>Download</option>
                            <option>Remind</option>
                            <option>Review</option>
                            <option>Retake</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => clickhandler(curPage - 1)}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  className="admin-overdue-bottom-pagination-cnt-item-btn"
                  style={{
                    gridTemplateColumns: `repeat(${
                      pageNo && (pageNo.length > 5 ? 5 : pageNo.length)
                    },1fr)`,
                  }}
                >
                  {pageNo &&
                    pageNo.map((item, id) => {
                      console.log(id);
                      if (id < 3) {
                        return (
                          <button
                            key={id}
                            onClick={() => clickhandler(item)}
                            className="admin-overdue-bottom-pagination-cnt-item"
                          >
                            {item}
                          </button>
                        );
                      } else if (id == 4) {
                        return (
                          <div
                            className="admin-overdue-bottom-pagination-cnt-item"
                            key={id}
                          >
                            <svg
                              width="auto "
                              height="4"
                              viewBox="0 0 12 4"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 3.08C1.63733 3.08 1.344 2.968 1.12 2.744C0.906667 2.52 0.8 2.232 0.8 1.88C0.8 1.53867 0.906667 1.26133 1.12 1.048C1.344 0.824 1.63733 0.712 2 0.712C2.37333 0.712 2.66133 0.824 2.864 1.048C3.07733 1.26133 3.184 1.53867 3.184 1.88C3.184 2.232 3.07733 2.52 2.864 2.744C2.66133 2.968 2.37333 3.08 2 3.08ZM5.96875 3.08C5.60608 3.08 5.31275 2.968 5.08875 2.744C4.87542 2.52 4.76875 2.232 4.76875 1.88C4.76875 1.53867 4.87542 1.26133 5.08875 1.048C5.31275 0.824 5.60608 0.712 5.96875 0.712C6.34208 0.712 6.63008 0.824 6.83275 1.048C7.04608 1.26133 7.15275 1.53867 7.15275 1.88C7.15275 2.232 7.04608 2.52 6.83275 2.744C6.63008 2.968 6.34208 3.08 5.96875 3.08ZM9.9375 3.08C9.57483 3.08 9.2815 2.968 9.0575 2.744C8.84417 2.52 8.7375 2.232 8.7375 1.88C8.7375 1.53867 8.84417 1.26133 9.0575 1.048C9.2815 0.824 9.57483 0.712 9.9375 0.712C10.3108 0.712 10.5988 0.824 10.8015 1.048C11.0148 1.26133 11.1215 1.53867 11.1215 1.88C11.1215 2.232 11.0148 2.52 10.8015 2.744C10.5988 2.968 10.3108 3.08 9.9375 3.08Z"
                                fill="#717579"
                              />
                            </svg>
                          </div>
                        );
                      } else if (id + 1 == pageNo.length) {
                        return (
                          <button
                            key={id}
                            onClick={() => clickhandler(item)}
                            className="admin-overdue-bottom-pagination-cnt-item"
                          >
                            {item}
                          </button>
                        );
                      }
                    })}
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => clickhandler(curPage + 1)}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div>
            <div className="User">List of Users</div>
  
            <div className="item">
              <p className="inputLabel">Search</p>
              <div className="inputbarButton">
                <input
                  type="text"
                  className="inputbar"
                  placeholder="Search Users"
                />
              </div>
            </div>
  
            <div className="topContent" style={{ marginTop: "2vw" }}>
              <div className="item">
                <p className="inputLabel">Company</p>
                <div className="inputbarButton">
                  <select className="rentInputbar">
                    <option value="rent"> Choose Option </option>
                  </select>
                </div>
              </div>
            </div>
  
            <div className="topContent" style={{ marginTop: "2vw" }}>
              <div className="item">
                <p className="inputLabel">Department</p>
                <div className="inputbarButton">
                  <select className="rentInputbar">
                    <option value="rent"> Choose Option </option>
                  </select>
                </div>
              </div>
            </div>
  
            <div className="topContent" style={{ marginTop: "2vw" }}>
              <div className="item">
                <p className="inputLabel">Group</p>
                <div className="inputbarButton">
                  <select className="rentInputbar">
                    <option value="rent"> Choose Option </option>
                  </select>
                </div>
              </div>
            </div>
  
            <div className="btn">
              <button>
                <HiFilter color="white" />
                <p>Filter</p>
              </button>
            </div>
  
            <div className="Apps">
              <table id="userTable"></table>
            </div>
  
            <div className="btn" onClick={goToAddNewUser}>
              <button>Add new Users</button>
            </div>
          </div> */}
        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <Link to={"/admin/trainingenrollments"}>
              <button
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
              >
                Back
              </button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
    // <>
    // <h1>External Training</h1>

    // <div>
    // <button onClick={()=>navigate("/admin/externalTrainingEdits/:id")}>New External Training</button>
    // </div>
    // <br></br>

    // <br></br>

    //     <table className='styled-table'>
    //         <thead>
    //             <tr>
    //                 <th>Trainings</th>
    //                 <th>Actions</th>
    //             </tr>
    //         </thead>
    //         <tbody id='external-trainings'>
    //             {Details.map((details, i)=>{
    //                 return(
    //                 <tr key={i}>
    //                     <td>{details.trainingName}</td>
    //                     <td><select onChange={(e)=>handleOnChange(e.target.value)}><option>Actions</option><option value={[details.trainingName, "edit"]}>Edit</option><option value={[details.trainingName, "archive"]}>Archive</option></select></td>
    //                 </tr>
    //                 )
    //             })}
    //         </tbody>
    //     </table>
    // <br></br>

    // </>
  );
}

export default InternalTraining;
