export const defultGreen = {
  SidebariconClr: "#188775",
  SidebarcntClr: "#E6FCF5",
  SidebartxtClr: "#188775",
  EnrolBtnClr: "#007848",
  
};

export const blue = {
  SidebariconClr: "rgb(36 24 135)",
  SidebarcntClr: "hwb(224deg 78% 3%)",
  SidebartxtClr: "rgb(1 5 16)",
  EnrolBtnClr: "rgb(36 24 135)",
};
