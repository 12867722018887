import React, { useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
  ChemistryIcons,
  EnglishIcons,
  PhysicsIcons,
  PlusIcons,
} from "../../../../utilities/Icons/Icons";

import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Timestamp } from "firebase/firestore";

const CertificateCard = ({ certificate }) => {
  return (
    <Grid item md={6}>
      <Box
        sx={{
          width: "100%",
          height: "100px",
          background: "#fff",
          position: "relative",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "30%",
            height: "100%",
            background: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                textTransform: "uppercase",
                color: "#2767D1",
                fontWeight: 600,
                fontSize: "20px",
              }}
            >
              PTW
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "70%",
            height: "100%",
            background: "#2767D1",
            position: "absolute",
            top: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            clipPath: "polygon(20% 0, 100% 0%, 100% 100%, 0% 100%)",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              {certificate?.certificateName}
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "8px",
                fontWeight: 400,
              }}
            >
              View Certificate
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default CertificateCard;
