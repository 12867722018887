import React, { useEffect } from "react";
import { useState } from "react";
import $ from "jquery";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import "./Form.css";
import { EditSvg, DeleteSVg } from "./icons";
import { useLocation, useNavigate } from "react-router";
import { getCookie } from "../../../Cookies";
import { IP } from "../../../config";
import axios from "axios";
import DeleteLesson from "./lessonDeletePopUp";

const Course = ({ setIsCourseIdSet }) => {
  // Course
  // fetchEnum

  // fetchCourseAccessEnum

  const deleteCourse = httpsCallable(functions, "deleteCourse");
  const fetchCoursesExtraInfo = httpsCallable(
    functions,
    "fetchCoursesExtraInfo"
  );

  const navigate = useNavigate();
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [deleteQuestionId, setDeleteQuestionId] = useState("");

  const [courseName, setCourseName] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [courseDuration, setCourseDuration] = useState(0);
  const [timeInMinute, settimeInMinute] = useState(0);
  const [trainingProvider, setTrainingProvider] = useState("");
  const [courseExpirationDays, setCourseExpirationDays] = useState(0);
  const [courseId, setCourseId] = useState("");
  const storage = getStorage();
  const [editable, setEditable] = useState(false);
  const [checkFailed, setCheckFailed] = useState(false);
  const [base64Img, setBase64Img] = useState("");
  const [isDraft, setIsdraft] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [CompletionText, setCompletionText] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [ScheduledDateOfRelease, setScheduledDateOfRelease] = useState(false);
  const [ScheduledDate, setScheduledDate] = useState("");
  const [category, setCategory] = useState("");
  const [FData, FectEData] = useState(false);
  const [isFeatured, setIsFeatured] = useState("");
  const [courseflow, setCourseFLow] = useState("");
  const [hasQuizData, setHasQuizData] = useState("");
  const [courseaccestype, setCourseaccesstype] = useState("");
  const [ECOurseData, setECOurseData] = useState("");
  const [coursedataenum, setcoursedataenum] = useState("");
  const [maxLengthForInputBox, setMaxLengthForInputBox] = useState(15);
  var docURL = [];
  var files = [];

  const formCheck = () => {
    if (courseName === "") return "Course Name";

    if (courseDescription === "") return "Description";

    return "allIsWell";
  };

  const handleCourseImage = (e) => {
    //         naturalHeight: 162
    // naturalWidth: 311

    // var reader = new FileReader();

    // //Read the contents of Image File.
    // reader.readAsDataURL(fileUpload.files[0]);
    // reader.onload = function (e) {

    //   //Initiate the JavaScript Image object.
    //   var image = new Image();

    //   //Set the Base64 string return from FileReader as source.
    //   image.src = e.target.result;

    //   //Validate the File Height and Width.
    //   image.onload = function () {
    //     var height = this.height;
    //     var width = this.width;
    //     if (height > 100 || width > 100) {
    //       alert("Height and Width must not exceed 100px.");
    //       return false;
    //     }
    //     alert("Uploaded image has valid Height and Width.");
    //     return true;
    //   };
    // };
    ///////////////////
    // console.log(e.target)
    // console.log(e.target.nextElementSibling.naturalHeight)
    // if(e.target.nextElementSibling.naturalHeight<250){
    //     // e.target.value=null
    //     alert("hight must be greater then 250px")
    // }
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = reader.result;
      //.log(image.src);
      //Validate the File Height and Width.
      image.onload = function () {
        //console.log("img onload");
        var height = this.height;
        var width = this.width;
        if (height < 250 || width < 250) {
          e.target.value = null;
          alert("Height and Width must be exceed 250px.");
          return false;
        } else {
          setBase64Img(reader.result);
        }

        return true;
      };
      // console.log(reader.result);

      setBase64Img(reader.result);
    };
  };

  const editData = useLocation();
  let editDataOfCourse = editData.state;
  useEffect(() => {
    // console.log("rahul");
    // console.log(editDataOfCourse);
    if (editDataOfCourse) {
      let configProfile = {
        method: "get",
        // params: { userId: uid },
        url: `${IP}/courseContent/extrainfo/${editDataOfCourse.courseId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
      };

      axios(configProfile)
        // fetchCoursesExtraInfo({ courseId: editDataOfCourse.courseId })
        .then((res) => {
          editDataFunctionLevelTwo(res.data);
        });
      console.log("gg");
      setCourseName(editDataOfCourse.courseName);
      settimeInMinute(editDataOfCourse?.timeInMinute);
      setCourseDuration(editDataOfCourse?.courseDuration);
      setBase64Img(editDataOfCourse.courseFeaturedImage);
      setCategory(editDataOfCourse.courseCategory);
      setCourseDescription(editDataOfCourse.courseDescription);
      setTrainingProvider(editDataOfCourse.courseAuthor);
      setCourseaccesstype(
        editDataOfCourse.courseAccessType == "1" ? "paid" : "free"
      );
      setIsdraft(editDataOfCourse.isDraft);
      setOriginalPrice(editDataOfCourse.originalPrice);
      setDiscountPrice(editDataOfCourse.discountPrice);
      setIsFeatured(editDataOfCourse.isFeatured == true ? "True" : "False");
      setCourseId(editDataOfCourse.courseId);

      let configProfileT = {
        method: "get",
        // params: { userId: uid },
        url: `${IP}/catagories/getCategoriesWithAllData`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        // data:
      };

      axios(configProfileT).then((res) => {
        // console.log(setCategory(res.data[editDataOfCourse.courseCategory]);
      });
    }
  }, []);

  const editDataFunctionLevelTwo = (data) => {
    // console.log(data);
    let date = new Date(data.ScheduledDateOfRelease._seconds * 1000);
    let dateString = date.toISOString().split("T")[0];

    // console.log(dateString);
    setScheduledDateOfRelease(dateString);
    setCompletionText(data.courseCompletionText);
    setCourseFLow(data.isLinearFLow == true ? "True" : "False");
    setHasQuizData(data.hasQuiz == true ? "True" : "False");
  };

  //console.log(editData);
  //console.log(category);
  useEffect(() => {
    FechENUMdata();
  }, []);
  const FechENUMdata = () => {
    let configProfile = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/catagories/getCategoriesWithAllData`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      // data:
    };

    axios(configProfile).then((res) => {
      // console.log(res);

      let objToArrData = [];
      // for (let key in res.data?.courseCategoryEnum) {
      //   objToArrData.push({ val: res.data?.courseCategoryEnum[key], key: key });
      // }
      let obj = {};
      res.data.map((el) => {
        objToArrData.push({ val: el.categoryName, key: el.categoryEnum });
        obj[el.categoryEnum] = el.categoryName;
      });
      console.log(obj);
      setcoursedataenum(obj);
      // objToArrData.push({ val: res.data?.courseCategoryEnum[key], key: key });

      FectEData(objToArrData);

      let courseobjtoenum = [];
      for (let key in res.data?.courseAccessTypeEnum) {
        courseobjtoenum.push({
          val: res.data?.courseAccessTypeEnum[key],
          key: key,
        });
      }
      console.log(courseobjtoenum);
      setECOurseData(courseobjtoenum);
    });
  };

  var lengthpro = true;
  if (courseDescription?.length >= 55 && courseDescription?.length <= 80) {
    lengthpro = false;
  }
  if (courseDescription?.length == 0) {
    lengthpro = false;
  }
  //console.log(courseDescription?.length, lengthpro);
  const addCourse = () => {
    const check = formCheck();
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }
    setCheckFailed(false); // removes error message after succesful creation

    // if (ScheduledDateOfRelease) {
    //   var myDate = ScheduledDateOfRelease;
    //   myDate = myDate.split("-");
    //   var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]);
    // }
    var newDate = ScheduledDateOfRelease;

    if (editDataOfCourse) {
      let testingImg = base64Img.startsWith("https");

      let catSet = category;
      // console.log(coursedataenum);
      let flag = true;
      for (let key in coursedataenum) {
        if (key == Number(catSet)) {
          flag = false;
        }
      }
      if (flag) {
        catSet = editDataOfCourse.courseCategory;

        // console.log("done setCategory");
      } else {
        catSet = Number(category);
      }

      let corAssty = courseaccestype;
      if (corAssty == "free" || corAssty == "paid") {
        corAssty = editDataOfCourse.courseAccessType;
      } else {
        corAssty = Number(courseaccestype);
      }

      let configProfile = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/course`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          courseDescription: courseDescription,
          courseFeaturedImage: base64Img,
          courseDuration: courseDuration,
          timeInMinute: timeInMinute,
          courseAuthor: trainingProvider,
          courseName: courseName,
          isDraft: isDraft == "True" ? true : false,
          isFeatured: isFeatured == "True" ? true : false,
          courseAccessType: corAssty,
          courseCategory: catSet,
          discountPrice: discountPrice,
          originalPrice: originalPrice,
        }),
      };

      axios(configProfile).then((res) => {
        //console.log(res.data);

        let configProfile = {
          method: "patch",
          // params: { userId: uid },
          url: `${IP}/courseBuilder/course`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: JSON.stringify({
            courseCompletionText: CompletionText,

            hasQuiz: hasQuizData == "True" ? true : false,
            isLinearFLow: courseflow == "True" ? true : false,

            lastUpdateDate: Timestamp.fromDate(new Date()),
            ScheduledDateOfRelease: newDate,
            courseId: courseId,
          }),
        };

        axios(configProfile).then((ress) => console.log(ress.data));
      });
    } else {
      let configProfile = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/course`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          courseDescription: courseDescription,
          courseFeaturedImage: base64Img,
          courseDuration: courseDuration,
          timeInMinute: timeInMinute,
          courseAuthor: trainingProvider,
          courseName: courseName,
          isDraft: isDraft == "True" ? true : false,
          isFeatured: isFeatured == "True" ? true : false,
          courseAccessType: Number(courseaccestype),
          courseCategory: Number(category),
          discountPrice: discountPrice,
          originalPrice: originalPrice,
        }),
      };

      axios(configProfile).then((res) => {
        // console.log(res.data);

        let configProfile = {
          method: "patch",
          // params: { userId: uid },
          url: `${IP}/courseBuilder/course`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: JSON.stringify({
            // associatedUnit: [],
            courseCompletionText: CompletionText,
            // associatedQuizzes: [],
            hasQuiz: hasQuizData == "True" ? true : false,
            isLinearFLow: courseflow == "True" ? true : false,
            courseCreationDate: Timestamp.fromDate(new Date()),
            lastUpdateDate: Timestamp.fromDate(new Date()),
            ScheduledDateOfRelease: newDate,
            courseId: courseId,
          }),
        };

        axios(configProfile).then((ress) => console.log(ress.data));
      });
    }

    sessionStorage.setItem("courseId", courseId);

    alert("Course " + courseName + " added");
    document.getElementById("courseName").disabled = true; // disable the input field
    const elements = document.getElementsByClassName("course-builder-top");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = "none";
    }
    document.getElementById("addC").style.display = "none";
    document.getElementById("editC").style.display = "";
    localStorage.setItem("courseId", courseId);

    setIsCourseIdSet(courseId);
  };
  function edit() {
    document.getElementById("addC").style.display = "";
    const elements = document.getElementsByClassName("course-builder-top");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = "";
    }
  }

  function deleteCoursefun(course) {
    // deleteCourse({ courseId: id });
    setDeleteQuestionId({ id: course });
    setopenLessonPopUp(true);
  }

  const delfunction = async (question) => {
    // db.collection("question").doc(question.id).delete();

    let configCreateExtraInfo = {
      method: "delete",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/course`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        courseId: question.id,
      }),
    };
    await axios(configCreateExtraInfo);

    alert("Deleted " + courseName);
    navigate("/admin");
  };
  return (
    <>
      <div className="course-builder-container">
        <DeleteLesson
          open={openLessonPopUp}
          onClose={() => setopenLessonPopUp(false)}
          delfun={(topic) => {
            delfunction(topic);
          }}
          topicId={deleteQuestionId}
        />
        <h2 className="course-builder-heading">Content Uploader</h2>
        <div
          id="editC"
          style={{ display: "none" }}
          className="course-builder-top-info"
        >
          <table className="course-builder-top-table">
            <thead className="course-builder-top-thead">
              <tr>
                <th>Course Name</th>

                <th>Training provider</th>

                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{courseName}</td>

                <td>{trainingProvider}</td>

                <td>
                  <div
                    onClick={() => {
                      setEditable(true);
                      edit();
                    }}
                  >
                    <EditSvg style={{ marginRight: "3px" }} />
                  </div>

                  <div
                    onClick={() => {
                      deleteCoursefun(courseId);
                    }}
                  >
                    <DeleteSVg
                      onClick={() => {
                        deleteCoursefun(courseId);
                      }}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="course-builder-top">
          <div className="course-builder-info">
            <label htmlFor="courseName" className="course-builder-inputLable">
              Course Name
            </label>
            <input
              type="text"
              className="course-builder-inputBar"
              value={courseName}
              maxLength={maxLengthForInputBox}
              id="courseName"
              onChange={(e) => {
                setCourseName(e.target.value);
                let value = e.target.value.replaceAll(" ", "");
                let time = new Date().getTime();
                if (!editDataOfCourse) {
                  setCourseId(value + "_" + time);
                }
              }}
            />
          </div>
          <div className="course-builder-info">
            <p className="course-builder-inputLable">Featured Image</p>
            {/* {JSON.stringify(base64Img)} */}
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg"
              className="course-builder-inputImg"
              id="courseImage"
              onChange={handleCourseImage}
            />{" "}
            {editDataOfCourse && (
              <img
                style={{ borderRadius: "20px" }}
                src={base64Img}
                alt="img"
                width="200px"
                height="200px"
              />
            )}{" "}
          </div>
          <div className="course-builder-info">
            <p className="course-builder-inputLable">Short Description</p>
            <textarea
              type="text"
              className="course-builder-textarea"
              id="courseDescription"
              value={courseDescription}
              maxLength={80}
              onChange={(event) => {
                setCourseDescription(event.target.value);
              }}
            />
            {lengthpro && (
              <p style={{ color: "red", fontSize: "12px", marginLeft: "20%" }}>
                lengtn should be between 55 to 80
              </p>
            )}
          </div>
          <div className="course-builder-info">
            <p className="course-builder-inputLable">Course Duration *</p>

            <div style={{ width: "35%" }}>
              {" "}
              Hour{" "}
              <input
                type="number"
                className="course-builder-inputBar"
                value={courseDuration}
                style={{
                  border: "1px solid #d7d7d7",
                  width: "22%",
                }}
                id="courseDuration"
                min={0}
                onChange={(event) => {
                  setCourseDuration(event.target.value);
                }}
              />
              Minute
              <input
                type="number"
                className="course-builder-inputBar"
                value={timeInMinute}
                style={{
                  width: "22%",
                  border: "1px solid #d7d7d7",
                }}
                id="courseDuration"
                min={0}
                onChange={(event) => {
                  settimeInMinute(event.target.value);
                  // setCheckErrorInForm((prev) => ({
                  //   ...prev,
                  //   courseDuration: "",
                  // }));
                }}
              />
            </div>

            {/* {checkErrorInForm.courseDuration === "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                Enter Course Duration
              </span>
            )} */}
          </div>
          <div className="course-builder-info">
            <p className="course-builder-inputLable">course Author</p>
            <input
              type="text"
              className="course-builder-inputBar"
              value={trainingProvider}
              id="trainingProvider"
              onChange={(event) => {
                setTrainingProvider(event.target.value);
              }}
            />
          </div>
          <div className="course-builder-info">
            <div
              className="course-builder-inputLable"
              style={{ marginTop: "2vw" }}
            >
              Course Access Type
            </div>

            <select
              id="category"
              className="course-builder-inputSelect"
              onChange={(e) => {
                setCourseaccesstype(e.target.value);
              }}
            >
              {editDataOfCourse ? (
                <option value="none" selected disabled hidden>
                  {courseaccestype}{" "}
                </option>
              ) : (
                <option value="none" selected disabled hidden>
                  select
                </option>
              )}
              <option value={"0"}>free </option>
              <option value={"1"}>paid </option>
            </select>
          </div>
          <div className="course-builder-info">
            <div
              className="course-builder-inputLable"
              style={{ marginTop: "2vw" }}
            >
              isDraft
            </div>
            <select
              id="isDraft"
              className="course-builder-inputSelect"
              onChange={(e) => {
                setIsdraft(e.target.value);
              }}
            >
              {editDataOfCourse ? (
                <option value="none" selected disabled hidden>
                  {isDraft ? "true" : "false"}{" "}
                </option>
              ) : (
                <option value="none" selected disabled hidden>
                  select
                </option>
              )}
              <option value="True">True</option>
              <option value="False">False</option>
            </select>
          </div>
          <div className="course-builder-info">
            <div
              className="course-builder-inputLable"
              style={{ marginTop: "2vw" }}
            >
              isFeatured
            </div>
            <select
              id="isFeatued"
              value={isFeatured}
              className="course-builder-inputSelect"
              onChange={(e) => {
                setIsFeatured(e.target.value);
              }}
            >
              {/* {
            editDataOfCourse ? <option value="none" selected disabled hidden>
                {
                isFeatured ? "true" : "false"
            } </option> : <option value="none" selected disabled hidden>
                select
            </option>
        } */}
              <option value="True">True</option>
              <option value="False">False</option>
            </select>
          </div>
          <div className="course-builder-info">
            <div
              className="course-builder-inputLable"
              style={{ marginTop: "2vw" }}
            >
              Course Category
            </div>

            <select
              id="category"
              value={category}
              className="course-builder-inputSelect"
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              {/* {editDataOfCourse ? (
                <option value="none" selected disabled hidden>
                  {category}{" "}
                </option>
              ) : (
                <option value="none" selected disabled hidden>
                  select
                </option>
              )} */}
              {FData.length > 0 &&
                FData.map((e) => <option value={e.key}>{e.val} </option>)}{" "}
            </select>
          </div>
          <div className="course-builder-info">
            <p className="course-builder-inputLable">Original Price</p>
            <input
              type="number"
              className="course-builder-inputBar"
              value={originalPrice}
              id="originalPrice"
              onChange={(event) => {
                setOriginalPrice(event.target.value);
              }}
            />
          </div>
          <div className="course-builder-info">
            <p className="course-builder-inputLable">Discount Price</p>
            <input
              type="number"
              className="course-builder-inputBar"
              id="discountPrice"
              value={discountPrice}
              onChange={(event) => {
                setDiscountPrice(event.target.value);
              }}
            />
          </div>
          <div className="course-builder-top">
            <h2 className="course-builder-heading" style={{ marginTop: "2vw" }}>
              Course Prerequisite
            </h2>

            <div className="course-builder-info">
              <div
                className="course-builder-inputLable"
                style={{ marginTop: "2vw" }}
              >
                Quiz
              </div>
              <select
                id="hasQuiz"
                className="course-builder-inputSelect"
                onChange={(e) => {
                  setHasQuizData(e.target.value);
                }}
              >
                {editDataOfCourse ? (
                  <option value="none" selected disabled hidden>
                    {hasQuizData}{" "}
                  </option>
                ) : (
                  <option value="none" selected disabled hidden>
                    select
                  </option>
                )}
                <option value="True">True</option>
                <option value="False">False</option>
              </select>
            </div>
          </div>
          <div className="course-builder-info">
            <div
              className="course-builder-inputLable"
              style={{ marginTop: "2vw" }}
            >
              islinearFLow
            </div>
            <select
              id="flow"
              value={courseflow}
              className="course-builder-inputSelect"
              onChange={(e) => {
                setCourseFLow(e.target.value);
              }}
            >
              {/* {
    editDataOfCourse ? <option value="none" selected disabled hidden>
        {courseflow} </option> : <option value="none" selected disabled hidden>
        select
    </option>
} */}
              <option value="True">true</option>
              <option value="False">false</option>
            </select>
          </div>
          <div className="course-builder-info">
            <p className="course-builder-inputLable">Course ID</p>
            <input
              disabled
              type="text"
              className="course-builder-inputBar"
              id="courseId"
              value={courseId}
            />
          </div>
          <div className="course-builder-info">
            <p className="course-builder-inputLable">Course CompletionText</p>
            <textarea
              type="text"
              className="course-builder-textarea"
              value={CompletionText}
              id="CompletionText"
              onChange={(event) => {
                setCompletionText(event.target.value);
              }}
            />
          </div>
          <div className="course-builder-info">
            <p className="course-builder-inputLable">
              Scheduled Date Of Release
            </p>
            <input
              type="date"
              className="course-builder-textarea"
              value={ScheduledDateOfRelease}
              id="ScheduledDateOfRelease"
              onChange={(event) => {
                setScheduledDateOfRelease(event.target.value);
              }}
            />
          </div>
          <br></br>
          {checkFailed && (
            <div>
              <p style={{ color: "red" }}>Fill all the field in the Form</p>
            </div>
          )}{" "}
        </div>
        <button id="addC" className="course-builder-button" onClick={addCourse}>
          Add Course
        </button>
        {/* {courseAdd==false?<ViewCourses/>:<Lesson/>} */}{" "}
      </div>
    </>
  );
};

export default Course;
