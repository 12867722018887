import { getAuth, signOut } from "firebase/auth";
import { t } from "i18next";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DateRangePicker } from "rsuite";
//import 'rsuite/dist/styles/rsuite-default.css';
import { Checkbox } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../Firebase";
import "./CourseList.css";

import { Cdata } from "./CourseData";

import Navbar from "../Component/Navbar";
/* import { DateRangePicker } from 'rsuite'; */
//import 'rsuite/dist/styles/rsuite-default.css';
/* import Navbar from "../Component/Navbar"; */
import { Link } from "react-router-dom";
import { getCookie } from "../../Cookies";
import NavbarTop from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import "./Dashboard.css";
import "./StyleAdminComponents/Dashboard.css";
import { useState } from "react";
import { IP } from "../../config";
import axios from "axios";

const Dashboard = () => {
  const [courseArray, setCourseArray] = useState(null);

  //const { clrs? } = useSelector((state) => state.createClr);
  // const navigate = useNavigate();
  // const goToHome = () => navigate("/");
  const goToBack = () => navigate("/admin/training");
  const gotoEditPage = (state) => {
    console.log(state);
    navigate("/admin/courseBuilder", { state: { ...state } });
  };
  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "admin") {
      goToHome();
      return <></>;
    }
  }, []);

  const [arrLength, setArrLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const fetchCourses = httpsCallable(functions, "fetchCourses");
  useEffect(() => {
    let configProfile = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/courseContent`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };

    axios(configProfile).then((res) => {
      ///  console.log(res.data)
      setCourseArray(res.data?.map((item) => ({ ...item, selected: false })));
      //  console.log(courseArray)
      setCourseName(res.data);
      // console.table(res.data);

      setExtraArrForData(res.data);
      setArrLength(res.data?.length);
    });
  }, []);

  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  const SelectHand = (i) => {
    let newData;
    newData = courseArray.map((item, id) => {
      if (id === i) {
        item.selected = item.selected ? false : true;
        return item;
      } else {
        return item;
      }
    });
    if (newData) setCourseArray(newData);
  };

  const { clrs } = useSelector((state) => state.createClr);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  const [courseName, setCourseName] = useState([]);
  const [extraArrForData, setExtraArrForData] = useState([]);
  const handleLogout = () => {
    //const auth = getAuth();
    // signOut(auth)
    //   .then(() => {
    //     goToHome();
    //   })
    //   .catch((error) => {});
  };

  const filterFun = (ele) => {
    let filArr = courseName.filter((e) => e.courseName === ele);
    setCourseArray(filArr);
    setArrLength(filArr.length);
    setCurrentPage(0);
  };

  const clearFunction = () => {
    setCourseArray(extraArrForData);
    setArrLength(extraArrForData.length);
    setCurrentPage(0);
  };
  return (
    <section className="dashboard">
      <NavbarTop page={t("Dashboard")} />
      {/* <Navbar /> */}

      <div style={{ height: "41vw" }} className="dashboard-main">
        <Sidebar page={"Dashboard"} hidepage={false} />
        <div className="admin-dashboard-main">
          {/*  <div className="admin-dashboard-main-top">
            <button
              style={{
                background: `${
                  clrs?.CourseListBtn ? clrs?.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
              }}
            >
              <div>
                <svg
                  width="auto"
                  height="18"
                  viewBox="0 0 16 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.8242 5.54297C12.8242 2.86719 10.6563 0.699219 7.9805 0.699219C5.30472 0.699219 3.13676 2.86719 3.13676 5.54297C3.13676 7.16016 3.92972 8.59375 5.14847 9.47266C5.14066 9.47656 5.1348 9.47851 5.1309 9.48047C4.25785 9.84961 3.47465 10.3789 2.80082 11.0547C2.12699 11.7285 1.59965 12.5137 1.23051 13.3887C0.86918 14.2441 0.675821 15.1523 0.65629 16.0859C0.654337 16.1738 0.724649 16.2461 0.81254 16.2461H1.98246C2.06644 16.2461 2.13676 16.1777 2.13871 16.0938C2.17777 14.5859 2.78129 13.1738 3.84965 12.1035C4.95316 10.9961 6.418 10.3867 7.9805 10.3867C10.6563 10.3867 12.8242 8.21875 12.8242 5.54297ZM7.9805 8.90234C6.12504 8.90234 4.62113 7.39844 4.62113 5.54297C4.62113 3.6875 6.12504 2.18359 7.9805 2.18359C9.83597 2.18359 11.3399 3.6875 11.3399 5.54297C11.3399 7.39844 9.83597 8.90234 7.9805 8.90234ZM10.0313 13.2188H15.1875C15.2735 13.2188 15.3438 13.1484 15.3438 13.0625V11.9688C15.3438 11.8828 15.2735 11.8125 15.1875 11.8125H11.7403L12.6621 10.6387C12.6836 10.6113 12.6953 10.5762 12.6953 10.543C12.6953 10.457 12.625 10.3867 12.5391 10.3867H11.1211C11.0254 10.3867 10.9356 10.4316 10.875 10.5059L9.53714 12.207C9.4512 12.3164 9.40433 12.4531 9.40433 12.5938C9.40628 12.9395 9.68558 13.2188 10.0313 13.2188ZM14.7188 14.4688H9.56253C9.47659 14.4688 9.40628 14.5391 9.40628 14.625V15.7188C9.40628 15.8047 9.47659 15.875 9.56253 15.875H13.0098L12.0879 17.0488C12.0664 17.0762 12.0547 17.1113 12.0547 17.1445C12.0547 17.2305 12.125 17.3008 12.211 17.3008H13.6289C13.7246 17.3008 13.8145 17.2559 13.875 17.1816L15.2129 15.4805C15.2989 15.3711 15.3457 15.2344 15.3457 15.0938C15.3438 14.748 15.0645 14.4688 14.7188 14.4688Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div>{t("UserView")}</div>
            </button>
          </div>
          // */}

          <div className="admin-dashboard-main-mid">
            {/* //   <div className="admin-dashboard-main-mid-item">
          //     <div className="admin-dashboard-main-mid-item-txt">
          //       {t("CompanyName")}
          //     </div>
          //     <div>
          //       {/* <input type="text" placeholder={t("SelectCompany")} />

        // */}
            {/* <select name="company" id="company"> */}

            {/* //        <option value="demo">Select Company</option>\
          //        </select>
          //     </div>
          //   </div>
          //   <div className="admin-dashboard-main-mid-item">
          //     <div className="admin-dashboard-main-mid-item-txt">
          //       {t("GroupName")}
          //     </div>
          //     <div> */}
            {/*  <input type="text" placeholder={t("SelectGroup")} /> */}
            {/* <select name="SelectGroup" id="SelectGroup">
                 <option value="demo">Select Group</option>\
                 </select>
              </div> */}
            {/* </div> */}
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("CourseName")}
              </div>
              <div>
                {/*   <input type="text" placeholder={t("SelectCourseName")} /> */}
                <select
                  name="SelectCourseName"
                  id="SelectCourseName"
                  onChange={(ele) => {
                    filterFun(ele.target.value);
                  }}
                >
                  {courseName ? (
                    courseName.map((e) => (
                      <option value={e.courseName}>{e.courseName}</option>
                    ))
                  ) : (
                    <option value="">Select Course Name</option>
                  )}
                </select>
              </div>
            </div>
            {/* <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Date")}
              </div>
              <div>
                <input type="date" />
              </div>
            </div> */}
            {/*         <DateRangePicker
                 style={{ width: 300 }}
           placeholder="Select Date Range"
                /> */}

            {/* <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("ToDate")}
              </div>
              <div>
                <input type="date" />
              </div>
            </div> */}
            {/* <div className="admin-dashboard-main-mid-item-last">
              {" "}
              <div className="admin-dashboard-main-top-last" style={{paddingTop: '1.5vw'}}>
                <button
                  style={{
                    background: `${
                      clrs?.CourseListBtn
                        ? clrs?.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                >
                  <div>
                    <svg
                      width="auto"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div>{t("Filter")}</div>
                </button>
              </div>
            </div> */}

            <div className="admin-dashboard-main-mid-item-last">
              {" "}
              <div
                className="admin-dashboard-main-top-last"
                style={{ paddingTop: "1.5vw" }}
              >
                <button
                  style={{
                    background: `${
                      clrs?.CourseListBtn
                        ? clrs?.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  onClick={() => {
                    clearFunction();
                  }}
                >
                  <div>
                    <svg
                      width="auto"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div>{t("clear")}</div>
                </button>
              </div>
            </div>
          </div>
          <div style={{ height: "3vw" }} className="admin-overdue-top"></div>
          {/*  <DateRangePicker/> */}
          {/* <div className="admin-dashboard-main-bottom">
            <div style={{width: "24vw"}} className="admin-dashboard-main-bottom-item">
              <div className="admin-dashboard-main-bottom-item-left">
                <div className="admin-dashboard-main-bottom-item-left-top">
                  {t("OverdueEnrollments")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-mid">
                  {t("NumberOfOverdueEnrollments")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-btm">
                  <Link to={"/admin/overdueenrollments"}>
                    <button
                      style={{
                        background: `${
                          clrs?.CourseListBtn
                            ? clrs?.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      <div>{t("ViewAll")}</div>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="admin-dashboard-main-bottom-item-right">
                <div
                  className="admin-dashboard-main-bottom-item-right-cnt"
                  style={{
                    background: `${
                      clrs?.CourseListBtn
                        ? clrs?.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                >
                  18
                </div>
              </div>
            </div> */}

          {/* <div style={{width: "24vw"}} className="admin-dashboard-main-bottom-item">
              <div className="admin-dashboard-main-bottom-item-left">
                <div className="admin-dashboard-main-bottom-item-left-top">
                  {t("UserPassedInThisMonth")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-mid">
                  {t("NumberOfUserPassed")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-btm">
                  <Link to={"/admin/userpassed"}>
                    <button
                      style={{
                        background: `${
                          clrs?.CourseListBtn
                            ? clrs?.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      <div>{t("ViewAll")}</div>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="admin-dashboard-main-bottom-item-right">
                <div
                  className="admin-dashboard-main-bottom-item-right-cnt"
                  style={{
                    background: `${
                      clrs?.CourseListBtn
                        ? clrs?.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                >
                  18
                </div>
              </div>
            </div> */}

          <div className="courselist">
            <div
              className="courselist-main"
              style={{ gridTemplateColumns: "90%" }}
            >
              <div className="courselist-main-cnt">
                <div className="course-head">{t("Courses")}</div>
                <div className="courselist-main-cnt-bottom">
                  <div
                    className="courselist-main-cnt-bottom-heading"
                    style={{ textAlign: "center", padding: "0" }}
                  >
                    <div style={{ textAlign: "left", paddingLeft: "3vw" }}>
                      {t("SNO")}
                    </div>
                    <div style={{ paddingLeft: "2vw" }}>{t("Trainings")}</div>
                    <div>{t("Action")}</div>
                  </div>
                  <div className="admin-overdue-bottom">
                    <div className="admin-overdue-bottom-table">
                      {courseArray?.map((item, id) => {
                        if (id < pageSize * currentPage) return;
                        if (id >= pageSize * (currentPage + 1)) return;
                        return (
                          <div
                            className="admin-overdue-bottom-table-cnt-c "
                            style={{
                              backgroundColor: `${
                                item.selected
                                  ? "rgba(0, 69, 119, 0.1)"
                                  : "rgba(255, 255, 255, 1)"
                              }`,
                            }}
                            key={id}
                          >
                            <div className="admin-overdue-bottom-table-td">
                              <Checkbox
                                checked={item.selected}
                                onClick={() => SelectHand(id)}
                              />
                            </div>
                            <div
                              className="admin-overdue-bottom-table-td"
                              style={{
                                display: "grid",
                                justifyContent: "left",
                                alignItems: "center",
                              }}
                              id="c-div"
                            >
                              {id + 1}
                            </div>
                            <div
                              className="admin-overdue-bottom-table-td"
                              id="c-div"
                            >
                              {item.courseName}
                            </div>
                            <div
                              className="admin-overdue-bottom-table-td"
                              id="c-div"
                            >
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "3vw 3vw",
                                }}
                              >
                                <div>
                                  <select
                                    style={{
                                      border: "none",
                                      backgroundColor: "#F2F2F2",
                                      height: "4vh",
                                      width: "7vw",
                                      padding: "0 1vh",
                                      fontWeight: "700",
                                      color: "#717579",
                                      borderRadius: "6px",
                                    }}
                                    onChange={() => {
                                      gotoEditPage(item);
                                    }}
                                  >
                                    <option> select</option>
                                    <option>Edit</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="admin-overdue-bottom-pagination">
                      <div
                        className="admin-overdue-bottom-pagination-cnt"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "15px",
                        }}
                      >
                        <div
                          className="admin-overdue-bottom-pagination-cnt-item"
                          style={{ marginRight: "10px" }}
                        >
                          <svg
                            onClick={() => clickhandler(currentPage - 1)}
                            width="auto"
                            height="22"
                            viewBox="0 0 14 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                              fill="#717579"
                            />
                          </svg>
                        </div>
                        <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                          <div className="admin-overdue-bottom-pagination-cnt-item">
                            {currentPage + 1} of{" "}
                            {Math.ceil(arrLength / pageSize)}
                          </div>
                        </div>
                        <div
                          className="admin-overdue-bottom-pagination-cnt-item"
                          style={{ marginLeft: "10px" }}
                        >
                          <svg
                            onClick={() => clickhandler(currentPage + 1)}
                            width="auto"
                            height="20"
                            viewBox="0 0 13 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                              fill="#717579"
                            />
                          </svg>
                        </div>
                        <div
                          style={{ marginRight: "-10vw", marginLeft: "60px" }}
                          className="admin-overdue-bottom-pagination-cnt-item"
                        >
                          <label className="admin-row">{t("Show")}</label>
                          {/* className="rowInput" */}
                          <select
                            style={{
                              width: "4vw",
                              margin: "0.5vw",
                              marginBottom: "1.5vw",
                              height: "1.5vw",
                            }}
                            value={pageSize}
                            onChange={(e) => setPageSize(e.target.value)}
                          >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                          </select>
                          <label className="admin-row">
                            {" "}
                            {t("PagePerItem")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{width: "24vw"}} className="admin-dashboard-main-bottom-item">
              <div className="admin-dashboard-main-bottom-item-left">
                <div className="admin-dashboard-main-bottom-item-left-top">
                  {t("UserFailedInThisMonth")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-mid">
                  {t("NumberOfUserFailed")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-btm">
                  <Link to={"/admin/userfailed"}>
                    <button
                      style={{
                        background: `${
                          clrs?.CourseListBtn
                            ? clrs?.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      <div>{t("ViewAll")}</div>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="admin-dashboard-main-bottom-item-right">
                <div
                  className="admin-dashboard-main-bottom-item-right-cnt"
                  style={{
                    background: `${
                      clrs?.CourseListBtn
                        ? clrs?.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                >
                  18
                </div>
              </div>
            </div> */}
        {/* <div style={{height: "8.5vw" ,width: "24vw"}} className="admin-dashboard-main-bottom-item">
              <div className="admin-dashboard-main-bottom-item-left">
                <div className="admin-dashboard-main-bottom-item-left-top">
                  {t("NewEmployees")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-mid">
                  {t("NumberOfNewEmployes")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-btm">
                  <Link to={"/admin/newemployees"}>
                    <button
                      style={{
                        background: `${
                          clrs?.CourseListBtn
                            ? clrs?.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      <div>{t("ViewAll")}</div>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="admin-dashboard-main-bottom-item-right">
                <div
                  className="admin-dashboard-main-bottom-item-right-cnt"
                  style={{
                    background: `${
                      clrs?.CourseListBtn
                        ? clrs?.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                >
                  18
                </div>
              </div>
            </div> */}
        {/* <div style={{width: "24vw"}} className="admin-dashboard-main-bottom-item">
              <div className="admin-dashboard-main-bottom-item-left">
                <div className="admin-dashboard-main-bottom-item-left-top">
                  {t("ExpiringTrainingCertificates")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-mid">
                  {t("NumberOfTrainingCertificates")}
                </div>
                <div className="admin-dashboard-main-bottom-item-left-btm">
                  <Link to={"/admin/expiringtraining"}>
                    <button
                      style={{
                        background: `${
                          clrs?.CourseListBtn
                            ? clrs?.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      <div>{t("ViewAll")}</div>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="admin-dashboard-main-bottom-item-right">
                <div
                  className="admin-dashboard-main-bottom-item-right-cnt"
                  style={{
                    background: `${
                      clrs?.CourseListBtn
                        ? clrs?.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                >
                  18
                </div>
              </div>
            </div> */}
        {/* </div> */}

        {/* <div className="btn">
          <button
            onClick={handleLogout}
            style={{ marginTop: "1vh", marginLeft: "5vw" }}
          >
            {" "}
            Logout{" "}
          </button>
        </div> */}
      </div>
    </section>
  );
};

export default Dashboard;
