import React, { useState } from "react";
import $ from "jquery";
import CreateLesson from "./CreateLesson";
import "./CreateCourse.css";
import Course from "./Course";
import { serverTimestamp, Timestamp } from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import CreateTopic from "./CreateTopic";
import Item from "./Item";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { Edit, ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import Navbar from "../../Component/NavbarTop";
import { t } from "i18next";

import Sidebar from "../../Component/Sidebar";
import { getCookie } from "../../../Cookies";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { DeleteSVg, SvgVector, EditSvg, ExpandMoreSvg } from "./icons";
import { IP } from "../../../config";
import axios from "axios";
import DeleteLesson from "./lessonDeletePopUp";

const CreateCourse = () => {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  // const navigate = useNavigate();
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [deleteQuestionId, setDeleteQuestionId] = useState("");

  const createUnit = httpsCallable(functions, "createUnit");
  const addUnitExtraInfo = httpsCallable(functions, "addUnitExtraInfo");
  const addCourseUnitOrder = httpsCallable(functions, "addCourseUnitOrder");
  const deleteunit = httpsCallable(functions, "deleteunit");
  const fetchCoursesExtraInfo = httpsCallable(
    functions,
    "fetchCoursesExtraInfo"
  );
  const fetchSingleUnitData = httpsCallable(functions, "fetchSingleUnitData");
  const fetchUnitExtraInfo = httpsCallable(functions, "fetchUnitExtraInfo");
  const unitExtraInfoUpdate = httpsCallable(functions, "unitExtraInfoUpdate");
  const unitUpdate = httpsCallable(functions, "unitUpdate");
  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const getUnitItemData = httpsCallable(functions, "getUnitItemData");
  // addCourseUnitOrder
  // useEffect(() => {
  //   if (getCookie("UID") == null || getCookie("ROLE") != "admin") {
  //     goToHome();
  //     return <></>;
  //   }
  // });
  var docURL = [];
  var files = [];
  const storage = getStorage();
  const [unitName, setunitName] = useState("");
  const [unitDescription, setunitDescription] = useState("");
  const [schedule, setSchedule] = useState("immediate");
  const [daysAfter, setDaysAfter] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [unitId, setunitId] = useState("");
  const [editable, setEditable] = useState(false);
  const [list, setList] = useState([]);
  const [isCourseIdSet, setIsCourseIdSet] = useState("");
  const [checkFailed, setCheckFailed] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [editdataflag, setEditDataflag] = useState(false);
  const [coursequizdata, setCourseQuizDataquizdata] = useState([]);
  const [uniteditflagdata, setUniteditflagdata] = useState(true);
  const [maxLengthForInputBox, setMaxLengthForInputBox] = useState(15);
  // courseAssociated: (data.courseAssociated) ? data.courseAssociated:"",
  // unitId:data.unitId?data.unitId:"",
  // unitName:data.unitName?data.unitName:"",
  // sampleUnit:data.sampleUnit?data.sampleUnit:false,
  // unitDescription:data.unitDescription?data.unitDescription:"",
  // isDraft:data.isDraft?data.isDraft:false,

  // unitLevels:data.unitLevels?data.unitLevels:[],
  // unitItems:data.unitItems?data.unitItems:[],
  // completionText:data.completionText?data.completionText:"good",
  // unitQuizzes:data.unitQuizzes?data.unitQuizzes:[],
  // hasQuiz:data.hasQuiz?data.hasQuiz:"",
  // creationDate: new Date(),
  // lastUpdateDate:new Date(),
  // scheduledDateOfRelease:data.scheduledDateOfRelease?data.scheduledDateOfRelease:""
  const initialInfo = {
    unitName: "",
    unitDescription: "",
    isDraft: "",
    isSample: true,
    hasQuiz: true,

    completionText: "",
  };
  const [unitDetails, setUnitDetails] = useState({ ...initialInfo });

  const handleInput = (e) => {
    const { value, name } = e.target;
    setUnitDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileInput = (e) => {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      setUnitDetails((prev) => ({ ...prev, base64Image: reader.result }));
      console.log("setBase64Image done");
    };
    reader.readAsDataURL(files[0]);
  };

  useEffect(() => {
    // fetchCoursesExtraInfo({ courseId: isCourseIdSet })

    let configProfile = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/courseContent/extrainfo/${isCourseIdSet}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };

    axios(configProfile).then((res) => {
      (async () => {
        for (let i = 0; i < res.data?.associatedUnit?.length; i++) {
          // let ress = await fetchSingleUnitData({
          //   unitId: res.data.associatedUnit[i],
          // });
          // let ressdata = await fetchUnitExtraInfo({
          //   unitId: res.data.associatedUnit[i],
          // });
          let configProfile = {
            method: "get",
            // params: { userId: uid },
            url: `${IP}/courseContent/units/${res.data.associatedUnit[i]}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getCookie("bearer")}`,
            },
          };
          // alert(res.data.associatedUnit[i]);
          let unitData = await axios(configProfile);

          // console.log(unitData.data);

          // let itemeditdata = await getUnitItemData({
          //   unitId: res.data.associatedUnit[i],
          // });

          let configIntem = {
            method: "get",
            // params: { userId: uid },
            url: `${IP}/courseContent/item/unit/${res.data.associatedUnit[i]}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getCookie("bearer")}`,
            },
          };

          let itemeditdata = await axios(configIntem);

          console.log("itemeditdata", itemeditdata.data);
          // alert("dy");

          let fulldate = new Date(
            unitData?.data?.scheduledDateOfRelease?._seconds * 1000
          );

          let dateString = fulldate.toISOString().split("T")[0];

          setList((prev) =>
            prev.concat({
              unitId: unitData.data.unitId,
              name: unitData.data.unitName,
              description: unitData.data.unitDescription,
              issampleUnit: unitData.data.sampleUnit,
              isDraft: unitData.data.isDraft,
              completionText: unitData.data.completionText,
              hasQuiz: unitData.data.hasQuiz,
              scheduledDateOfRelease: dateString,
              courseAssociated: unitData.data.courseAssociated,
              itemsArr: itemeditdata.data,
            })
          );
        }
      })();

      (async () => {
        for (let i = 0; i < res.data?.associatedQuizzes?.length; i++) {
          if (res.data?.associatedQuizzes?.length > 0) {
            // let levelquizdata = await getQuizInfo({
            //   quizId: res.data.associatedQuizzes[i],
            // });

            let configQuiz = {
              method: "get",
              // params: { userId: uid },
              url: `${IP}/courseContent/quiz/${res.data.associatedQuizzes[i]}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
            };

            let levelquizdata = await axios(configQuiz);
            console.log(levelquizdata.data);

            let fulldate = new Date(
              levelquizdata?.data?.scheduledDateOfRelease?._seconds * 1000
            );

            let dateString = fulldate.toISOString().split("T")[0];

            setCourseQuizDataquizdata((prev) =>
              prev.concat({
                id: levelquizdata.data.quizId,
                name: levelquizdata.data.quizName,
                passScore: levelquizdata.data.passingScore,
                quizDescription: levelquizdata.data.quizDescription,
                timeLimit: levelquizdata.data.timeLimit,
                success: levelquizdata.data.completionText,
                scheduledDateOfRelease: dateString,
              })
            );
          }
        }
      })();

      console.log(list);
      //   saveOrder(list);

      if (isCourseIdSet != null) {
        console.log("isCourseIdSet", isCourseIdSet);
        let configProfile = {
          method: "get",
          // params: { userId: uid },
          url: `${IP}/courseContent/extrainfo/${isCourseIdSet}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
        };

        axios(configProfile).then((res) => {});
      }

      ////////////////////
    });
  }, [isCourseIdSet]);

  console.log("coursequizdata", coursequizdata);

  const formCheck = () => {
    if (unitDetails.unitName === "") return "unitName";
    if (unitDetails.unitDescription === "") return "unitDescription";
    return "allIsWell";
    // not checking for files
  };

  const addLesson = async () => {
    let check = formCheck();
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }
    setCheckFailed(false);

    let newDate = startDate;
    console.log(isCourseIdSet);
    let d = 0;
    if (schedule == "enrolment") {
      d = unitDetails.daysAfter;
    } else if (schedule == "specific") {
      d = unitDetails.startDate;
    }

    let arr = list.map((el) => {
      if (el.unitId == unitId) {
        return {
          unitId: unitId,
          name: unitDetails.unitName,
          description: unitDetails.unitDescription,
          issampleUnit: unitDetails.isSample == "yes" ? true : false,
          hasQuiz: unitDetails.hasQuiz == "yes" ? true : false,
          schedule: schedule,

          courseAssociated: isCourseIdSet,
        };
      } else {
        return el;
      }
    });

    let newList = arr;
    console.log(editdataflag);
    if (!editdataflag) {
      newList = arr.concat({
        unitId: unitId,
        name: unitDetails.unitName,
        description: unitDetails.unitDescription,
        issampleUnit: unitDetails.isSample == "yes" ? true : false,
        hasQuiz: unitDetails.hasQuiz == "yes" ? true : false,
        schedule: schedule,

        courseAssociated: isCourseIdSet,
      });
    }

    console.log(newList);

    let configProfile = {
      method: "post",
      // params: { userId: uid },
      url: `${IP}/courseBuilder/unit`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        unitId: unitId,
        isDraft: unitDetails.isDraft == "yes" ? true : false,
        courseAssociated: isCourseIdSet,
        sampleUnit: unitDetails.isSample == "yes" ? true : false,
        unitDescription: unitDetails.unitDescription,
        unitName: unitDetails.unitName,
      }),
    };

    await axios(configProfile);

    let configUnit = {
      method: "patch",
      // params: { userId: uid },
      url: `${IP}/courseBuilder/unit`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        unitId: unitId,
        completionText: unitDetails.completionText,
        hasQuiz: unitDetails.hasQuiz == "yes" ? true : false,
        lastUpdateDate: new Date(),
        creationDate: new Date(),

        scheduledDateOfRelease: newDate,
      }),
    };

    await axios(configUnit);

    // addCourseUnitOrder({
    //   courseId: isCourseIdSet,
    //   associatedUnit: [unitId],
    //   edit: false,
    // });
    setList(newList);
    saveOrder({
      courseId: isCourseIdSet,
      associatedUnit: [unitId],
      edit: false,
    });
    setUnitDetails(initialInfo);
    setEditDataflag(false);
    alert("unit Added");
    toggle();
    // }
  };

  const toggle = () => {
    setFormOpen((prev) => !prev);
  };

  async function saveOrder(items) {
    var orderList = [];
    console.log(list);

    // for (let i = 0; i < items.associatedUnit.length; i++) {
    //   orderList.push(items.associatedUnit[i].unitId);
    // }
    let configUnit = {
      method: "post",
      // params: { userId: uid },
      url: `${IP}/courseBuilder/addCourseUnitOrder`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        associatedUnit: items.associatedUnit,
        courseId: items.courseId,
        edit: items.edit,
      }),
    };

    console.log(orderList);
    await axios(configUnit);
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder({
      courseId: isCourseIdSet,
      associatedUnit: [unitId],
      edit: false,
    });
    // addCourseUnitOrder({
    //   courseId: isCourseIdSet,
    //   associatedUnit: [unitId],
    //   edit: false,
    // });
  }

  function edit(lesson) {
    setUniteditflagdata(true);
    let lessonInfo = {
      unitName: lesson.name,
      unitDescription: lesson.description,
      completionText: lesson.completionText,
      isSample: lesson.issampleUnit ? "yes" : "no",
      hasQuiz: lesson.hasQuiz ? "yes" : "no",
      isDraft: lesson.isDraft ? "yes" : "no",

      unitId: lesson.unitId,
    };
    setStartDate(lesson.scheduledDateOfRelease);
    setunitId(lesson.unitId);
    setSchedule(lesson.schedule);
    setUnitDetails(lessonInfo);

    setEditable(true);
    toggle();
  }
  async function del(lesson, index) {
    setDeleteQuestionId({ id: lesson });
    setopenLessonPopUp(true);

    // console.log(lesson);
  }

  const delfunction = async (question) => {
    // db.collection("question").doc(question.id).delete();

    let configCreateExtraInfo = {
      method: "delete",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/unit`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        unitId: question.id,
      }),
    };
    await axios(configCreateExtraInfo);
    let newArr = list.filter((el) => el.unitId != question.id);

    setList(newArr);

    let tempARr = [];
    newArr.map((el) => {
      tempARr.push(el.unitId);
    });
    saveOrder({
      courseId: isCourseIdSet,
      associatedUnit: tempARr,
      edit: true,
    });
    alert("Deleted ");
  };
  return (
    <>
      <section className="section">
        <DeleteLesson
          open={openLessonPopUp}
          onClose={() => setopenLessonPopUp(false)}
          delfun={(topic) => {
            delfunction(topic);
          }}
          topicId={deleteQuestionId}
        />
        <Navbar page={t("LMSCourseBuilder")} />
        <div className="createcourse-main">
          <Sidebar page={"LMSCourseBuilder"} hidepage={true} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Course setIsCourseIdSet={setIsCourseIdSet} />
            {isCourseIdSet && (
              <div className="createcourse-main-bottom">
                <button
                  className="addLesson-btn"
                  onClick={() => {
                    setEditable(false);
                    setUniteditflagdata(false);
                    toggle();
                  }}
                >
                  Add Unit
                </button>

                {formOpen && (
                  <div id="lessonForm" className="lessonForm-container">
                    <h3 className="lessonForm-heading">UNIT</h3>
                    <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">Unit Name</p>
                      <input
                        type="text"
                        className="lessonForm-inputBar"
                        name="unitName"
                        maxLength={maxLengthForInputBox}
                        id="unitName"
                        value={unitDetails.unitName}
                        onChange={(event) => {
                          handleInput(event);
                          if (!editable) {
                            var today = new Date();
                            let value = event.target.value.replaceAll(" ", "");
                            let time = new Date().getTime();
                            setunitId(value + "_" + time);
                          }
                        }}
                      />
                    </div>
                    <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">Short Description</p>
                      <textarea
                        type="text"
                        className="lessonForm-inputBar"
                        id="unitDescription"
                        name="unitDescription"
                        value={unitDetails.unitDescription}
                        onChange={handleInput}
                      />
                    </div>

                    <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">Sample Unit</p>
                      <select
                        value={unitDetails.isSample}
                        name="isSample"
                        onChange={handleInput}
                        id="issampleUnit"
                        className="lessonForm-inputBar"
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                    <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel"> is Draft</p>
                      <select
                        value={unitDetails.isDraft}
                        name="isDraft"
                        onChange={handleInput}
                        id="isDraft"
                        className="lessonForm-inputBar"
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                    {/* <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">Quiz</p>
                      <select
                        value={unitDetails.hasQuiz}
                        name="hasQuiz"
                        onChange={handleInput}
                        id="hasQuiz"
                        className="lessonForm-inputBar"
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div> */}

                    <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">
                        Unit Release Schedule
                      </p>
                      <div className="lessonForm-row">
                        <input
                          type="date"
                          className="lessonForm-inputBar"
                          value={startDate}
                          onChange={(e) => {
                            setStartDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">
                        Unit Release Schedule
                      </p>
                      <div className="lessonForm-row">
                        <input
                          type="date"
                          className="lessonForm-inputBar"
                          value={startDate}
                          onChange={(e) => {
                            setStartDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">
                        Unit completion Text
                      </p>
                      <textarea
                        type="text"
                        className="lessonForm-inputBar"
                        id="completionText"
                        name="completionText"
                        value={unitDetails.completionText}
                        onChange={handleInput}
                      />
                    </div> */}

                    <div className="lessonForm-row">
                      <p className="lessonForm-inputLabel">Unit ID</p>
                      <input
                        disabled
                        type="text"
                        className="lessonForm-inputBar"
                        id="unitId"
                        value={unitId}
                      />
                    </div>

                    {checkFailed && (
                      <p style={{ color: "red" }}>Fill all the fields</p>
                    )}
                    <button className="lessonForm-save-btn" onClick={addLesson}>
                      Save Unit
                    </button>
                    <br />
                  </div>
                )}
                <div className="lessonList-container">
                  <div className="lessonForm-heading">UNIT</div>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="list">
                      {(provided) => (
                        <ul
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            display: "block",
                            overflow: "hidden",
                            width: "100%",
                            listStyleType: "none",
                            margin: "0px",
                            padding: "0px",
                          }}
                          id="lessonList"
                        >
                          {list.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={item.id}
                                  className="lessonList-draggable"
                                >
                                  <Accordion>
                                    <AccordionSummary
                                      style={{
                                        backgroundColor: "#FA9600",
                                        color: "white",
                                        borderRadius: "5px",
                                      }}
                                      expandIcon={<ExpandMoreSvg />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <SvgVector />
                                        <div className="lessonList-accordion-heading">
                                          {item.name}
                                        </div>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      style={{ borderRadius: "2px" }}
                                      isDragDisabled={true}
                                    >
                                      <div className="lessonList-draggable-summary">
                                        <p>{item.unitId}</p>
                                        <div
                                          onClick={() => {
                                            setEditDataflag(true);
                                            edit(item);
                                          }}
                                        >
                                          <MdEdit
                                            size={28}
                                            style={{
                                              float: "right",
                                              margin: "20px",
                                            }}
                                          />
                                        </div>
                                        <div
                                          onClick={() => {
                                            del(item.unitId, index);
                                          }}
                                        >
                                          {" "}
                                          <MdDelete
                                            style={{
                                              float: "right",
                                              margin: "20px",
                                            }}
                                            size={28}
                                            // onClick={ ()=>{del(item.unitId,index)}}
                                          />{" "}
                                        </div>

                                        {/* <h1  onClick={ ()=>{del(item.unitId)}}> delete </h1> */}
                                      </div>

                                      <p>{item.description}</p>
                                      <p>Course Associated - {isCourseIdSet}</p>
                                      <Item
                                        courseId={isCourseIdSet}
                                        unitId={item.unitId}
                                        itemsArr={item.itemsArr}
                                        editData={editdataflag}
                                      />
                                      <p></p>
                                      <br />
                                      <CreateLesson
                                        courseId={isCourseIdSet}
                                        unitId={item.unitId}
                                        editData={editdataflag}
                                      />
                                    </AccordionDetails>
                                  </Accordion>
                                </li>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                <CreateTopic
                  courseId={isCourseIdSet}
                  editData={true}
                  coursequizdata={coursequizdata}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateCourse;
