import React, { useEffect, useState } from "react";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadBytes,
  deleteObject,
} from "@firebase/storage";

import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import "./createQuiz.css";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import { ExpandMoreSvg } from "./icons";
import DeleteLesson from "./lessonDeletePopUp";
import CreateMcqQuestion from "./mcqQuestion";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";
const Matchthepairspicturebased = ({
  quizId,
  questionData,
  editQuestion,
  setfetchDataFromApi,
  fetchDataFromApi,
  flagForSeconstEdit,
}) => {
  const storage = getStorage();

  const createQuestion = httpsCallable(functions, "createQuestion");
  const addQuestionSetOrder = httpsCallable(functions, "addQuestionSetOrder");
  //addQuestionSetOrder
  // added by Himanshu

  const [questionName, setQuestionName] = useState("");
  const [questionId, setQuestionId] = useState("");

  const scrambleArr = (arr) => {
    //console.log(arr[0]);
    let res = Array.from(arr);
    //return res
    return res.sort((a, b) => 0.5 - Math.random());
  };

  const [canEditMatchPair, setCanEditMatchPair] = useState(true);

  const [imageOptions, setImageOptions] = useState(new Array(4).fill(""));
  const [imageFiles, setImageFiles] = useState([
    {
      base64: "",
      match: 1,
      url: "",
    },
    {
      base64: "",
      match: 2,
      url: "",
    },
    {
      base64: "",
      match: 3,
      url: "",
    },
    {
      base64: "",
      match: 4,
      url: "",
    },
  ]);
  const [disableImage, setDisableImage] = useState(false);

  const handleOptionsChange = (e, index) => {
    let { value } = e.target;
    setImageOptions((prev) => {
      let newArr = Array.from(prev);
      newArr[index] = value;
      return newArr;
    });
  };
  const handleImageChange = (e, index) => {
    let { files } = e.target;
    let reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result.length > 1000000) {
        alert("Image Too large, compress and try again");
        e.target.value = "";
        return;
      }
      setImageFiles((prev) => {
        let newArr = Array.from(prev);
        let item = {
          match: index + 1,
          url: URL.createObjectURL(files[0]),
          base64: reader.result,
        };
        newArr[index] = item;
        return newArr;
      });
      //console.log("Image converted ", index);
    };
    reader.readAsDataURL(files[0]);
  };

  const scrambleImage = () => {
    setDisableImage(true);
    setImageFiles((prev) => scrambleArr(prev));
  };

  const [imageMcq, setImageMcq] = useState([
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
  ]);

  //

  useEffect(() => {
    if (!editQuestion) return;
    // console.log(questionData)
    setqus(questionData.data?.question);
    setQuestionName(questionData?.name);
    setQuestionId(questionData?.id);
    setqusInstruction(questionData.data?.questionInstruction);
    setImageOptions(questionData.data.textOptions);

    let arr = [];
    for (let i = 0; i < questionData.data.imageOptions.length; i++) {
      if (questionData.data.imageOptions[i].match == 1) {
        arr[0] = { match: 1, url: questionData.data.imageOptions[i].base64 };
      }
      if (questionData.data.imageOptions[i].match == 2) {
        arr[1] = { match: 2, url: questionData.data.imageOptions[i].base64 };
      }
      if (questionData.data.imageOptions[i].match == 3) {
        arr[2] = { match: 3, url: questionData.data.imageOptions[i].base64 };
      }
      if (questionData.data.imageOptions[i].match == 4) {
        arr[3] = { match: 4, url: questionData.data.imageOptions[i].base64 };
      }

      // arr[op]=questionData.data.options[i].o

      if (i == questionData.data.imageOptions.length - 1) {
        setImageFiles(arr);
      }
    }
  }, [flagForSeconstEdit]);

  const [checkErrorInForm, setCheckErrorInForm] = useState({
    questionName: "",
    qus: "",
    qusInstruction: "",
    imageFiles: [
      {
        url: "",
      },
      {
        url: "",
      },
      {
        url: "",
      },
      {
        url: "",
      },
    ],

    imageOptions: ["", "", "", ""],
  });
  const formCheckFunction = () => {
    if (questionName === "") {
      setCheckErrorInForm((prev) => ({ ...prev, questionName: "error" }));
      return "question Name";
    }

    if (qus === "") {
      setCheckErrorInForm((prev) => ({ ...prev, qus: "error" }));
      return "question ";
    }
    if (qusInstruction === "") {
      setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "error" }));
      return "questionc ";
    }

    if (imageOptions[0] === "") {
      let op = ["error", "", "", ""];
      setCheckErrorInForm((prev) => ({ ...prev, imageOptions: op }));
      return "question ";
    }

    if (imageFiles[0].url === "") {
      let op = [{ url: "error" }, { url: "" }, { url: "" }, { url: "" }];
      setCheckErrorInForm((prev) => ({ ...prev, imageFiles: op }));
      return "question ";
    }

    if (imageOptions[1] === "") {
      let op = ["", "error", "", ""];
      setCheckErrorInForm((prev) => ({ ...prev, imageOptions: op }));
      return "question ";
    }

    if (imageFiles[1].url === "") {
      let op = [{ url: "" }, { url: "error" }, { url: "" }, { url: "" }];
      setCheckErrorInForm((prev) => ({ ...prev, imageFiles: op }));
      return "question ";
    }

    if (imageOptions[2] === "") {
      let op = ["", "", "error", ""];
      setCheckErrorInForm((prev) => ({ ...prev, imageOptions: op }));
      return "question ";
    }

    if (imageFiles[2].url === "") {
      let op = [{ url: "" }, { url: "" }, { url: "error" }, { url: "" }];
      setCheckErrorInForm((prev) => ({ ...prev, imageFiles: op }));
      return "question ";
    }

    if (imageOptions[3] === "") {
      let op = ["", "", "", "error"];
      setCheckErrorInForm((prev) => ({ ...prev, imageOptions: op }));
      return "question ";
    }

    if (imageFiles[3].url === "") {
      let op = [{ url: "" }, { url: "" }, { url: "" }, { url: "error" }];
      setCheckErrorInForm((prev) => ({ ...prev, imageFiles: op }));
      return "question ";
    }
    return "alliswell";
  };

  const [qusMode, setqusMode] = useState("");
  const [qustype, setqustype] = useState("1");
  const [qus, setqus] = useState("");
  const [qusInstruction, setqusInstruction] = useState("");
  const [PointorScore, setPointorScore] = useState("");
  const [qus5, setqus5] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  const [qus5Answer, setqus5Answer] = useState("Option 1");
  const [qus2, setqus2] = useState({ word: "", correctImage: "" });
  const [formCheck, setFormCheck] = useState("");
  function submit(e) {
    e.preventDefault();
  }

  async function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    let configCreateExtraInfo = {
      method: "patch",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/questionSet`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        questionSetId: quizId,
        questions: orderList,
        edit: true,
      }),
    };
    await axios(configCreateExtraInfo);
  }

  const storedDataOnFirebase = async () => {
    let check = formCheckFunction();

    let nsn = questionName.replaceAll(" ", "");
    let dat = /\//i.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any (/) characters");
      return;
    }
    if (check != "alliswell") {
      setFormCheck("check");
      return;
    }

    setFormCheck("");
    let questionTypeEnum;
    let managequestionData;

    if (qustype == "1") {
      if (!disableImage) return;
      let imageArr = [];
      imageFiles.forEach((item) => {
        let ele = {
          base64: item.base64 ? item.base64 : item.url,
          match: item.match,
        };
        imageArr.push(ele);
      });

      let questionDatafromUser = {
        textOptions: imageOptions,
        imageOptions: imageArr,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 0;
    }
    const docData = {
      questionName: questionName,
      questionId: questionId,
      associatedQuiz: quizId,
      questionSet: quizId,
      data: managequestionData,
      questionType: qustype,
      created: Timestamp.now(),
    };
    //console.log(docData);

    let configCreateExtraInfo = {
      method: "post",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/question`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        questionId: questionId,
        questionName: questionName,
        questionType: qustype,
        created: new Date().toString(),
        questionData: managequestionData,
        questionPoolAssociated: quizId,
      }),
    };
    axios(configCreateExtraInfo).then((res) => {
      setfetchDataFromApi(fetchDataFromApi + 1);
      alert("data saved successfully");
    });

    document.getElementById(quizId + "question-form").reset();
    document.getElementById(quizId + "questionName").value = "";
    toggleQuestionForm();
  };

  const toggleQuestionForm = (e) => {
    resetData();
    document.getElementById(quizId + "questionForm").style.display =
      document.getElementById(quizId + "questionForm").style.display == "none"
        ? "block"
        : "none";
  };

  function edit(lesson) {
    toggleQuestionForm();
  }

  function resetData() {
    setQuestionName("");
    setQuestionId("");
    setqustype("1");
    setqus("");
    setqusInstruction("");

    setCanEditMatchPair(true);

    setImageOptions(new Array(4).fill("options"));
    setDisableImage(false);
    setImageFiles([
      {
        base64: "",
        match: 1,
        url: "",
      },
      {
        base64: "",
        match: 2,
        url: "",
      },
      {
        base64: "",
        match: 3,
        url: "",
      },
      {
        base64: "",
        match: 4,
        url: "",
      },
    ]);
    setImageMcq([
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
    ]);
  }

  return (
    <section className="quizForm-container">
      <form id={quizId + "question-form"} onSubmit={submit}>
        <div className="quizForm-mainForm-infoRow">
          <p className="quizForm-mainForm-label">Question Name :</p>
          <input
            type="text"
            className="quizForm-mainForm-inputBar"
            id={quizId + "questionName"}
            value={questionName}
            style={{
              border: `${
                checkErrorInForm.questionName == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(e) => {
              setQuestionName(e.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, questionName: "" }));

              let value = e.target.value.replaceAll(" ", "");
              let time = new Date().getTime();
              //setQuestionId(value + "_" + time);
              if (!editQuestion) {
                setQuestionId(value + "_" + time);
              }
            }}
          />
          {checkErrorInForm.questionName == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Name
            </span>
          )}
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Question : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qus}
            style={{
              border: `${
                checkErrorInForm.qus == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqus(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qus: "" }));
            }}
          ></textarea>
          {checkErrorInForm.qus == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question
            </span>
          )}
        </div>
        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Instruction : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qusInstruction}
            style={{
              border: `${
                checkErrorInForm.qusInstruction == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqusInstruction(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "" }));
            }}
          ></textarea>

          {checkErrorInForm.qusInstruction == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Instruction
            </span>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          {imageOptions.map((item, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "1em",
              }}
            >
              <label className="quizForm-matchUpPic-label">
                Option {index + 1}
              </label>
              <input
                className="quizForm-matchUpPic-inputBar"
                disabled={disableImage ? "disabled" : ""}
                value={item}
                style={{
                  border: `${
                    checkErrorInForm.imageOptions[index] == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                onChange={(e) => {
                  handleOptionsChange(e, index);
                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    imageOptions: ["", "", "", ""],
                  }));
                }}
              />
              {checkErrorInForm.imageOptions[index] == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Question Option
                </span>
              )}
              {!disableImage && (
                <input
                  type={"file"}
                  accept="image/png, image/gif, image/jpeg"
                  style={{
                    border: `${
                      checkErrorInForm.imageFiles[index].url == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                  }}
                  onChange={(e) => {
                    handleImageChange(e, index);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      imageFiles: [
                        { url: "" },
                        { url: "" },
                        { url: "" },
                        { url: "" },
                      ],
                    }));
                  }}
                />
              )}
              {checkErrorInForm.imageFiles[index].url == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Upload Image
                </span>
              )}
              <img
                style={{
                  width: "100px",
                  height: "auto",
                  borderRadius: "5px",
                  backgroundColor: "#D9D9D9",
                }}
                src={imageFiles[index].url}
              />
            </div>
          ))}
          <div>
            <button
              className="quizFrom-button"
              style={{ margin: "1em" }}
              onClick={(e) => {
                e.preventDefault();
                scrambleImage();
              }}
            >
              Scramble
            </button>
          </div>
        </div>
      </form>
      {formCheck && <div> Fill all the Required fields.</div>}
      <div className="quizForm-mainForm-infoRow">
        <button
          className="quizFrom-button"
          style={{ marginLeft: "auto" }}
          onClick={storedDataOnFirebase}
        >
          Submit
        </button>
      </div>
    </section>
  );
};

export default Matchthepairspicturebased;
