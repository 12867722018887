import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import { functions } from "../../../Firebase";
import {
  ChemistryIcons,
  EnglishIcons,
  PhysicsIcons,
  PlusIcons,
} from "../../../utilities/Icons/Icons";
import "../../styles/Homepage/Homepage.css";
import "../../Translater/Translater";
import Navbar from "../Navbar/Navbar";
import CertificateCard from "./Cards/CertificateCard";
import TrainingCard from "./Cards/TrainingCard";

function Homepage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  const fetch_user_courses = httpsCallable(functions, "fetch_user_courses");
  const fetch_certificates = httpsCallable(functions, "fetch_certificates");
  const [internalTraining, setInternalTraining] = useState([]);
  const [externalTraining, setExternalTraining] = useState([]);
  const [internalOfflineTraining, setInternalOfflineTraining] = useState([]);
  const [previousTraining, setPreviousTraining] = useState([]);
  const [certificate, setCertificate] = useState([]);

  useEffect(() => {
    fetch_user_courses({ select: 0 }).then((result) => {
      // console.log(result);
      setExternalTraining([...result.data].sort(() => 0.5 - Math.random()));
    });
    fetch_user_courses({ select: 1 }).then((result) => {
      // console.log(result);
      setInternalTraining([...result.data].sort(() => 0.5 - Math.random()));
    });
    fetch_user_courses({ select: 2 }).then((result) => {
      // console.log(result.data);
      setInternalOfflineTraining(
        [...result.data].sort(() => 0.5 - Math.random())
      );
    });
    fetch_user_courses({ select: 3 }).then((result) => {
      // console.log(result.data);
      setPreviousTraining([...result.data].sort(() => 0.5 - Math.random()));
    });
    fetch_certificates({ select: 3 }).then((result) => {
      // console.log(result);
      setCertificate([...result.data].sort(() => 0.5 - Math.random()));
    });
  }, []);

  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "user") {
      goToHome();
      return <></>;
    }
  });

  return (
    <Box>
      <Navbar page={t("Home")} />
      <Box>
        <Box sx={{ py: 6 }}>
          <Container>
            <Box sx={{ width: "100%" }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 5 }}
              >
                <Grid item xs={8}>
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#00495F",
                          fontWeight: 600,
                          fontSize: "22px",
                        }}
                      >
                        My Training
                      </Typography>

                      <Link to="my-courses">
                        <Typography
                          sx={{
                            color: "#007C84",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {t("ViewAll")}
                        </Typography>
                      </Link>
                    </Box>
                    <Stack spacing="40px">
                      <Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            my: "30px",
                          }}
                        >
                          <Typography
                            sx={{
                              minWidth: "200px",
                              textAlign: "center",
                              fontWeight: 600,
                              background: "#F18040",
                              paddingY: "6px",
                              color: "#fff",
                              borderRadius: "5px",
                            }}
                          >
                            Internal Training
                          </Typography>
                          <Box
                            sx={{
                              width: "100%",
                              height: "2px",
                              backgroundColor: "#F18040",
                              position: "relative",
                            }}
                          >
                            <Box
                              sx={{
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                background: "#F18040",
                                position: "absolute",
                                top: "-4px",
                                right: "0px",
                              }}
                            ></Box>
                          </Box>
                        </Box>
                        {internalTraining[0] ? (
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            {internalTraining?.map((training, index) => {
                              if (index > 1) return;
                              return (
                                <TrainingCard key={index} training={training} />
                              );
                            })}
                            :
                          </Grid>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "100px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              boxShadow:
                                "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                              borderRadius: "10px",
                              color: "#007848",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            You Need To Enroll Internal Training First
                          </div>
                        )}
                      </Box>

                      <Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            my: "30px",
                          }}
                        >
                          <Typography
                            sx={{
                              minWidth: "200px",
                              textAlign: "center",
                              fontWeight: 600,
                              background: "#7783F4",
                              paddingY: "6px",
                              color: "#fff",
                              borderRadius: "5px",
                            }}
                          >
                            External Training
                          </Typography>
                          <Box
                            sx={{
                              width: "100%",
                              height: "2px",
                              backgroundColor: "#7783F4",
                              position: "relative",
                            }}
                          >
                            <Box
                              sx={{
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                background: "#7783F4",
                                position: "absolute",
                                top: "-4px",
                                right: "0px",
                              }}
                            ></Box>
                          </Box>
                        </Box>
                        {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          {externalTraining?.map((training, index) => {
                            if (index > 1) return;
                            return <TrainingCard key={index} training={training} />;
                          })}
                        </Grid> */}
                        {externalTraining[0] ? (
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            {externalTraining?.map((training, index) => {
                              if (index > 1) return;
                              return (
                                <TrainingCard key={index} training={training} />
                              );
                            })}
                          </Grid>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "100px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              boxShadow:
                                "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                              borderRadius: "10px",
                              color: "#007848",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            You Need To Enroll External Training First
                          </div>
                        )}
                      </Box>

                      <Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            my: "30px",
                          }}
                        >
                          <Typography
                            sx={{
                              minWidth: "200px",
                              textAlign: "center",
                              fontWeight: 600,
                              background: "#F47780",
                              paddingY: "6px",
                              color: "#fff",
                              borderRadius: "5px",
                            }}
                          >
                            Internal Offline Training
                          </Typography>
                          <Box
                            sx={{
                              width: "100%",
                              height: "2px",
                              backgroundColor: "#F47780",
                              position: "relative",
                            }}
                          >
                            <Box
                              sx={{
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                background: "#F47780",
                                position: "absolute",
                                top: "-4px",
                                right: "0px",
                              }}
                            ></Box>
                          </Box>
                        </Box>
                        {internalOfflineTraining[0] ? (
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            {internalOfflineTraining?.map((training, index) => {
                              if (index > 1) return;
                              return (
                                <TrainingCard key={index} training={training} />
                              );
                            })}
                          </Grid>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "100px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              boxShadow:
                                "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                              borderRadius: "10px",
                              color: "#007848",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            You Need To Enroll Internal offline Training First
                          </div>
                        )}
                      </Box>
                    </Stack>
                  </Box>

                  <Box sx={{ width: "100%", mt: "50px", mb: "20px" }}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#00495F",
                          fontWeight: 600,
                          fontSize: "22px",
                        }}
                      >
                        {t("Certificates")}
                      </Typography>

                      <Link to="my-certificate-view-all">
                        <Typography
                          sx={{
                            color: "#007C84",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {t("ViewAll")}
                        </Typography>
                      </Link>
                    </Box>
                    <Box spacing="true" sx={{ mt: "20px" }}>
                      {certificate[0] ? (
                        <Grid container spacing={4}>
                          {certificate?.map((certs, index) => {
                            if (index > 1) return;
                            return (
                              <CertificateCard
                                key={index}
                                certificate={certs}
                              />
                            );
                          })}
                        </Grid>
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            height: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow:
                              "4px 4px 10px rgba(0, 118, 135, 0.05), 6px 6px 40px rgba(0, 118, 135, 0.1)",
                            borderRadius: "10px",
                            color: "#007848",
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "24px",
                          }}
                        >
                          You Dont Have Any Certificates
                        </div>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{ fontSize: "22px", fontWeight: 600, color: "#00495F" }}
                  >
                    {t("PreviousTraining")}
                  </Typography>
                  <Box
                    sx={{
                      px: 2,
                      py: 3,
                      mt: 3,
                      borderRadius: "10px",
                      background: "#fff",
                    }}
                  >
                    <Box>
                      {
                        //// implement things here
                        previousTraining &&
                          previousTraining.map((training, index) => {
                            // console.log(training)
                            return (
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box sx={{ display: "flex" }}>
                                  <Box
                                    sx={{
                                      color: "#007C84",
                                      fontSize: "12px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    1
                                  </Box>
                                  <Box sx={{ ml: 1 }}>
                                    <Typography
                                      sx={{
                                        color: "#007C84",
                                        fontSize: "12px",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {t(training.courseName)}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: "#007C84",
                                        fontSize: "12px",
                                        my: 1,
                                      }}
                                    >
                                      Description about the course
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: "#007C84",
                                        fontSize: "10px",
                                        fontFamily: "Nunito",
                                      }}
                                    >
                                      12/10/2022
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box>
                                  <Box
                                    sx={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "5px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      background:
                                        "linear-gradient(to right, #007848, #004577)",
                                    }}
                                  >
                                    <PhysicsIcons />
                                  </Box>
                                </Box>
                              </Box>
                            );
                          })
                      }
                    </Box>

                    <Box sx={{ mt: 3 }}>
                      <Box>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "#007C84",
                          }}
                        >
                          View All
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 2,
                        }}
                      >
                        <Link
                          to="add-certificates"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            textTransform: "none",
                            color: "#fff",
                            padding: "10px 30px",
                            background:
                              "linear-gradient(to right, #007848, #004577)",
                            borderRadius: "5px",
                            fontWeight: 600,
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontFamily: "Nunito",
                              color: "#fff",
                            }}
                          >
                            {t("AddCertificate")}
                          </Typography>
                          <Box>
                            <PlusIcons />
                          </Box>
                        </Link>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "8px",
                          mt: 1,
                          color: "#007C84",
                          textAlign: "center",
                          fontWeight: 300,
                        }}
                      >
                        Add your previous Certificates from previous company
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}

export default Homepage;
