export const data = [
  {
    SRNo: "01",
    UserName: "Sagar B",
    EmployeeID: "#2154",
    CourseName: "UI UX",
    CourseID: "#2154",
    TrainingProvider: "Training Provider",
    StartDate: "06/05/2022",
    CompletionDate: "06/05/2022",
    GroupID: "#2154",
    Status: "Completed",
  },
  {
    SRNo: "01",
    UserName: "Sagar B",
    EmployeeID: "#2154",
    CourseName: "UI UX",
    CourseID: "#2154",
    TrainingProvider: "Training Provider",
    StartDate: "06/05/2022",
    CompletionDate: "06/05/2022",
    GroupID: "#2154",
    Status: "Overdue",
  },
  {
    SRNo: "01",
    UserName: "Sagar B",
    EmployeeID: "#2154",
    CourseName: "UI UX",
    CourseID: "#2154",
    TrainingProvider: "Training Provider",
    StartDate: "06/05/2022",
    CompletionDate: "06/05/2022",
    GroupID: "#2154",
    Status: "80%",
  },
  {
    SRNo: "01",
    UserName: "Sagar B",
    EmployeeID: "#2154",
    CourseName: "UI UX",
    CourseID: "#2154",
    TrainingProvider: "Training Provider",
    StartDate: "06/05/2022",
    CompletionDate: "06/05/2022",
    GroupID: "#2154",
    Status: "Expired",
  },
  {
    SRNo: "01",
    UserName: "Sagar B",
    EmployeeID: "#2154",
    CourseName: "UI UX",
    CourseID: "#2154",
    TrainingProvider: "Training Provider",
    StartDate: "06/05/2022",
    CompletionDate: "06/05/2022",
    GroupID: "#2154",
    Status: "Completed",
  },
  {
    SRNo: "01",
    UserName: "Sagar B",
    EmployeeID: "#2154",
    CourseName: "UI UX",
    CourseID: "#2154",
    TrainingProvider: "Training Provider",
    StartDate: "06/05/2022",
    CompletionDate: "06/05/2022",
    GroupID: "#2154",
    Status: "Overdue",
  },
  {
    SRNo: "01",
    UserName: "Sagar B",
    EmployeeID: "#2154",
    CourseName: "UI UX",
    CourseID: "#2154",
    TrainingProvider: "Training Provider",
    StartDate: "06/05/2022",
    CompletionDate: "06/05/2022",
    GroupID: "#2154",
    Status: "80%",
  },
  {
    SRNo: "01",
    UserName: "Sagar B",
    EmployeeID: "#2154",
    CourseName: "UI UX",
    CourseID: "#2154",
    TrainingProvider: "Training Provider",
    StartDate: "06/05/2022",
    CompletionDate: "06/05/2022",
    GroupID: "#2154",
    Status: "Expired",
  },
  {
    SRNo: "01",
    UserName: "Sagar B",
    EmployeeID: "#2154",
    CourseName: "UI UX",
    CourseID: "#2154",
    TrainingProvider: "Training Provider",
    StartDate: "06/05/2022",
    CompletionDate: "06/05/2022",
    GroupID: "#2154",
    Status: "Completed",
  },
  {
    SRNo: "01",
    UserName: "Sagar B",
    EmployeeID: "#2154",
    CourseName: "UI UX",
    CourseID: "#2154",
    TrainingProvider: "Training Provider",
    StartDate: "06/05/2022",
    CompletionDate: "06/05/2022",
    GroupID: "#2154",
    Status: "Overdue",
  },
  {
    SRNo: "01",
    UserName: "Sagar B",
    EmployeeID: "#2154",
    CourseName: "UI UX",
    CourseID: "#2154",
    TrainingProvider: "Training Provider",
    StartDate: "06/05/2022",
    CompletionDate: "06/05/2022",
    GroupID: "#2154",
    Status: "80%",
  },
  {
    SRNo: "01",
    UserName: "Sagar B",
    EmployeeID: "#2154",
    CourseName: "UI UX",
    CourseID: "#2154",
    TrainingProvider: "Training Provider",
    StartDate: "06/05/2022",
    CompletionDate: "06/05/2022",
    GroupID: "#2154",
    Status: "Expired",
  },
  {
    SRNo: "01",
    UserName: "Sagar B",
    EmployeeID: "#2154",
    CourseName: "UI UX",
    CourseID: "#2154",
    TrainingProvider: "Training Provider",
    StartDate: "06/05/2022",
    CompletionDate: "06/05/2022",
    GroupID: "#2154",
    Status: "Completed",
  },
  {
    SRNo: "01",
    UserName: "Sagar B",
    EmployeeID: "#2154",
    CourseName: "UI UX",
    CourseID: "#2154",
    TrainingProvider: "Training Provider",
    StartDate: "06/05/2022",
    CompletionDate: "06/05/2022",
    GroupID: "#2154",
    Status: "Overdue",
  },
  {
    SRNo: "01",
    UserName: "Sagar B",
    EmployeeID: "#2154",
    CourseName: "UI UX",
    CourseID: "#2154",
    TrainingProvider: "Training Provider",
    StartDate: "06/05/2022",
    CompletionDate: "06/05/2022",
    GroupID: "#2154",
    Status: "80%",
  },
  {
    SRNo: "01",
    UserName: "Sagar B",
    EmployeeID: "#2154",
    CourseName: "UI UX",
    CourseID: "#2154",
    TrainingProvider: "Training Provider",
    StartDate: "06/05/2022",
    CompletionDate: "06/05/2022",
    GroupID: "#2154",
    Status: "Expired",
  },
];
