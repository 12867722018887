import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import AdminSettings from "./Admin/AdminComponents/AdminSettings";
import CourseCreation from "./Admin/AdminComponents/Builder/CourseCreation";
import CreateCourse from "./Admin/AdminComponents/Builder/CreateUnit";
import QuestionForm from "./Admin/AdminComponents/Builder/QuestionForm";

import CourseList from "./Admin/AdminComponents/CourseList";

import Dashboard from "./Admin/AdminComponents/Dashboard";

import ExternalTraining from "./Admin/AdminComponents/ExternalTraining";
import ExternalTrainingEdits from "./Admin/AdminComponents/ExternalTrainingEdits";

import InternalTraining from "./Admin/AdminComponents/InternalTraining";

import TrainingEnrollments from "./Admin/AdminComponents/TrainingEnrollments";
import Trainings from "./Admin/AdminComponents/Trainings";

import TrainInternalTrainEn from "./Admin/AdminComponents/TrainInternalTrainEn";

import "./App.css";

import Hompage from "./User/components/Homepage/Hompage";

import LoginPage from "./User/components/loginpage/LoginPage";

import SetPassword from "./User/components/setPaassword/SetPassword";

import Sidebar from "./User/components/Sidebar/Sidebar";

import InternalTrainingMain from "./User/components/Training/InternalTrainingMain";

import TrainingMain from "./User/components/Training/TrainingMain";
import CategoryListAndAdd from "./Admin/AdminComponents/category/Category";
import GoogleAuth from "./AuthCom";
import { getCookieFromServer, getLocalStorage } from "./Cookies";
import axios from "axios";
import DeleteAccount from "./User/components/deleteAccount/DeleteAccount";
import DeleteUserReq from "./Admin/AdminComponents/deleteRequest/userDeleteReq";

const App = () => {
  useEffect(async () => {
    axios.defaults.withCredentials = true;
  }, []);
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/DeleteAccount" element={<DeleteAccount />} />

          <Route path="/GoogleAuth" element={<GoogleAuth />} />

          <Route path="/" element={<LoginPage />} />
          <Route path="/set-password" element={<SetPassword />} />
          <Route path="user" element={<Sidebar />}>
            <Route path="home" element={<Hompage />} />

            <Route path="training" element={<TrainingMain />} />
            <Route
              path="training/internal-training"
              element={<InternalTrainingMain />}
            />
          </Route>

          <Route path="/admin" element={<Dashboard />}></Route>

          <Route path="/admin/settings" element={<AdminSettings />}></Route>
          <Route path="/admin/courseBuilder" element={<CreateCourse />}></Route>

          <Route path="/admin/question" element={<QuestionForm />}></Route>
          <Route
            path="/admin/externalTraining"
            element={<ExternalTraining />}
          ></Route>
          <Route
            path="/admin/externalTrainingEdits/:id"
            element={<ExternalTrainingEdits />}
          ></Route>

          <Route path="/admin/courselist" element={<CourseList />} />

          <Route
            path="/admin/trainingenrollments"
            element={<TrainingEnrollments />}
          />
          <Route
            path="/admin/internaltraingenrolments"
            element={<InternalTraining />}
          />
          <Route path="/admin/coursecreation" element={<CourseCreation />} />
          <Route path="/admin/training" element={<Trainings />} />
          <Route
            path="/admin/training/internal"
            element={<TrainInternalTrainEn />}
          />
          <Route
            path="/admin/training/category"
            element={<CategoryListAndAdd />}
          />

          <Route
            path="/admin/training/DeleteUserReq"
            element={<DeleteUserReq />}
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
