import React, { createRef, useEffect } from "react";
import { useState } from "react";
import $ from "jquery";
// import CreateQuiz from "./CreateQuiz";
import CreateQuestionPool from "./CreateQquestionPool";
import { SvgVector, EditSvg, ExpandMoreSvg, DeleteSVg } from "./icons";
import { MdEdit, MdDelete } from "react-icons/md";
import { functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./createTopic.css";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";
import DeleteLesson from "./lessonDeletePopUp";

const CreateTopic = ({
  levelId,
  courseId,
  unitId,
  stageId,
  editData,
  quizstageList,
  levelSetList,
  unitquizdata,
  coursequizdata,
}) => {
  const initialList = [];
  const [list, setList] = useState([]);

  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [deleteQuestionId, setDeleteQuestionId] = useState("");

  const [quizId, setQuizId] = useState("");
  const [editable, setEditable] = useState(false);
  const [checkFailed, setCheckFailed] = useState(false);

  const [addcompletionText, setAddcompletionText] = useState();

  const [scheduledDateOfRelease, setScheduledDateOfRelease] = useState("");
  const [quizdataEdit, setQuizDataEdit] = useState(false);
  const [quizDetails, setQuizDetails] = useState({
    quizName: "",
    passScore: 0,
    quizDescription: "",
    timeLimit: 0,
  });

  const quizFormRef = createRef();

  const handleInput = (e) => {
    const { value, name } = e.target;
    setQuizDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  console.log("unitquiz in quiz component", unitquizdata);
  useEffect(() => {
    if (quizstageList) setList(quizstageList);
  }, [quizstageList]);
  useEffect(() => {
    if (unitquizdata) {
      setList(unitquizdata);
    }
  }, [unitquizdata]);
  useEffect(() => {
    console.log(coursequizdata);
    if (coursequizdata) setList(coursequizdata);
  }, [coursequizdata]);
  useEffect(() => {
    if (levelSetList) setList(levelSetList);
  }, [levelSetList]);

  function checkForm() {
    if (quizDetails.timeLimit == "") {
      return "dadadad";
    }
    let res = "allIsWell";

    return res;
  }

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    if (stageId != null) {
      console.log(stageId);
      // addStageQuizOrder({
      //   stageId: stageId,
      //   stageQuizzes: orderList,
      //   edit: true,
      // });

      let configProfile = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/addStageQuizOrder`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          stageId: stageId,
          stageQuizzes: orderList,
          edit: true,
        }),
      };

      axios(configProfile);
    } else if (levelId != null) {
      // addLevelQuizOrder({
      //   levelId: levelId,
      //   levelQuizzes: orderList,
      //   edit: true,
      // });

      let configProfile = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/addLevelQuizOrder`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          levelId: levelId,
          levelQuizzes: orderList,
          edit: true,
        }),
      };

      axios(configProfile);
    } else if (unitId != null) {
      let configProfile = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/addUnitQuizOrder`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          unitId: unitId,
          unitQuizzes: orderList,
          edit: true,
        }),
      };

      axios(configProfile);
      // addUnitQuizOrder({
      //   unitId: unitId,
      //   unitQuizzes: orderList,
      //   edit: true,
      // });
    } else if (courseId != null) {
      let configProfile = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/addCourseQuizOrder`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          associatedQuizzes: orderList,
          edit: true,
        }),
      };

      axios(configProfile);

      // addCourseQuizOrder({
      //   courseId: courseId,
      //   associatedQuizzes: orderList,
      //   edit: true,
      // });
    }
  }
  const addQuiz = async () => {
    let check = checkForm();
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }
    setCheckFailed(false);

    var newDate = scheduledDateOfRelease;

    console.log({
      quizId: quizId,
      rahul: "rahul",
    });
    console.log("rahullodhi");
    if (quizdataEdit) {
      // updateQuiz({
      //   quizId: quizId,
      //   quizName: quizDetails.quizName,
      //   quizDescription: quizDetails.quizDescription,
      // });

      let configProfile = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/quiz`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          quizId: quizId,
          quizDescription: quizDetails.quizDescription,
          quizName: quizDetails.quizName,
          levelAssociated: levelId,
          unitAssociated: unitId,
          courseAssociated: courseId,
          stageAssociated: stageId,
        }),
      };

      axios(configProfile);

      // updateQuizExtraInfo({
      //   quizId: quizId,
      //   completionText: addcompletionText,

      //   lastUpdatedDate: Timestamp.fromDate(new Date()),
      //   passingScore: Number(quizDetails.passScore),
      //   scheduledDateOfRelease: Timestamp.fromDate(newDate),

      //   timeLimit: Number(quizDetails.timeLimit),
      // });

      let configQuiz = {
        method: "patch",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/quiz`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          quizId: quizId,
          completionText: addcompletionText,

          creationDate: Timestamp.fromDate(new Date()),
          lastUpdatedDate: Timestamp.fromDate(new Date()),
          passingScore: Number(quizDetails.passScore),
          scheduledDateOfRelease: newDate,

          timeLimit: Number(quizDetails.timeLimit),
        }),
      };

      axios(configQuiz);
      setQuizDataEdit(false);
      setQuizDetails({
        quizName: "",
        passScore: "",

        timeLimit: "",
        quizDescription: "",
      });
      alert("update");
    } else {
      const newList = list.concat({
        id: quizId,
        name: quizDetails.quizName,
        quizDescription: quizDetails.quizDescription,
        passScore: Number(quizDetails.passScore),

        timeLimit: Number(quizDetails.timeLimit),
        success: addcompletionText,
        scheduledDateOfRelease: scheduledDateOfRelease,
      });
      /// quizId:data.quizId,
      // associatedCourse:data.associatedCourse?data.associatedCourse:"",//
      // associatedLevel:data.associatedLevel?data.associatedLevel:"",
      // associatedStage:data.associatedStage?data.associatedStage:"",
      // associatedUnit:data.associatedUnit?data.associatedUnit:"",
      // questionPoolId:data.questionPoolId
      // createQuiz({
      //   quizId: quizId,
      //   quizDescription: quizDetails.quizDescription,
      //   quizName: quizDetails.quizName,
      //   associatedLevel: levelId,
      //   associatedUnit: unitId,
      //   associatedCourse: courseId,
      //   associatedStage: stageId,
      // });

      let configProfile = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/quiz`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          quizId: quizId,
          quizDescription: quizDetails.quizDescription,
          quizName: quizDetails.quizName,
          levelAssociated: levelId,
          unitAssociated: unitId,
          courseAssociated: courseId,
          stageAssociated: stageId,
        }),
      };

      axios(configProfile);

      /// completionText:	data.completionText?data.completionText:"",
      // passingScore:data.passingScore?data.passingScore:40,
      // timeLimit:data.timeLimit?data.timeLimit:20,
      // creationDate:data.creationDate,
      // lastUpdatedDate:data.lastUpdatedDate,
      // scheduledDateOfRelease:data.scheduledDateOfRelease?data.scheduledDateOfRelease:""

      let configQuiz = {
        method: "patch",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/quiz`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          quizId: quizId,
          completionText: addcompletionText,

          creationDate: Timestamp.fromDate(new Date()),
          lastUpdatedDate: Timestamp.fromDate(new Date()),
          passingScore: Number(quizDetails.passScore),
          scheduledDateOfRelease: newDate,

          timeLimit: Number(quizDetails.timeLimit),
        }),
      };

      axios(configQuiz);
      // addQuizExtraInfo({

      // });

      setList(newList);
      saveOrder(newList);
      alert("Added");
    }

    setQuizDetails({
      quizName: "",
      passScore: "",

      timeLimit: "",
      quizDescription: "",
    });
    setAddcompletionText("");

    toggleForm();
  };

  const toggleForm = (e) => {
    quizFormRef.current.style.display =
      quizFormRef.current.style.display === "none" ? "" : "none";
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(quiz) {
    setEditable(true);
    setQuizDataEdit(true);
    quizFormRef.current.style.display = "";

    let params = {
      quizName: quiz.name,
      passScore: quiz.passScore,

      timeLimit: quiz.timeLimit,
      quizDescription: quiz.quizDescription,
    };
    setQuizDetails(params);
    setQuizId(quiz.id);
    setAddcompletionText(quiz.success);
    setScheduledDateOfRelease(quiz.scheduledDateOfRelease);
  }

  async function del(quiz) {
    setDeleteQuestionId(quiz);
    setopenLessonPopUp(true);
    // let newArr = [...list];
    // deleteQuiz({ quizId: quiz.id });

    // setList(newArr);
    // saveOrder(newArr);
  }

  const delfunction = async (question) => {
    // db.collection("question").doc(question.id).delete();

    let configCreateExtraInfo = {
      method: "delete",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/quiz`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        quizId: question.id,
      }),
    };
    await axios(configCreateExtraInfo);
    let newArr = list.filter((el) => el.id != question.id);

    setList(newArr);

    saveOrder(newArr, true);
    alert("Deleted ");
  };
  return (
    <>
      <section className="createQuiz-container">
        <DeleteLesson
          open={openLessonPopUp}
          onClose={() => setopenLessonPopUp(false)}
          delfun={(topic) => {
            delfunction(topic);
          }}
          topicId={deleteQuestionId}
        />
        <button
          className="createQuiz-btn"
          onClick={() => {
            setEditable(false);
            toggleForm();
          }}
        >
          Add Quiz
        </button>
        <div
          className="createQuiz-quizForm"
          ref={quizFormRef}
          id={"quizForm"}
          style={{ display: "none" }}
        >
          <div className="createQuiz-heading">Quiz</div>
          <div className="createQuiz-row">
            <label className="createQuiz-inputLabel">Quiz Name</label>
            <input
              type="text"
              className="createQuiz-inputBar"
              id={levelId + courseId + unitId + "quizName"}
              name="quizName"
              value={quizDetails.quizName}
              onChange={(event) => {
                handleInput(event);
                if (!editable) {
                  let value = event.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();
                  setQuizId(value + "_" + time);
                }
              }}
            />
          </div>

          <div className="createQuiz-row">
            <label className="createQuiz-inputLabel">Quiz Description</label>
            <textarea
              type="text"
              maxLength={40}
              className="createQuiz-inputBar"
              id={levelId + courseId + unitId + "quizDescription"}
              name="quizDescription"
              value={quizDetails.quizDescription}
              onChange={(event) => {
                handleInput(event);
              }}
            />
          </div>

          <div className="createQuiz-row">
            <label className="createQuiz-inputLabel">
              Passing Score in Percentage
            </label>
            <input
              type="number"
              className="createQuiz-inputBar"
              id={levelId + courseId + unitId + "passScore"}
              name={"passScore"}
              max={100}
              value={quizDetails.passScore}
              onChange={handleInput}
            />
          </div>

          <div className="createQuiz-row">
            <p className="createQuiz-inputLabel">Time Limit (minutes)</p>
            <input
              type="number"
              className="createQuiz-inputBar"
              id={levelId + courseId + unitId + "timeLimit"}
              name="timeLimit"
              value={quizDetails.timeLimit}
              onChange={handleInput}
            />
          </div>

          {/* <div className="createQuiz-row">
            <p className="createQuiz-inputLabel"> completion Text</p>
            <input
              type="text"
              className="createQuiz-inputBar"
              id={levelId + courseId + unitId + "successMessage"}
              value={addcompletionText}
              name="successMessage"
              onChange={(e) => {
                setAddcompletionText(e.target.value);
              }}
            />
          </div> */}

          {/* <div className="createQuiz-row">
            <p className="createQuiz-inputLabel">Scheduled Date Of Release</p>
            <input
              type="date"
              className="createQuiz-inputBar"
              value={scheduledDateOfRelease}
              name="retakeMessage"
              onChange={(e) => {
                setScheduledDateOfRelease(e.target.value);
              }}
            />
          </div> */}

          {checkFailed && (
            <p style={{ color: "red" }}>Enter the Required Fields</p>
          )}
          <div className="createQuiz-row">
            <button className="createQuiz-btn-save" onClick={addQuiz}>
              Save Quiz
            </button>
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                id="quizList"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  listStyleType: "none",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {list?.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={item.id}
                        className="quiz-dragable"
                      >
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreSvg />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              backgroundColor: "#2DB5B2",
                              color: "white",
                              borderRadius: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <SvgVector />
                              <div className="quiz-accordion-heading">
                                {item.name}
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="quiz-accordion-details-head">
                              <p>{item.name}</p>
                              <div
                                style={{ float: "right", margin: "20px" }}
                                onClick={() => edit(item)}
                              >
                                <MdEdit size={28} />
                              </div>

                              <div
                                style={{ float: "right", margin: "20px" }}
                                onClick={() => del(item, index)}
                              >
                                <MdDelete
                                  size={28}
                                  // onClick={() => del(item,index)}
                                />
                              </div>
                            </div>
                            <p>Associated id -{item.id} </p>
                            <p>Quiz Name -{item.name} </p>
                            <CreateQuestionPool
                              quizId={item.id}
                              editData={editData}
                            />
                            {/* <CreateQuiz quizId={item.id} /> */}
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>
    </>
  );
};

export default CreateTopic;
