import React from 'react';
import { useState, setState } from 'react';
import $ from 'jquery';
import { db } from '../../Firebase';
import { arrayUnion, doc, Firestore, setDoc, query, where, collection, getDocs, Timestamp } from "firebase/firestore";
import {useParams} from "react-router-dom";
import { getCookie } from "../../Cookies";
import { useNavigate } from "react-router";
import { useEffect } from "react";

function ExternalTrainingEdits(){

    const navigate = useNavigate();
    const goToHome = () => navigate("/");

    useEffect(()=>{
        if(getCookie("UID")==null || getCookie("ROLE")!="admin"){
            goToHome();
            return (<></>);
        }
    })
    const {id} = useParams();

    const handleOnChangeExtTrain = (e) => {
        e.preventDefault();

        if(document.getElementById("external-training-name").value && !(document.getElementById("external-training-name").value === "") && !(document.getElementById("external-training-name").value === " "))

            document.getElementById("external-training-id").value = document.getElementById("external-training-name").value+"_"+Date.now();
    
        else
            document.getElementById("external-training-id").value = "";
    };

    const handleOnClickExtTrain = async (e) => {
        e.preventDefault();
        await setDoc(doc(db, "externalTraining", document.getElementById('external-training-id').value),{
            trainingName: document.getElementById('external-training-name').value,
            trainingDesc: document.getElementById('external-training-desc').value,
            trainingType: "External",
            trainingProvider: document.getElementById("external-training-provider").value,
            createdOn: Timestamp.now()
        })

        alert("training Added");

    };

    $(document).ready(async function(){
        if(id!=":id"){
            var info = id.split(",");
            await db.collection("externalTraining").where("trainingName", "==", info[0]).get().then((snapshot)=>{
                snapshot.forEach((doc)=>{
                    document.getElementById('external-training-provider').value = doc.data().trainingProvider;
                    document.getElementById('external-training-id').value = doc.id;
                    document.getElementById('external-training-desc').value = doc.data().trainingDesc;
                    document.getElementById('external-training-name').value = info[0];
                    document.getElementById('external-training-name').disabled = true;
                })
            })
        }
    })

    return(
        <>
            <h1>Add/Edit External Training</h1>

            <label for='external-training-name'>Training Name</label> 
            < input type='text' name='external-training-name' id='external-training-name' onChange={handleOnChangeExtTrain} />

            <label for='external-training-provider'>External Training Provider</label>
            < input type='text' name='external-training-provider' id='external-training-provider'/>

            <label for='external-training-id'>External Training ID</label>
            < input type='text' name='external-training-id' id='external-training-id' disabled/>

            <label for='external-training-desc'>External Training Short Description</label>
            < textarea name='external-training-desc'  id='external-training-desc' />

            <button onClick={handleOnClickExtTrain}>Save and Update</button>
        </>
    )

}

export default ExternalTrainingEdits;