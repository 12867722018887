import { Button, Container, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import loginLogo from "../img/logo/logo.png";

import { IP } from "../../../config";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { createBrowserHistory } from "history";

import axios from "axios";
import DeletePopUpForAccount from "./deletePopUp";

function DeleteAccount() {
  const goToHome = () => navigate("/");
  const goToUserDashboard = () => navigate("/user/home");
  const goToAdminDashboard = () => navigate("/admin");
  const goToInstructorDashboard = () => navigate("/instructor");
  const [linkSent, setLinkSet] = useState(false);
  const [email, setEmail] = useState("");
  const customHistory = createBrowserHistory();
  customHistory.push = (path, state) => {
    // Clear the history stack before pushing the new entry.
    customHistory.length = 1;

    // Push the new entry to the history stack.
    customHistory.push(path, state);
  };
  const navigate = useNavigate();

  const [newpass, setnewpass] = useState("");
  const [confermpass, setconfermass] = useState("");
  //const [clicked, setLoading] = useState(false);

  const [passwordError, setPasswordError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [shownewPassword, setShownewPassword] = useState(false);

  const [popup, setPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Note * : Your account will be deleted within 30 days"
  );
  const [errorFlagData, setErrorFlagData] = useState(false);
  const par = useParams();
  ///========visibility password=======//

  const clearErrors = () => {
    setUserNameError("");
    setPasswordError("");
  };

  const deleteFun = () => {
    setErrorFlagData(false);
    // console.log("setUserPassword");

    if (!newpass || !confermpass) {
      setLoading(false);

      setPasswordError("Fill the form");
      return;
    }
    setPopup(true);
  };
  const setUserPassword = async (e) => {
    // e.preventDefault();

    setLoading(true);

    // can make is so that if a continue url is given we can redirect to the continue url
    //console.log(value);
    var data = JSON.stringify({
      type: newpass,
      value: confermpass,
    });

    var config = {
      method: "delete",

      url: `${IP}/userDelete/deleteProfile`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((userCredential) => {
        setLoading(false);
        setLinkSet(true);
      })
      .catch((error, res) => {
        setLoading(false);
        setPasswordError(error.response?.data?.message);
        setconfermass("");
        setnewpass("");
        //console.log(error.code);
        // setError(error.code);
      });
  };

  return (
    <Box
      sx={{
        py: { md: "0", height: "100vh", background: "#F5F5F5" },
      }}
    >
      <DeletePopUpForAccount
        onClose={() => {
          setPopup(false);
        }}
        open={popup}
        delfun={() => {
          setUserPassword();

          // alert("deleted");
        }}
        topicId={"trss"}
        itemName={"ts"}
      />
      <Container
        sx={{
          // ...responsiveReverseFlexDirection,

          gap: "50px",

          // display: "flex",
          // justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img
            // className={classes.logo}
            src={loginLogo}
            alt=""
            style={{
              marginTop: "60px",

              height: "70px",
              background: "none",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            justifyContent: "center",
            gridGap: "20px",
            mt: "20px",
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "20px",
            color: "#6846C7",
          }}
        >
          <Box>Englishio</Box>
          <Box>Account deletion request</Box>
          <Box>Developer Name:Ibrahim Alajlan</Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: "50px",
          }}
        >
          <Box
            sx={{
              // ...responsiveWidth,

              border: "2px solid #6846C7",
              borderRadius: "20px",
              width: "476px",
              background: "#EDEAFF",
              // height: "70vh",
            }}
          >
            {!linkSent ? (
              <Box sx={{ padding: { xs: "25px", md: "50px" } }}>
                <form action="">
                  <Box>
                    <div
                      style={{
                        // backgroundColor: "#FFFFFF",
                        width: "100%",
                        borderRadius: "10px",
                        // padding: "20px",
                        // outline: "none",
                        display: "flex",
                        marginTop: "10px",
                        gap: "20px",
                        // justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          marginTop: "30px",
                          fontSize: "20px",
                          width: "30%",
                          // justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        Type
                      </div>
                      <div
                        style={{
                          backgroundColor: "#FFFFFF",
                          width: "90%",
                          borderRadius: "10px",
                          padding: "15px",
                          outline: "none",
                          display: "flex",
                          marginTop: "30px",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <select
                          type={shownewPassword ? "text" : "password"}
                          required
                          id={
                            shownewPassword ? "showPassword" : "hiddePassword"
                          }
                          value={newpass}
                          onChange={(e) => setnewpass(e.target.value)}
                          placeholder="New password"
                          style={{
                            width: "100%",
                            padding: "7px",
                            height: "40px",
                            border: "none",
                            background: "#FFFFFF",
                            fontSize: "20px",

                            // marginTop: "30px",
                            borderRadius: "16px",
                            outline: "none",
                          }}
                        >
                          <option value="">Select login type</option>

                          <option value="apple">Apple</option>
                          <option value="phone">Mobile</option>
                          <option value="google">Google</option>
                          <option value="facebook">Facebook</option>
                        </select>
                      </div>
                    </div>
                  </Box>

                  <Box>
                    <div
                      style={{
                        // backgroundColor: "#FFFFFF",
                        width: "100%",
                        borderRadius: "10px",
                        // padding: "15px",
                        outline: "none",
                        display: "flex",
                        gap: "20px",
                        // marginTop: "30px",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "30%",
                          display: "flex",
                          marginTop: "30px",
                          fontSize: "20px",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        Identifier value
                      </div>
                      <div
                        style={{
                          backgroundColor: "#FFFFFF",
                          width: "100%",
                          borderRadius: "10px",
                          padding: "15px",
                          outline: "none",
                          display: "flex",
                          marginTop: "30px",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type={"text"}
                          required
                          // id={showPassword ? "showPassword" : "hiddePassword"}
                          value={confermpass}
                          onChange={(e) => setconfermass(e.target.value)}
                          placeholder="Identifier value"
                          style={{
                            width: "100%",
                            padding: "7px",
                            height: "30px",
                            border: "none",
                            background: "#FFFFFF",

                            // marginTop: "30px",
                            borderRadius: "16px",
                            outline: "none",
                          }}
                        />
                      </div>
                    </div>
                  </Box>

                  <Box sx={{ mt: 3 }}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        color: "#C40B0B",
                        fontWeight: 400,
                        fontSize: { xs: "12px", md: "14px" },
                      }}
                    >
                      <Box>{errorMessage}</Box>
                    </Typography>
                  </Box>
                  <Box sx={{ mt: "30px" }}>
                    <Button
                      // type="submit"
                      onClick={() => {
                        deleteFun();
                      }}
                      sx={{
                        textTransform: "none",
                        width: "100%",
                        py: 1,
                        borderRadius: "15px",
                        fontSize: "16px",
                        fontWeight: 500,
                        background: "#6846C7",
                        color: "#fff",
                        "&:hover": {
                          background: "#6846C7",
                        },
                      }}
                    >
                      {loading ? "Sending ..." : "Submit"}
                    </Button>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        color: "#C40B0B",
                        fontWeight: 400,
                        fontSize: { xs: "12px", md: "14px" },
                      }}
                    >
                      <Box>{passwordError}</Box>
                    </Typography>
                  </Box>
                </form>
              </Box>
            ) : (
              <Box sx={{ p: 7 }}>
                <Box>
                  <Typography
                    variant="h3"
                    sx={{ color: "#007848", fontWeight: 700 }}
                  >
                    Your request submitted successfully
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default DeleteAccount;
