import React from "react";
import { useState, setState } from "react";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { db } from "../../Firebase";
import $ from "jquery";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { useDispatch } from "react-redux";
import { createClrs } from "../Redux/Actions";
import { useTranslation } from "react-i18next";
import "../Translater/Translater";
import { defultGreen, blue, GradiantGreen } from "../Redux/Colors";
import { getCookie } from "../../Cookies";
import { useNavigate } from "react-router";
import { useEffect } from "react";

function AdminSettings() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  useEffect(() => {
    if (getCookie("UID") == null ) {
      goToHome();
      return <></>;
    }
  });
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const submithandler = (i) => {
    if (i === 1) {
      dispatch(createClrs(defultGreen));
      localStorage.setItem("clrs", JSON.stringify(defultGreen));
    } else if (i === 2) {
      dispatch(createClrs(blue));
      localStorage.setItem("clrs", JSON.stringify(blue));
    } else if (i === 3) {
      i18n.changeLanguage("ar");
      localStorage.setItem("lang", JSON.stringify("ar"));
    } else if (i === 4) {
      i18n.changeLanguage("en");
      localStorage.setItem("lang", JSON.stringify("en"));
    } else if (i === 5) {
      localStorage.setItem("clrs", JSON.stringify(GradiantGreen));
    }
  };

  const [companyname, setCompanyName] = useState("");
  const [color, setColor] = useColor("hex", "#ff0000");
  const storage = getStorage();

  function clear() {
    setCompanyName("");
  }

  function AddCompany() {
    alert("Adding Company");
    db.collection("company")
      .add({ name: companyname, brandColor: color.hex })
      .then((doc) => {
        const metadata = {
          contentType: $("#logo")[0].files[0].type,
        };
        const fileName = $("#logo")[0].files[0].name;
        const storageRef = ref(
          storage,
          "companyLogo/" +
            doc.id +
            fileName.substring(fileName.lastIndexOf("."))
        );
        const uploadTask = uploadBytesResumable(
          storageRef,
          $("#logo")[0].files[0],
          metadata
        );
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {},
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log("File available at", downloadURL);
              db.collection("company")
                .doc(doc.id)
                .update({ logoURL: downloadURL })
                .then(() => {
                  alert("Company Added");
                  clear();
                });
            });
          }
        );
      });
  }
  return (
    <>
      <div>
        <div>
          <button onClick={() => submithandler(1)}>Green color</button>
          <button onClick={() => submithandler(2)}>dark blue color</button>
          <button onClick={() => submithandler(5)}>green gradiant</button>
          <button onClick={() => submithandler(3)}>change lang to ar</button>
          <button onClick={() => submithandler(4)}>change lang to en</button>
        </div>
        <p className="inputLabel">Company Name</p>
        <input
          type="text"
          value={companyname}
          className="inputBar"
          id="companyname"
          onChange={(event) => {
            setCompanyName(event.target.value);
          }}
        />
      </div>
      <div style={{ margin: "20px" }}>
        <p className="inputLabel">Upload Logo</p>
        <input
          type="file"
          accept="image/png, image/gif, image/jpeg"
          className="inputBar"
          id="logo"
        />
      </div>
      <div style={{ margin: "20px" }}>
        <p className="inputLabel">Brand Color</p>
        <ColorPicker
          width={456}
          height={228}
          color={color}
          onChange={setColor}
          hideHSV
          dark
        />
        ;
      </div>
      <button onClick={AddCompany}>Add Company details</button>
    </>
  );
}

export default AdminSettings;
