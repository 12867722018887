import React, { useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
  ChemistryIcons,
  EnglishIcons,
  PhysicsIcons,
  PlusIcons,
} from "../../../../utilities/Icons/Icons";

import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Timestamp } from "firebase/firestore";

const TrainingCard = ({ training }) => {
  const { t } = useTranslation();
  const dueDate = Timestamp.fromMillis(training?.dueDate?._seconds * 1000);

  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  return (
    <Grid item xs={6}>
      <Box
        sx={{
          background: "#fff",
          padding: "15px",
          borderRadius: "10px",
          boxShadow: "4px 4px 10px #0076870d, 6px 6px 40px #0076871a",
        }}
      >
        <Box spacing="true" sx={{ display: "flex" }}>
          <Box sx={{ width: "20%" }}>
            <Box
              sx={{
                width: "50px",
                height: "50px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "linear-gradient(to right, #007848, #004577)",
              }}
            >
              <PhysicsIcons />
            </Box>
          </Box>
          <Box
            sx={{
              width: "80%",
              marginTop: "10px",
              ml: 1,
            }}
          >
            <Box>
              <Box>
                <Typography
                  sx={{
                    color: "#007C84",
                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                >
                  {training?.courseName}
                </Typography>
                <Typography
                  sx={{
                    color: "#007C84",
                    fontSize: "12px",
                  }}
                >
                  Description about the course
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    color: "#007C84",
                    fontSize: "10px",
                    fontFamily: "Nunito",
                  }}
                >
                  {dueDate.toDate().toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </Typography>
                <Button
                  sx={{
                    textTransform: "none",
                    color: "#fff",
                    padding: "5px 30px",
                    background: "linear-gradient(to right, #007848, #004577)",
                    borderRadius: "5px",
                    fontWeight: 600,
                    fontSize: "12px",
                  }}
                >
                  {t("Start")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default TrainingCard;
