import React, { useEffect, useState } from "react";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadBytes,
  deleteObject,
} from "@firebase/storage";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import "./createQuiz.css";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import { ExpandMoreSvg } from "./icons";
import DeleteLesson from "./lessonDeletePopUp";
import CreateMcqQuestion from "./mcqQuestion";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";
const Choosethecorrectsequence = ({
  quizId,
  questionData,
  editQuestion,
  setfetchDataFromApi,
  fetchDataFromApi,
  flagForSeconstEdit,
}) => {
  const storage = getStorage();

  const [deleteQuestionId, setDeleteQuestionId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);

  const [questionName, setQuestionName] = useState("");
  const [questionId, setQuestionId] = useState("");

  const initialList = [];
  const [list, setList] = useState(initialList);

  const [picMCQ, setPicMCQ] = useState({
    url: "",
    base64: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: 0,
  });
  const [trueFalse, setTrueFalse] = useState(false);

  const [fillUpsAns, setFillUpsAns] = useState("");
  // rearrange
  const [canEditRearrange, setCanEditRearrange] = useState(true);
  const [rearrangeOptions, setRearrangeOptions] = useState([
    {
      text: "First",
      position: 1,
    },
  ]);

  const handleRearrange = (e, order) => {
    console.log(e, order);
    if (!canEditRearrange) return;
    setRearrangeOptions((prev) => {
      let item = {
        text: e.target.value,
        position: order,
      };
      let res = Array.from(prev);
      res[order - 1] = item;
      return res;
    });
  };
  const handleScramble = () => {
    setCanEditRearrange(false);
    setRearrangeOptions((prev) => {
      let res = scrambleArr(prev);
      //console.log(res);
      return res;
    });
  };
  const scrambleArr = (arr) => {
    //console.log(arr[0]);
    let res = Array.from(arr);
    return res.sort((a, b) => 0.5 - Math.random());
  };

  const [canEditMatchPair, setCanEditMatchPair] = useState(true);
  const [leftOptions, setLeftOptions] = useState(new Array(4).fill(""));
  const [rightOptions, setRightOptions] = useState([
    {
      text: "",
      match: 1,
    },
    {
      text: "",
      match: 2,
    },
    {
      text: "",
      match: 3,
    },
    {
      text: "",
      match: 4,
    },
  ]);

  const [imageOptions, setImageOptions] = useState(
    new Array(4).fill("options")
  );
  const [imageFiles, setImageFiles] = useState([
    {
      base64: "",
      match: 1,
      url: "",
    },
    {
      base64: "",
      match: 2,
      url: "",
    },
    {
      base64: "",
      match: 3,
      url: "",
    },
    {
      base64: "",
      match: 4,
      url: "",
    },
  ]);
  const [disableImage, setDisableImage] = useState(false);
  const [uploading, setUploading] = useState(false);
  const handleOptionsChange = (e, index) => {
    let { value } = e.target;
    setImageOptions((prev) => {
      let newArr = Array.from(prev);
      newArr[index] = value;
      return newArr;
    });
  };

  const [imageMcq, setImageMcq] = useState([
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
  ]);
  const [imageMcqAnswer, setImageMcqAnswer] = useState(0);

  //

  // useEffect(() => {
  //   if (!questionData) return;
  //   for (let i = 0; i < questionData.length; i++) {
  //     setList((prev) =>
  //       prev.concat({
  //         id: questionData[i].questionId,
  //         name: questionData[i].questionName,
  //         type: questionData[i].questionType,
  //       })
  //     );
  //   }
  // }, []);

  useEffect(() => {
    if (!editQuestion) return;

    //console.log(questionData)
    setqus(questionData.data?.question);
    setQuestionName(questionData?.name);
    setQuestionId(questionData?.id);
    setqusInstruction(questionData.data?.questionInstruction);

    if (questionData.data.options) {
      let arr = [];
      // console.log(questionData.data.options);
      for (let i = 0; i < questionData.data.options.length; i++) {
        let op = `option${i + 1}`;
        console.log(op);
        arr[i] = { text: questionData.data.options[i].text, position: i + 1 };

        // if (questionData.data.options[i].position == 1) {
        //   arr[0] = { text: questionData.data.options[i].text, position: 1 };
        // }
        // if (questionData.data.options[i].position == 2) {
        //   arr[1] = { text: questionData.data.options[i].text, position: 2 };
        // }
        // if (questionData.data.options[i].position == 3) {
        //   arr[2] = { text: questionData.data.options[i].text, position: 3 };
        // }
        // if (questionData.data.options[i].position == 4) {
        //   arr[3] = { text: questionData.data.options[i].text, position: 4 };
        // }
        // arr[op]=questionData.data.options[i].option

        setRearrangeOptions();

        if (i == questionData.data.options.length - 1) {
          setRearrangeOptions(arr);
          //setqus5(arr)
        }

        setCanEditRearrange(true);
      }
    }
  }, [flagForSeconstEdit]);

  const [checkErrorInForm, setCheckErrorInForm] = useState({
    questionName: "",
    qusInstruction: "",
    qus: "",
  });
  const formCheckFunction = () => {
    if (questionName === "") {
      setCheckErrorInForm((prev) => ({ ...prev, questionName: "error" }));
      return "question Name";
    }

    if (qus === "") {
      setCheckErrorInForm((prev) => ({ ...prev, qus: "error" }));
      return "question ";
    }
    if (qusInstruction === "") {
      setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "error" }));
      return "question ";
    }
    return "alliswell";
  };

  const [qusMode, setqusMode] = useState("");
  const [qustype, setqustype] = useState("6");
  const [qus, setqus] = useState("");
  const [qusInstruction, setqusInstruction] = useState("");
  const [PointorScore, setPointorScore] = useState("");
  const [qus5, setqus5] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  const [qus5Answer, setqus5Answer] = useState("Option 1");
  const [qus2, setqus2] = useState({ word: "", correctImage: "" });
  const [formCheck, setFormCheck] = useState("");
  function submit(e) {
    e.preventDefault();
  }

  async function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    let configCreateExtraInfo = {
      method: "patch",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/questionSet`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        questionSetId: quizId,
        questions: orderList,
        edit: true,
      }),
    };
    await axios(configCreateExtraInfo);
  }

  const storedDataOnFirebase = async () => {
    let check = formCheckFunction();

    let nsn = questionName.replaceAll(" ", "");

    let dat = /\//i.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any (/) characters");
      return;
    }
    if (check != "alliswell") {
      setFormCheck("check");
      return;
    }

    setFormCheck("");
    let questionTypeEnum;
    let managequestionData;

    if (qustype == "6") {
      if (canEditRearrange) return; // didn't scramble before submit
      const myArray = Array.from(rearrangeOptions);
      let questionDatafromUser = {
        options: myArray,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 1;
    }
    const docData = {
      questionName: questionName,
      questionId: questionId,
      associatedQuiz: quizId,
      questionSet: quizId,
      data: managequestionData,
      questionType: qustype,
      created: Timestamp.now(),
    };
    //console.log(docData);

    let configCreateExtraInfo = {
      method: "post",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/question`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        questionId: questionId,
        questionName: questionName,
        questionType: qustype,
        created: new Date().toString(),
        questionData: managequestionData,
        questionPoolAssociated: quizId,
      }),
    };
    axios(configCreateExtraInfo).then((res) => {
      setfetchDataFromApi(fetchDataFromApi + 1);
      alert("data saved successfully");
    });

    const newList = list.concat({
      id: questionId,
      name: questionName,
      type: qustype,
    });
    setList(newList);

    document.getElementById(quizId + "question-form").reset();
    document.getElementById(quizId + "questionName").value = "";
    toggleQuestionForm();
  };

  const toggleQuestionForm = (e) => {
    resetData();
    document.getElementById(quizId + "questionForm").style.display =
      document.getElementById(quizId + "questionForm").style.display == "none"
        ? "block"
        : "none";
  };

  function resetData() {
    setQuestionName("");
    setQuestionId("");
    setqustype("6");
    setqus("");
    setqusInstruction("");
    setRearrangeOptions([
      {
        text: "First",
        position: 1,
      },
      {
        text: "Second",
        position: 2,
      },
      {
        text: "Third",
        position: 3,
      },
      {
        text: "Fourth",
        position: 4,
      },
    ]);
    setqus5({
      option1: "",
      option2: "",
      option3: "",
      option4: "",
    });
    setqus5Answer("Option 1");
    setLeftOptions(new Array(4).fill(""));
    setCanEditMatchPair(true);
    setRightOptions([
      {
        text: "",
        match: 1,
      },
      {
        text: "",
        match: 2,
      },
      {
        text: "",
        match: 3,
      },
      {
        text: "",
        match: 4,
      },
    ]);
    setImageOptions(new Array(4).fill("options"));
    setDisableImage(false);
    setImageFiles([
      {
        base64: "",
        match: 1,
        url: "",
      },
      {
        base64: "",
        match: 2,
        url: "",
      },
      {
        base64: "",
        match: 3,
        url: "",
      },
      {
        base64: "",
        match: 4,
        url: "",
      },
    ]);
    setImageMcq([
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
    ]);
    setPicMCQ({
      url: "",
      base64: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      answer: 0,
    });
  }

  return (
    <section className="quizForm-container">
      <form id={quizId + "question-form"} onSubmit={submit}>
        <div className="quizForm-mainForm-infoRow">
          <p className="quizForm-mainForm-label">Question Name :</p>
          <input
            type="text"
            className="quizForm-mainForm-inputBar"
            value={questionName}
            style={{
              border: `${
                checkErrorInForm.questionName == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            id={quizId + "questionName"}
            onChange={(e) => {
              setQuestionName(e.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, questionName: "" }));
              let value = e.target.value.replaceAll(" ", "");
              let time = new Date().getTime();
              // setQuestionId(value + "_" + time);
              if (!editQuestion) {
                setQuestionId(value + "_" + time);
              }
            }}
          />
          {checkErrorInForm.questionName == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Name
            </span>
          )}
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Question : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qus}
            style={{
              border: `${
                checkErrorInForm.qus == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqus(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qus: "" }));
            }}
          ></textarea>
          {checkErrorInForm.qus == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question
            </span>
          )}
        </div>
        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Instruction : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qusInstruction}
            style={{
              border: `${
                checkErrorInForm.qusInstruction == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqusInstruction(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "" }));
            }}
          ></textarea>
          {checkErrorInForm.qusInstruction == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Instruction
            </span>
          )}
        </div>

        <label className="quizForm-sequence-label">
          Options (Enter the options in sequence)
        </label>
        <div className="quizForm-sequence-options">
          {rearrangeOptions &&
            rearrangeOptions.map((item, id) => (
              <div key={id}>
                <input
                  className="quizForm-mainForm-inputBar"
                  style={{ marginBottom: "1em", textAlign: "center" }}
                  disabled={canEditRearrange ? false : true}
                  value={item.text}
                  onChange={(e) => handleRearrange(e, id + 1)}
                />

                <br />
              </div>
            ))}
          <button
            className="quizFrom-button"
            //  onClick={handleScramble}
            onClick={(e) => {
              e.preventDefault();
              handleScramble();
            }}
          >
            Scramble
          </button>
          <button
            className="quizFrom-button"
            //  onClick={handleScramble}
            onClick={(e) => {
              e.preventDefault();

              if (rearrangeOptions.length < 6) {
                handleRearrange(
                  { target: { value: "" } },
                  rearrangeOptions.length + 1
                );
              } else {
                alert("you can not add more then 6 options ");
              }
            }}
          >
            Add options
          </button>
        </div>
      </form>
      {formCheck && <div> Fill all the Required fields.</div>}
      <div className="quizForm-mainForm-infoRow">
        <button
          className="quizFrom-button"
          style={{ marginLeft: "auto" }}
          onClick={storedDataOnFirebase}
        >
          Submit
        </button>
      </div>
    </section>
  );
};

export default Choosethecorrectsequence;
