import { Button, Container, ThemeProvider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  confirmPasswordReset,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  verifyPasswordResetCode,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../../../Cookies";
import { db } from "../../../Firebase";
import loginImg from "../../../utilities/images/login-img.png";
import classes from "../../styles/LoginpageStyle/LoginPage.module.css";
import loginLogo from "../img/logo/logo.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const { createTheme } = require("@mui/material");

const loginTheme = createTheme({
  typography: {
    fontFamily: ["Nunito", "sans-serif"].join(","),
  },
});

function SetPassword() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const goToUserDashboard = () => navigate("/user/home");
  const goToAdminDashboard = () => navigate("/admin");
  const goToInstructorDashboard = () => navigate("/instructor");
  const [role, setRole] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [userNameError, setUserNameError] = useState("");

  ///=======visibility icon=======//
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const clearInputs = () => {
    setUserNameError("");
    setPassword("");
  };

  const clearErrors = () => {
    setUserNameError("");
    setPasswordError("");
  };

  //===============reset password===============//

  const setUserPassword = async() => {
    console.log("setUserPassword");
    clearErrors();
    const auth = getAuth();
    if (password !== password2) return;
    if (password.length < 8 || password2.length < 8) {
      setPasswordError("Password must be atleast 8 characters");
      return;
    }
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      let value = params.oobCode;
      // can make is so that if a continue url is given we can redirect to the continue url
      console.log(value);
      try {
        const email = await verifyPasswordResetCode(auth, value);
        console.log(email);
        await confirmPasswordReset(auth, value, password);
        goToHome();
      } catch (error) {
        console.log(error.code);
        // setError(error.code);
      }
  };

  const authListener = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        clearInputs();
        const uid = user.uid;
        // console.log(uid);
        setUser(user);
      } else {
        setUser("");
      }
    });
  };

  useEffect(() => {
    authListener();
  }, []);

  return (
    <ThemeProvider theme={loginTheme}>
      <Box>
        <Container
          style={{ height: "100vh" }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "50%",
              border: "3px solid #007848",
              borderRadius: "10px",
              minHeight: "650px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img className={classes.logo} src={loginLogo} alt="" />
            </Box>
            <Box sx={{ p: 7 }}>
              <Box>
                <Typography
                  variant="h3"
                  sx={{ color: "#007848", fontWeight: 700 }}
                >
                  Set a password
                </Typography>
                <Typography sx={{ my: 2, color: "#007848" }}>
                  Hi, Please set up a password for your account.
                </Typography>
              </Box>
              <form action="">
                <Box>
                  <Typography
                    sx={{ fontWeight: "700", mb: 1, color: "#007848" }}
                  >
                    New Password
                  </Typography>
                  {/* first password */}
                  <div
                    style={{
                      width: "100%",
                      borderRadius: "6px",
                      padding: "10px 15px",
                      border: "1px solid #007848",
                      outline: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type={showPassword1 ? "text" : "password"}
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      style={{ border: "none", outline: "none", width: "90%" }}
                    />
                    <div onClick={() => setShowPassword1(!showPassword1)}>
                      {showPassword1 ? (
                        <VisibilityIcon sx={{ color: "#007848" }} />
                      ) : (
                        <VisibilityOffIcon sx={{ color: "#007848" }} />
                      )}
                    </div>
                  </div>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Typography
                    sx={{ fontWeight: "700", mb: 1, color: "#007848" }}
                  >
                    Connfirm New Password
                  </Typography>
                  {/* confirm password */}
                  <div
                    style={{
                      width: "100%",
                      borderRadius: "6px",
                      padding: "10px 15px",
                      border: "1px solid #007848",
                      outline: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type={showPassword2 ? "text" : "password"}
                      placeholder="Password"
                      required
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                      style={{ border: "none", outline: "none", width: "90%" }}
                    />
                    <div onClick={() => setShowPassword2(!showPassword2)}>
                      {showPassword2 ? (
                        <VisibilityIcon sx={{ color: "#007848" }} />
                      ) : (
                        <VisibilityOffIcon sx={{ color: "#007848" }} />
                      )}
                    </div>
                  </div>
                </Box>
                <br />
                {/* submit button */}
                <Box sx={{ mt: 3 }}>
                  <Button
                    onClick={setUserPassword}
                    style={{
                      background: "linear-gradient(to right, #007848, #004577)",
                      color: "#fff",
                    }}
                    sx={{
                      textTransform: "none",
                      width: "100%",
                      py: 1,
                      borderRadius: "15px",
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    Continue
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>

          <Box sx={{ width: "50%" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={loginImg}
              alt=""
            />
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default SetPassword;
