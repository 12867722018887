import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
//import axios from 'axios';
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../../Firebase";

import { t } from "i18next";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import "./category.css";

import { EditSvg, DeleteSVg } from "../Builder/icons";
import Sidebar from "../../Component/Sidebar";
import Navbar from "../../Component/NavbarTop";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";

function CategoryListAndAdd() {
  const navigate = useNavigate();
  const { clrs } = useSelector((state) => state.createClr);
  const [template, settemplate] = useState([]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);
  const [courseName, setCourseName] = useState("");
  const [base64Img, setBase64Img] = useState("");
  const [maxLengthForInputBox, setMaxLengthForInputBox] = useState(20);
  const [courseId, setCourseId] = useState("");
  const [editDataOfCourse, seteditDataOfCourse] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [categoryEnum, setcategoryEnum] = useState("");
  const [checkErrorInForm, setCheckErrorInForm] = useState({
    courseName: "",

    imageUrl: "",
  });
  const imageRef = useRef(null);
  const onImageClick = () => {
    imageRef.current.click();
  };

  const deleteTemplate = httpsCallable(functions, "deleteTemplate");

  const deletetemplate = (id) => {
    //console.log(id);
    let configProfile = {
      method: "delete",
      // params: { userId: uid },
      url: `${IP}/catagories/category`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        categoryId: id,
      }),
    };

    axios(configProfile).then((res) => {
      // console.log(res);
      alert("deleted successfully");
      setdeltemp(!deltemp);
    });
  };

  const fetchdata = () => {
    setLoading(true);
    let configProfile = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/catagories/getCategoriesWithAllData`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };

    axios(configProfile)
      .then((res) => {
        setLoading(false);
        // console.log("cerificate fetch:", res);
        settemplate(res.data);
      })
      .catch((err) => {
        setLoading(false);
        //   console.log(err);
      });
  };

  useEffect(() => {
    fetchdata();
  }, [deltemp]);

  const handleCourseImage = (e) => {
    let reader = new FileReader();
    //console.log(e);

    let filenameType = e.target.value.split(".");

    //console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (
      filetypedata == "png" ||
      filetypedata == "jpeg" ||
      filetypedata == "jpg"
    ) {
    } else {
      alert("only jpeg  , gif  ,png  file accepted");
      e.target.value = null;

      return;
    }

    let rfilesize = Number(e.target.files[0].size) / 1024;

    rfilesize = rfilesize / 1024;
    //console.log(rfilesize);
    if (Number(rfilesize) > 5) {
      alert("size must be less than 5 mb");
      //   setImgFileName("");
      e.target.value = null;
      return;
    } else {
      //console.log(rfilesize);
      let url = URL.createObjectURL(e.target.files[0]);
      setImageUrl(url);
      //   setImgFileName(e.target.files[0].name);
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        //console.log(reader.result);
        setBase64Img(reader.result);
      };
    }
  };

  const reset = () => {
    setCourseId("");
    setCourseName("");
    setImageUrl("");
    setcategoryEnum("");
    seteditDataOfCourse("");
  };
  const saveData = () => {
    if (courseName == "" || imageUrl == "") {
      alert("all name and img are mandatory");
      return;
    }
    let configProfile = {
      method: "post",
      // params: { userId: uid },
      url: `${IP}/catagories/category`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        categoryName: courseName,
        categoryImg: base64Img ? base64Img : imageUrl,
        categoryEnum: categoryEnum,
        categoryId: courseId,
      }),
    };

    axios(configProfile).then((ress) => {
      setdeltemp(!deltemp);
      reset();
    });
  };
  return (
    <>
      <section className="dashboard">
        {/* <NavbarTop page={t("Certificate Settings")} /> */}
        {/* <Navbar /> */}
        <Navbar page={t("Category")} />

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"LMSCourseBuilder"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              <div className="course-builder-top">
                <div className="course-builder-info">
                  <label
                    htmlFor="courseName"
                    className="course-builder-inputLable"
                  >
                    Category Name
                  </label>
                  <input
                    type="text"
                    className="course-builder-inputBar"
                    value={courseName}
                    maxLength={maxLengthForInputBox}
                    id="courseName"
                    onChange={(e) => {
                      setCourseName(e.target.value);
                      let value = e.target.value.replaceAll(" ", "");
                      let time = new Date().getTime();
                      if (!editDataOfCourse) {
                        setCourseId(value + "_" + time);
                      }
                    }}
                  />
                </div>
                <div className="course-builder-info">
                  <p className="course-builder-inputLable">Featured Image *</p>
                  {imageUrl == "" ? (
                    <div
                      style={{
                        width: "20vw",
                        height: "8em",
                        background: "#d5d5d5",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        borderRadius: "10px",
                      }}
                      onClick={onImageClick}
                    >
                      Click to upload Image
                    </div>
                  ) : (
                    <img
                      style={{
                        width: "20vw",
                        height: "8em",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                      onClick={onImageClick}
                      src={imageUrl}
                    ></img>
                  )}
                  <input
                    ref={imageRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    className="course-builder-inputImg"
                    id="courseImage"
                    onChange={(e) => {
                      handleCourseImage(e);
                      setCheckErrorInForm((prev) => ({
                        ...prev,
                        imageUrl: "",
                      }));
                    }}
                  />
                  {checkErrorInForm.imageUrl == "error" && (
                    <span
                      style={{
                        marginLeft: "20px",
                        color: "red",
                        fontSize: "15px",
                      }}
                    >
                      {" "}
                      upload Image
                    </span>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "40px",
                  }}
                >
                  <button
                    id="addC"
                    className="course-builder-button"
                    onClick={saveData}
                  >
                    Save Category
                  </button>
                  <button
                    id="addC"
                    className="course-builder-button"
                    onClick={() => {
                      setCourseId("");
                      setCourseName("");
                      setImageUrl("");
                      setcategoryEnum("");
                      seteditDataOfCourse("");
                    }}
                  >
                    Cancel
                  </button>{" "}
                </div>
              </div>
              <div className="setting-table-main">
                <div
                  className="bigger-container"
                  style={{ textAlign: "center" }}
                >
                  <div style={{ width: "40%" }}> Available category</div>

                  <div style={{ width: "40%" }}>Action</div>
                  {/* <div style={{ width: "20%" }}>Make Default</div> */}
                </div>
                <div className="">
                  {showLoading && (
                    <div
                      className="admin-overdue-bottom-table-cnt-certi"
                      key={0}
                    >
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td">
                        <div className="spinner-container">
                          <div className="loading-spinner"></div>
                        </div>
                      </div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div className="admin-overdue-bottom-table-td"></div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{ color: "#004577" }}
                      ></div>
                    </div>
                  )}

                  {template?.map((ele) => {
                    return (
                      <div
                        className="outer-inner-container"
                        style={{ textAlign: "center" }}
                      >
                        <div className="templatename" style={{ width: "40%" }}>
                          {ele.categoryName}
                        </div>

                        <div
                          className="button-box"
                          style={{
                            width: "40%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "20px",
                          }}
                        >
                          <div className="button-box-inner mx-3 my-1">
                            <button
                              className="action-btn"
                              style={{
                                background: "red",
                                width: "100px",
                                height: "40px",
                                border: "none",
                                borderRadius: "10px",
                                color: "white",
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                deletetemplate(ele.categoryId);
                              }}
                            >
                              {/* <img src={} alt="" /> */}
                              Delete
                            </button>
                          </div>
                          <div className="button-box-inner mx-3 my-1">
                            <button
                              className="action-btn"
                              style={{
                                background: "green",
                                width: "100px",
                                height: "40px",
                                border: "none",
                                borderRadius: "10px",
                                color: "white",
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                setCourseId(ele.categoryId);
                                setCourseName(ele.categoryName);
                                setImageUrl(ele.categoryImg);
                                setcategoryEnum(ele.categoryEnum);
                                seteditDataOfCourse(true);
                              }}
                            >
                              <img src={EditSvg} />
                              Edit
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  marginTop: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  className="course-builder-button"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CategoryListAndAdd;
