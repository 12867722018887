import axios from "axios";
import { IP } from "./config";

const setCookie = (name, value, date) => {
  const dev = process.env.NODE_ENV === "development";
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 30);
  const IP = dev ? "http://192.168.1.199:3000" : "https://api.sereininc.live";
  document.cookie = dev
    ? name +
      "=" +
      (value || "") +
      "; path=/" +
      `;expires=${date ? date : expirationDate}`
    : name + "=" + (value || "") + "; domain=.sereininc.live" + "; path=/";

  // cookies.set(name, value, {
  //   path: "/",
  //   expires: expirationDate,
  //   domain: dev ? "" : ".sereininc.live",
  // });
  // console.log("line 22", { name: value });

  if (name != "rfToken") {
    // console.log("line 24", { name: value });

    localStorage.setItem(name, value);
  }
};
const getCookie = (name) => {
  // return cookies.get(name);
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) {
      let val = c.substring(nameEQ.length, c.length);
      // console.log("line 37", { name: val });

      if (name != "rfToken") {
        if (val) {
          // console.log("line 39", { name: val });
          localStorage.setItem(name, val);
        }
      }
      // console.log(localStorage.getItem(name));
      return localStorage.getItem(name);
    }
  }

  return localStorage.getItem(name);
};

const getLocalStorage = (name) => {
  return localStorage.getItem(name);
};

const deleteCookie = (name) => {
  deleteCookiesInServer();
  // console.log("deleteCookie");

  // //console.log("Cookies deleted");
};

const getCookieFromServer = (name) => {
  // return cookies.get(name);
  // console.log("getCookieFromServer", name);

  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) {
      let val = c.substring(nameEQ.length, c.length);
      if (name == "rfToken") {
        // console.log(val);
        return val;
        localStorage.setItem(name, val);
      }
      // console.log(localStorage.getItem(name));
      // return localStorage.getItem(name);
    }
  }
  // console.log(localStorage.getItem(name));
  return localStorage.getItem(name);
};

const deleteCookiesInServer = () => {
  let data = JSON.stringify({
    test: "ts",
  });

  var config = {
    method: "post",

    url: `${IP}/auth/logout`,
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${getCookie("bearer")}`,
    },
    data: data,
  };
  axios(config)
    .then((res) => {
      // console.log(res.data);
      console.log(window.location.origin);
      window.open(`${window.location.origin}`, "_self");
      // window.location.reload();
    })
    .catch((err) => {
      window.open(`${window.location.origin}`, "_self");
    });
};

export {
  setCookie,
  getCookie,
  deleteCookie,
  getLocalStorage,
  deleteCookiesInServer,
  getCookieFromServer,
};

// const setCookie = (name,value)=>{
//     document.cookie = name + "=" + (value || "")  + "; path=/";
// }
// const getCookie= (name)=>{
//     var nameEQ = name + "=";
//     var ca = document.cookie.split(';');
//     for(var i=0;i < ca.length;i++) {
//         var c = ca[i];
//         while (c.charAt(0)==' ') c = c.substring(1,c.length);
//         if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
//     }
//     return null;
// }

// export {setCookie,getCookie};
