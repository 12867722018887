import React, { useEffect } from "react";
import { useState } from "react";
import CreateTopic from "./CreateTopic";
import $, { hasData, timers } from "jquery";
import { Timestamp } from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import Item from "./Item";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import "./styles/item.css";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";
import DeleteLesson from "./lessonDeletePopUp";
const CreateStage = ({ courseId, unitId, levelId, editData }) => {
  const storage = getStorage();
  //Topic
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [deleteQuestionId, setDeleteQuestionId] = useState("");

  const [stageName, setStageName] = useState("");
  const [stageDescription, setStageDescription] = useState("");
  const [daysAfterTopic, setDaysAfterTopic] = useState(0);
  const [startDateTopic, setStartDateTopic] = useState("");
  const [stageId, setStageId] = useState("");
  const [editable, setEditable] = useState(false);
  const [checkFailed, setCheckedFailed] = useState(false);
  const [list, setList] = useState([]);
  const [isDraft, setIsDraft] = useState([]);
  const [hasQuiz, setHasQuiz] = useState("yes");
  const [addcompletionText, setAddcompletionText] = useState("");
  const [adddate, setAdddate] = useState("");
  const [editstage, setEditSatge] = useState(false);
  const [quizstageList, setQuizstageList] = useState([]);
  const [tt, setTt] = useState(true);

  useEffect(() => {
    let configProfile = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/courseContent/level/extraInfo/${levelId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };

    axios(configProfile)
      .then((res) => {
        (async () => {
          for (let i = 0; i < res?.data?.levelStages.length; i++) {
            let configProfile = {
              method: "get",
              // params: { userId: uid },
              url: `${IP}/courseContent/stages/${res.data.levelStages[i]}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
            };

            let stagedata = await axios(configProfile);

            // let itemData = await getStageItemData({
            //   stageId: res.data.levelStages[i],
            // });

            let configItemData = {
              method: "get",
              // params: { userId: uid },
              url: `${IP}/courseContent/item/stage/${res?.data?.levelStages[i]}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
            };

            let itemData = await axios(configItemData);
            console.log("itemData", itemData.data);
            let quizarr = [];

            let quizz = stagedata.data.stageQuizzes;
            console.log("rahul", quizz);
            if (quizz?.length > 0) {
              for (let i = 0; i < quizz.length; i++) {
                let configQuiz = {
                  method: "get",
                  // params: { userId: uid },
                  url: `${IP}/courseContent/quiz/${quizz[i]}`,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getCookie("bearer")}`,
                  },
                };

                let levelquizdata = await axios(configQuiz);
                console.log(levelquizdata.data);

                // let fulldate = new Date(
                //   levelquizdata.data.scheduledDateOfRelease._seconds * 1000
                // );

                quizarr.push({
                  id: levelquizdata.data.quizId,
                  name: levelquizdata.data.quizName,
                  passScore: levelquizdata.data.passingScore,

                  timeLimit: levelquizdata.data.timeLimit,
                  success: levelquizdata.data.completionText,
                  // scheduledDateOfRelease: fulldate,
                });
              }
            }

            let fulldate = new Date(
              stagedata?.data?.scheduledDateOfRelease?._seconds * 1000
            );

            console.log(fulldate);
            let dateString = fulldate.toISOString().split("T")[0];

            setList((prev) =>
              prev.concat({
                id: stagedata.data.stageId,
                name: stagedata.data.stageName,
                description: stagedata.data.stageDescription,
                hasQuiz: stagedata.data.hasQuiz,
                completionText: stagedata.data.completionText,
                schedule: dateString,
                isDraft: stagedata.data.isDraft,
                quizarr: quizarr,
                itemData: itemData.data,
              })
            );
            console.log("stageItem", itemData.data);
            // alert("tse");
          }

          //console.log(res.data)
        })();
      })
      .catch((er) => {
        alert("something went wrong");
      });
  }, [editData]);

  function checkForm() {
    if (stageName == "") return "stageName";
    if (stageDescription == "") return "stageDescription";
    return "allIsWell";
  }

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    // console.log(orderList);

    let configQuiz = {
      method: "post",
      // params: { userId: uid },
      url: `${IP}/courseBuilder/addLevelStageOrder`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        levelId: levelId,
        levelStages: orderList,
        edit: true,
      }),
    };
    axios(configQuiz).then((res) => {});
    // addLevelStageOrder({

    // });
  }

  const addTopic = () => {
    let check = checkForm();
    if (check != "allIsWell") {
      setCheckedFailed(true);
      return;
    }

    let newDate = adddate;
    console.log(adddate);
    setCheckedFailed(false);

    // const newList = list.concat({
    //   id: stageId,
    //   name: stageName,
    //   description: stageDescription,
    //   completionText: addcompletionText,
    //   hasQuiz: hasQuiz,
    //   schedule: newDate,
    //   isDraft: isDraft,
    // });
    // console.log(newList);

    let arr = list.map((el) => {
      if (el.id == stageId) {
        return {
          id: stageId,
          name: stageName,
          description: stageDescription,
          completionText: addcompletionText,
          hasQuiz: hasQuiz,
          schedule: newDate,
          isDraft: isDraft,
        };
      } else {
        return el;
      }
    });

    let newList = arr;
    // console.log(editdataflag);
    if (!editstage) {
      newList = arr.concat({
        id: stageId,
        name: stageName,
        description: stageDescription,
        completionText: addcompletionText,
        hasQuiz: hasQuiz,
        schedule: newDate,
        isDraft: isDraft,
      });
    }

    console.log(newList);
    if (editstage) {
      let configQuiz = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/stage`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          stageId: stageId,
          stageDescription: stageDescription,
          associatedCourseId: courseId,
          associatedLevelId: levelId,
          associatedUnitId: unitId,
          stageName: stageName,

          isDraft: isDraft == "yes" ? true : false,
        }),
      };
      axios(configQuiz).then((res) => {
        let configStage = {
          method: "patch",
          // params: { userId: uid },
          url: `${IP}/courseBuilder/stage`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: JSON.stringify({
            stageId: stageId,
            hasQuiz: hasQuiz == "yes" ? true : false,
            completionText: addcompletionText,
            creationDate: new Date(),
            lastUpdateDate: new Date(),
            scheduledDateOfRelease: newDate,
          }),
        };
        axios(configStage);
      });

      toggle();
      setEditSatge(false);
    } else {
      // createStage({

      // });

      let configQuiz = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/stage`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          stageId: stageId,
          stageDescription: stageDescription,
          associatedCourseId: courseId,
          associatedLevelId: levelId,
          associatedUnitId: unitId,
          stageName: stageName,

          isDraft: isDraft == "yes" ? true : false,
        }),
      };
      axios(configQuiz);

      let configStage = {
        method: "patch",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/stage`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          stageId: stageId,
          hasQuiz: hasQuiz == "yes" ? true : false,
          completionText: addcompletionText,
          creationDate: new Date(),
          lastUpdateDate: new Date(),
          scheduledDateOfRelease: newDate,
        }),
      };
      axios(configStage);

      saveOrder(newList);

      alert("stage Added");
    }
    setList(newList);
    setStageName("");
    setStageDescription("");
    setDaysAfterTopic("");
    setStartDateTopic("");
  };

  const toggle = (e) => {
    document.getElementById(levelId + "topicForm").style.display =
      document.getElementById(levelId + "topicForm").style.display == "none"
        ? "block"
        : "none";
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }
  function edit(topic) {
    console.log(topic);
    setEditSatge(true);

    setEditable(true);
    document.getElementById(levelId + "topicForm").style.display = "";
    setStageName(topic.name);
    setStageId(topic.id);
    setStageDescription(topic.description);
    setIsDraft(topic.isDraft == true ? "yes" : "no");
    setAddcompletionText(topic.completionText);
    setHasQuiz("yes");

    setAdddate(topic.schedule);
  }

  async function del(topic, index) {
    setDeleteQuestionId({ id: topic });
    setopenLessonPopUp(true);
  }

  const delfunction = async (question) => {
    // db.collection("question").doc(question.id).delete();

    let configCreateExtraInfo = {
      method: "delete",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/stage`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        stageId: question.id,
      }),
    };
    await axios(configCreateExtraInfo);
    let newArr = list.filter((el) => el.id != question.id);

    setList(newArr);

    saveOrder(newArr);
    alert("Deleted ");
  };
  return (
    <>
      <br></br>
      {/* <Item courseId={courseId} levelId={levelId} /> */}
      <br></br>
      <DeleteLesson
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteQuestionId}
      />
      <section
        className="sectiondd"
        style={{ marginBlockStart: "90px", width: "100%" }}
      >
        <button
          className="addItem-btn"
          onClick={() => {
            setEditable(false);
            toggle();
          }}
        >
          Add Stage
        </button>
        <div
          className="addItem-container"
          id={levelId + "topicForm"}
          style={{ display: "none" }}
        >
          <div className="addItem-heading">Stage</div>
          <div className="addItem-row">
            <div className="addItem-inputLabel">stage Name</div>
            <input
              type="text"
              className="addItem-inputBar"
              value={stageName}
              id={levelId + "stageName"}
              onChange={(event) => {
                setStageName(event.target.value);
                if (!editable) {
                  let value = event.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();
                  setStageId(value + "_" + time);
                }
              }}
            />
          </div>

          <div className="addItem-row">
            <div className="addItem-inputLabel">Short Description</div>
            <textarea
              type="text"
              className="addItem-inputBar"
              value={stageDescription}
              id={levelId + "stageDescription"}
              onChange={(event) => {
                setStageDescription(event.target.value);
              }}
            />
          </div>

          <div className="addItem-row">
            <div className="addItem-inputLabel" style={{ marginTop: "2vw" }}>
              isDraft
            </div>
            <select
              className="addItem-inputSelect"
              id={levelId + "isDraft"}
              value={isDraft}
              onChange={(e) => {
                setIsDraft(e.target.value);
              }}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          {/* <div className="addItem-row">
            <div className="addItem-inputLabel">Quiz</div>
            <select
              className="addItem-inputSelect"
              id={levelId + "hasTopicQuiz"}
              value={hasQuiz}
              onChange={(e) => {
                setHasQuiz(e.target.value);
              }}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div> */}

          {/* <div className="addItem-row">
            <div className="addItem-inputLabel">completion Text</div>
            <textarea
              type="text"
              className="addItem-inputBar"
              value={addcompletionText}
              id={levelId + "completionText"}
              onChange={(event) => {
                setAddcompletionText(event.target.value);
              }}
            />
          </div> */}

          <div className="addItem-row">
            <div className="addItem-inputLabel">
              {" "}
              Scheduled Date Of Release{" "}
            </div>
            <input
              type="date"
              className="addItem-inputBar"
              value={adddate}
              id={levelId + "ScheduledDateOfRelease"}
              onChange={(event) => {
                setAdddate(event.target.value);
              }}
            />
          </div>

          <br></br>
          {checkFailed && (
            <p style={{ color: "red" }}>Fill all the necessary fields. </p>
          )}
          <div className="addItem-row">
            <button
              className="addItem-btn"
              style={{ marginLeft: "auto" }}
              onClick={addTopic}
            >
              Save Stage
            </button>
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                id="topicist"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  listStyleType: "none",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {list.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={item.id}
                        className="draggableTopic-li"
                      >
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreSharp />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              backgroundColor: "#557B83",
                              color: "white",
                              borderRadius: "5px",
                            }}
                          >
                            <Typography>{item.name}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div onClick={() => del(item.id, index)}>
                              <MdDelete
                                style={{ float: "right", margin: "20px" }}
                                size={28}
                                onClick={() => del(item.id)}
                              />
                            </div>

                            <MdEdit
                              style={{ float: "right", margin: "20px" }}
                              size={28}
                              onClick={() => edit(item)}
                            />
                            <p>{item.description}</p>
                            <p>Id- {item.id}</p>
                            <p>Associated level- {levelId}</p>

                            <br></br>

                            <br></br>
                            <Item
                              courseId={courseId}
                              stageId={item.id}
                              itemsArr={item.itemData}
                              editData={editData}
                            />
                            <br></br>
                            <CreateTopic
                              unitId={unitId}
                              courseId={courseId}
                              levelId={levelId}
                              stageId={item.id}
                              editData={editData}
                              quizstageList={item.quizarr}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>

      {/* <CreateTopic courseId={courseId} unitId={unitId} /> */}
    </>
  );
};

export default CreateStage;
