import React, { useState } from "react";
import firebase from "firebase/compat/app";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./Firebase";

function SignInWithGoogle() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    // auth.signInWithPopup(provider);
    firebase
      .auth()
      .signInWithRedirect(provider)
      .then((res) => {
        console.log(res);
        firebase
          .auth()
          .getRedirectResult()
          .then((result) => {
            // Handle the authentication result
            const user = result.user;
            if (user) {
              console.log("Signed in as: " + user.displayName);
            }
          })
          .catch((error) => {
            // Handle errors
            console.error("Authentication failed: " + error.message);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <button onClick={signInWithGoogle}>Sign in with google</button>
    </div>
  );
}

function SignInWithFacebook() {
  const signInWithFacebook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    auth.signInWithPopup(provider);
  };
  return (
    <div>
      <button onClick={signInWithFacebook}>Sign in with Facebook</button>
    </div>
  );
}

function SignInWithTwitter() {
  const SignInWithTwitter = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    auth.signInWithPopup(provider);
  };
  return (
    <div>
      <button onClick={SignInWithTwitter}>Sign in with Twitter</button>
    </div>
  );
}

function DashBoard() {
  const [user] = useAuthState(auth);
  console.log(user);
  // alert("tshs");
  // window.close();

  return user ? (
    <div style={{ border: "solid black" }}>
      User Dashboard <br></br>
      Name: {user.displayName} <br></br>
      Email: {user.email} <br></br>
      Photo: <img src={user.photoURL} alt="profile pic" /> <br></br>
    </div>
  ) : (
    <></>
  );
}

export default function GoogleAuth() {
  return (
    <div>
      <SignInWithGoogle />

      <DashBoard />
    </div>
  );
}
