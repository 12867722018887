import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCookie } from "../../Cookies";
// import notify from "./img/notify.svg";
// import settings from "./img/settings.svg";
// import { useTranslation } from "react-i18next";
import "../Translater/Translater";
import logo from "./img/logo.png";
import pp from "./img/profilePicture.svg";
import "./NavbarTop.css";
function Navbar({ page }) {
  // const [lagn, setLang] = useState("en");
  // const { i18n } = useTranslation();
  // NavbarTxtClr
  const { clrs } = useSelector((state) => state.createClr);

  return (
    <div className="nav">
      <div className="nav-cnt">
        <div className="nav-cnt-left">
          <img
            className="nav-cnt-left-img"
            style={{ width: "12vw" }}
            src={logo}
            alt="loading"
          />
        </div>
        <div className="nav-cnt-center">
          <div
            style={{
              color: `${clrs?.NavbarTxtClr ? clrs?.NavbarTxtClr : "black"}`,
            }}
          >
            {page}
          </div>
        </div>
        <div className="nav-cnt-right">
          <div className="nav-cnt-right-profile">
            <div className="nav-cnt-right-profile-img">
              <img style={{ width: "3.5vw" }} src={pp} alt="loading" />
            </div>
            <div className="nav-cnt-right-profile-right">
              <div className="nav-cnt-right-profile-right-name">
                {getCookie("NAME")}
              </div>
              <div className="nav-cnt-right-profile-right-info">
                {getCookie("EMPID")}
              </div>
            </div>
          </div>
          {/* <div className="nav-cnt-right-notify">
            <svg
              width="auto"
              height="36"
              viewBox="0 0 35 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.9274 15.9708C25.2173 15.2719 25.3667 14.5228 25.367 13.7662C25.3685 13.0083 25.2202 12.2575 24.9307 11.5571C24.6413 10.8566 24.2163 10.2202 23.6802 9.68441C23.1441 9.14863 22.5074 8.72403 21.8068 8.43497C21.1061 8.14591 20.3553 7.9981 19.5973 8.00002C16.4256 8.00208 13.8462 10.585 13.8443 13.7608C13.844 14.5174 13.9926 15.2667 14.2818 15.9658C14.5711 16.6649 14.9951 17.3003 15.5299 17.8355C16.0646 18.3707 16.6995 18.7954 17.3984 19.0853C18.0973 19.3751 18.8464 19.5245 19.603 19.5249C20.3596 19.5252 21.1088 19.3766 21.8079 19.0873C22.5071 18.7981 23.1424 18.3741 23.6776 17.8393C24.2129 17.3046 24.6376 16.6697 24.9274 15.9708ZM19.6179 21.852C19.6701 22.8787 19.8832 23.8561 20.3027 24.7848C20.7372 25.7684 21.3824 26.6446 22.1928 27.3513C22.6054 27.7087 22.9353 28.1114 23.03 28.6652C23.2269 29.8158 22.4711 30.8471 21.3041 31.02C21.135 31.0457 20.9643 31.059 20.7933 31.0599C14.6249 31.0618 8.4564 31.0596 2.28792 31.0531C1.92889 31.0539 1.57303 30.9859 1.2396 30.8527C-0.138411 30.2795 -0.430694 28.542 0.683034 27.5458C1.6099 26.7167 2.35956 25.7681 2.8479 24.6198C3.24156 23.7085 3.45078 22.7282 3.46348 21.7356C3.46957 21.1617 3.46839 20.5877 3.46721 20.0137C3.46603 19.444 3.46486 18.8743 3.47079 18.3047C3.47898 17.1877 3.71946 16.0846 4.17695 15.0654C4.63445 14.0463 5.29894 13.1335 6.12821 12.3851C6.95748 11.6366 7.93339 11.0689 8.99391 10.7179C10.0544 10.3669 11.1764 10.2404 12.2884 10.3464C11.1536 13.032 11.2473 15.6398 12.819 18.0947C14.3858 20.541 16.6996 21.7332 19.6179 21.852ZM11.0875 35.6684C10.9178 35.6122 10.7446 35.5627 10.5714 35.5132C10.1907 35.4043 9.80991 35.2955 9.46606 35.1161C8.30236 34.5084 7.60686 33.5123 7.30321 32.2193H15.8047C15.7578 32.3415 15.7155 32.4625 15.674 32.5815C15.5859 32.8339 15.5011 33.077 15.3823 33.3022C14.6953 34.6054 13.6168 35.3822 12.1575 35.621C12.1059 35.6331 12.0552 35.649 12.0059 35.6685L11.0875 35.6684Z"
                fill="#D7D7D7"
              />
              <circle
                cx="23.5"
                cy="11.5"
                r="10.5"
                fill="#FFAA2B"
                stroke="white"
                stroke-width="2"
              />
              <path
                d="M17.8 15V14.05L20.08 11.62C20.3867 11.2867 20.6067 10.9933 20.74 10.74C20.8733 10.48 20.94 10.2167 20.94 9.95C20.94 9.27 20.53 8.93 19.71 8.93C19.07 8.93 18.48 9.17 17.94 9.65L17.51 8.69C17.79 8.43667 18.14 8.23333 18.56 8.08C18.98 7.92667 19.4167 7.85 19.87 7.85C20.6233 7.85 21.2 8.02 21.6 8.36C22.0067 8.7 22.21 9.18667 22.21 9.82C22.21 10.2467 22.1067 10.6567 21.9 11.05C21.7 11.4433 21.3833 11.8667 20.95 12.32L19.4 13.94H22.48V15H17.8ZM26.4861 15V13.66H23.4161V12.72L26.7061 7.95H27.7661V12.6H28.7561V13.66H27.7661V15H26.4861ZM26.4861 12.6V9.91L24.6461 12.6H26.4861Z"
                fill="#FBFBFB"
              />
            </svg>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
