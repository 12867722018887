import React, { useEffect, useState } from "react";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadBytes,
  deleteObject,
} from "@firebase/storage";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import "./createQuiz.css";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import { ExpandMoreSvg } from "./icons";
import DeleteLesson from "./lessonDeletePopUp";
import CreateMcqQuestion from "./mcqQuestion";
import { IP } from "../../../config";
import { getCookie } from "../../../Cookies";
import axios from "axios";
const Matchthepair = ({
  quizId,
  questionData,
  editQuestion,
  setfetchDataFromApi,
  fetchDataFromApi,
  flagForSeconstEdit,
}) => {
  const storage = getStorage();

  const createQuestion = httpsCallable(functions, "createQuestion");
  const addQuestionSetOrder = httpsCallable(functions, "addQuestionSetOrder");
  //addQuestionSetOrder
  // added by Himanshu
  const [deleteQuestionId, setDeleteQuestionId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);

  const [questionName, setQuestionName] = useState("");
  const [questionId, setQuestionId] = useState("");

  const initialList = [];
  const [list, setList] = useState(initialList);

  const [picMCQ, setPicMCQ] = useState({
    url: "",
    base64: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: 0,
  });
  const [trueFalse, setTrueFalse] = useState(false);

  function onPicMCQChange(e) {
    const { value, name, files } = e.target;
    setPicMCQ((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  function setPicMCQFile(e) {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result.length > 1000000) {
        alert("Image Too large, compress and try again");
        e.target.value = "";
        return;
      }
      setPicMCQ((prev) => ({ ...prev, url: url, base64: reader.result }));
      //console.log("setBase64Image done", reader.result);
    };
    reader.readAsDataURL(files[0]);

    // setPicMCQ((prev) => ({ ...prev,  }));
  }

  function uploadPicMCQ(file) {
    if (!file) return;
    let time = JSON.stringify(new Date().getTime());
    const storageref = ref(storage, `/files/${file.name + "_" + time}`);
    const uploadTask = uploadBytesResumable(storageref, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // can set progress.
      },
      (
          err //console.log(err),
        ) =>
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) =>
            setPicMCQ((prev) => ({ ...prev, url: url }))
          );
        }
    );
  }

  const [fillUpsAns, setFillUpsAns] = useState("");
  // rearrange
  const [canEditRearrange, setCanEditRearrange] = useState(true);
  const [rearrangeOptions, setRearrangeOptions] = useState([
    {
      text: "First",
      position: 1,
    },
    {
      text: "Second",
      position: 2,
    },
    {
      text: "Third",
      position: 3,
    },
    {
      text: "Fourth",
      position: 4,
    },
  ]);
  /* arr element example
    {
      text:"rearrange",
      position: 1
    }
  */

  const scrambleArr = (arr) => {
    //console.log(arr[0]);
    let res = Array.from(arr);
    return res.sort((a, b) => 0.5 - Math.random());
  };

  const [canEditMatchPair, setCanEditMatchPair] = useState(true);
  const [leftOptions, setLeftOptions] = useState(new Array(4).fill(""));
  const [rightOptions, setRightOptions] = useState([
    {
      text: "",
      match: 1,
    },
    {
      text: "",
      match: 2,
    },
    {
      text: "",
      match: 3,
    },
    {
      text: "",
      match: 4,
    },
  ]);

  const handleMatchPair = (e, order, side) => {
    //console.log("onChange");
    if (!canEditMatchPair) return;
    if (side == "left") {
      setLeftOptions((prev) => {
        let item = e.target.value;

        let res = Array.from(prev);
        res[order - 1] = item;
        return res;
      });
    } else {
      setRightOptions((prev) => {
        let item = {
          text: e.target.value,
          match: order,
        };
        let res = Array.from(prev);
        res[order - 1] = item;
        return res;
      });
    }
  };

  const handleMatchUpScramble = () => {
    setCanEditMatchPair(false);
    setRightOptions((prev) => {
      let res = scrambleArr(prev);
      //console.log(res);
      return res;
    });
  };

  // match words to image

  const [imageOptions, setImageOptions] = useState(
    new Array(4).fill("options")
  );
  const [imageFiles, setImageFiles] = useState([
    {
      base64: "",
      match: 1,
      url: "",
    },
    {
      base64: "",
      match: 2,
      url: "",
    },
    {
      base64: "",
      match: 3,
      url: "",
    },
    {
      base64: "",
      match: 4,
      url: "",
    },
  ]);
  const [disableImage, setDisableImage] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [imageMcq, setImageMcq] = useState([
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
  ]);
  const [imageMcqAnswer, setImageMcqAnswer] = useState(0);

  //

  useEffect(() => {
    if (!editQuestion) return;
    // console.log(questionData)
    setqus(questionData.data?.question);
    setQuestionName(questionData?.name);
    setQuestionId(questionData?.id);
    setqusInstruction(questionData.data?.questionInstruction);

    if (questionData.data.LHS) {
      setLeftOptions(questionData.data.LHS);
    }
    if (questionData.data.RHS) {
      let arr = [];
      for (let i = 0; i < questionData.data.RHS.length; i++) {
        if (questionData.data.RHS[i].match == 1) {
          arr[0] = { text: questionData.data.RHS[i].text, match: 1 };
        }
        if (questionData.data.RHS[i].match == 2) {
          arr[1] = { text: questionData.data.RHS[i].text, match: 2 };
        }
        if (questionData.data.RHS[i].match == 3) {
          arr[2] = { text: questionData.data.RHS[i].text, match: 3 };
        }
        if (questionData.data.RHS[i].match == 4) {
          arr[3] = { text: questionData.data.RHS[i].text, match: 4 };
        }

        if (i == 3) {
          setRightOptions(arr);
        }
      }
    }
    setCanEditMatchPair(true);
  }, [flagForSeconstEdit]);

  const [checkErrorInForm, setCheckErrorInForm] = useState({
    questionName: "",
    qus: "",
    qusInstruction: "",

    leftOptions: ["", "", "", ""],
    rightOptions: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }],
  });
  const formCheckFunction = () => {
    console.log("ggjgjj");
    if (questionName === "") {
      console.log("ggjgjj");
      setCheckErrorInForm((prev) => ({ ...prev, questionName: "error" }));
      return "question Name";
    }

    if (qus === "") {
      setCheckErrorInForm((prev) => ({ ...prev, qus: "error" }));
      return "question ";
    }
    if (qusInstruction === "") {
      setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "error" }));
      return "questionc ";
    }
    if (leftOptions[0] === "") {
      let op = ["error", "", "", ""];
      setCheckErrorInForm((prev) => ({ ...prev, leftOptions: op }));
      return "question ";
    }
    if (leftOptions[1] === "") {
      let op = ["", "error", "", ""];
      setCheckErrorInForm((prev) => ({ ...prev, leftOptions: op }));
      return "question ";
    }
    if (leftOptions[2] === "") {
      let op = ["", "", "error", ""];
      setCheckErrorInForm((prev) => ({ ...prev, leftOptions: op }));
      return "question ";
    }
    if (leftOptions[3] === "") {
      let op = ["", "", "", "error"];
      setCheckErrorInForm((prev) => ({ ...prev, leftOptions: op }));
      return "question ";
    }
    if (rightOptions[0].text === "") {
      let op = [{ text: "error" }, { text: "" }, { text: "" }, { text: "" }];
      setCheckErrorInForm((prev) => ({ ...prev, rightOptions: op }));
      return "question ";
    }
    if (rightOptions[1].text === "") {
      let op = [{ text: "" }, { text: "error" }, { text: "" }, { text: "" }];
      setCheckErrorInForm((prev) => ({ ...prev, rightOptions: op }));
      return "question ";
    }
    if (rightOptions[2].text === "") {
      let op = [{ text: "" }, { text: "" }, { text: "error" }, { text: "" }];
      setCheckErrorInForm((prev) => ({ ...prev, rightOptions: op }));
      return "question ";
    }
    if (rightOptions[3].text === "") {
      let op = [{ text: "" }, { text: "" }, { text: "" }, { text: "error" }];
      setCheckErrorInForm((prev) => ({ ...prev, rightOptions: op }));
      return "question ";
    }
    return "alliswell";
  };

  const [qusMode, setqusMode] = useState("");
  const [qustype, setqustype] = useState("3");
  const [qus, setqus] = useState("");
  const [qusInstruction, setqusInstruction] = useState("");
  const [PointorScore, setPointorScore] = useState("");
  const [qus5, setqus5] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  const [qus5Answer, setqus5Answer] = useState("Option 1");
  const [qus2, setqus2] = useState({ word: "", correctImage: "" });
  const [formCheck, setFormCheck] = useState("");
  function submit(e) {
    e.preventDefault();
  }

  async function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }

    let configCreateExtraInfo = {
      method: "patch",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/questionSet`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        questionSetId: quizId,
        questions: orderList,
        edit: true,
      }),
    };
    await axios(configCreateExtraInfo);

    // db.collection("quiz")
    //   .doc(quizId)
    //   .collection("extraInfo")
    //   .doc("infoDoc")
    //   .update({
    //     questions: orderList,
    //   });
    // db.collection("questionSet")
    //   .doc(quizId)
    //   .collection("extraInfo")
    //   .doc("infoDoc")
    //   .update({
    //     questions: orderList,
    //   });
  }

  const storedDataOnFirebase = async () => {
    let check = formCheckFunction();

    let nsn = questionName.replaceAll(" ", "");

    let dat = /\//i.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any (/) characters");
      return;
    }
    if (check != "alliswell") {
      setFormCheck("check");
      return;
    }

    setFormCheck("");
    let questionTypeEnum;
    let managequestionData;

    if (qustype == "3") {
      if (canEditMatchPair) return;

      let questionDatafromUser = {
        LHS: leftOptions,
        RHS: rightOptions,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 7;
    }
    // const docData = {
    //   questionName: questionName,
    //   questionId: questionId,
    //   associatedQuiz: quizId,
    //   questionSet: quizId,
    //   data: managequestionData,
    //   questionType: qustype,
    //   created: Timestamp.now(),
    // };
    console.log(managequestionData);

    let configCreateExtraInfo = {
      method: "post",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/question`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        // questionId: questionId,
        // questionName: questionName,
        // questionType: qustype,
        // created: new Date().toString(),
        // data: managequestionData,
        // quizId: quizId,

        questionId: questionId,
        questionName: questionName,
        questionType: qustype,
        created: new Date(),
        questionData: managequestionData,
        questionPoolAssociated: quizId,
      }),
    };
    axios(configCreateExtraInfo).then((res) => {
      setfetchDataFromApi(fetchDataFromApi + 1);
      alert("data saved successfully");
    });

    const newList = list.concat({
      id: questionId,
      name: questionName,
      type: qustype,
    });
    setList(newList);
    // saveOrder(newList);
    document.getElementById(quizId + "question-form").reset();
    document.getElementById(quizId + "questionName").value = "";
    toggleQuestionForm();
  };

  const toggleQuestionForm = (e) => {
    resetData();
    document.getElementById(quizId + "questionForm").style.display =
      document.getElementById(quizId + "questionForm").style.display == "none"
        ? "block"
        : "none";
  };

  function resetData() {
    setQuestionName("");
    setQuestionId("");
    setqustype("3");
    setqus("");
    setqusInstruction("");
    setRearrangeOptions([
      {
        text: "First",
        position: 1,
      },
      {
        text: "Second",
        position: 2,
      },
      {
        text: "Third",
        position: 3,
      },
      {
        text: "Fourth",
        position: 4,
      },
    ]);
    setqus5({
      option1: "",
      option2: "",
      option3: "",
      option4: "",
    });
    setqus5Answer("Option 1");
    setLeftOptions(new Array(4).fill(""));
    setCanEditMatchPair(true);
    setRightOptions([
      {
        text: "",
        match: 1,
      },
      {
        text: "",
        match: 2,
      },
      {
        text: "",
        match: 3,
      },
      {
        text: "",
        match: 4,
      },
    ]);
    setImageOptions(new Array(4).fill("options"));
    setDisableImage(false);
    setImageFiles([
      {
        base64: "",
        match: 1,
        url: "",
      },
      {
        base64: "",
        match: 2,
        url: "",
      },
      {
        base64: "",
        match: 3,
        url: "",
      },
      {
        base64: "",
        match: 4,
        url: "",
      },
    ]);
    setImageMcq([
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
    ]);
    setPicMCQ({
      url: "",
      base64: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      answer: 0,
    });
  }
  async function del(question) {
    setopenLessonPopUp(true);
    setDeleteQuestionId(question);
  }

  return (
    <section className="quizForm-container">
      <form id={quizId + "question-form"} onSubmit={submit}>
        <div className="quizForm-mainForm-infoRow">
          <p className="quizForm-mainForm-label">Question Name :</p>
          <input
            type="text"
            className="quizForm-mainForm-inputBar"
            id={quizId + "questionName"}
            value={questionName}
            style={{
              border: `${
                checkErrorInForm.questionName == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(e) => {
              setQuestionName(e.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, questionName: "" }));
              let value = e.target.value.replaceAll(" ", "");
              let time = new Date().getTime();
              //setQuestionId(value + "_" + time);
              if (!editQuestion) {
                setQuestionId(value + "_" + time);
              }
            }}
          />
          {checkErrorInForm.questionName == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Name
            </span>
          )}
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Question : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qus}
            style={{
              border: `${
                checkErrorInForm.qus == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqus(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qus: "" }));
            }}
          ></textarea>
          {checkErrorInForm.qus == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question
            </span>
          )}
        </div>
        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Instruction : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qusInstruction}
            style={{
              border: `${
                checkErrorInForm.qusInstruction == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqusInstruction(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "" }));
            }}
          ></textarea>
          {checkErrorInForm.qusInstruction == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Instruction
            </span>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              marginLeft: "1em",
            }}
          >
            {leftOptions.map((item, index) => (
              <>
                <p className="quizForm-matchUp-label">Option {index + 1}: </p>
                <input
                  disabled={canEditMatchPair ? "" : "disabled"}
                  className="quizForm-matchUp-inputBar"
                  value={item}
                  style={{
                    border: `${
                      checkErrorInForm.leftOptions[index] == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                  }}
                  placeholder={"Left option " + (index + 1)}
                  onChange={(e) => {
                    handleMatchPair(e, index + 1, "left");
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      leftOptions: ["", "", "", ""],
                    }));
                  }}
                />
                {checkErrorInForm.leftOptions[index] == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Option
                  </span>
                )}
                <br />
              </>
            ))}
          </div>

          <div>
            {rightOptions.map((item, index) => (
              <>
                <input
                  disabled={canEditMatchPair ? "" : "disabled"}
                  className="quizForm-matchUp-inputBar"
                  value={item.text}
                  style={{
                    border: `${
                      checkErrorInForm.rightOptions[index].text == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                  }}
                  placeholder={"Answer " + (index + 1)}
                  onChange={(e) => {
                    handleMatchPair(e, index + 1, "right");
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      rightOptions: [
                        { text: "" },
                        { text: "" },
                        { text: "" },
                        { text: "" },
                      ],
                    }));
                  }}
                />

                {checkErrorInForm.rightOptions[index].text == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Option
                  </span>
                )}
                <br />
              </>
            ))}
          </div>
        </div>
        <button
          className="quizFrom-button"
          style={{
            marginLeft: "1em",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleMatchUpScramble();
          }}
        >
          {/* onClick={handleMatchUpScramble} */}
          Scramble
        </button>
      </form>
      {formCheck && <div> Fill all the Required fields.</div>}
      <div className="quizForm-mainForm-infoRow">
        <button
          className="quizFrom-button"
          style={{ marginLeft: "auto" }}
          onClick={storedDataOnFirebase}
        >
          Submit
        </button>
      </div>
    </section>
  );
};

export default Matchthepair;
