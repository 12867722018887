import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { createClrsReducer } from "./Admin/Redux/Reducers";
import { blue } from "../src/Admin/Redux/Colors";
const reducer = combineReducers({
  createClr: createClrsReducer,
});

const newClrs = blue;

const userClrFromStorage = localStorage.getItem("clrs")
  ? JSON.parse(localStorage.getItem("clrs"))
  : localStorage.setItem("clrs", JSON.stringify(newClrs));

const intitialState = {
  //   createClr: {
  //     clrs: {
  //       SidebariconClr: "#D7D7D7",
  //       SidebarcntClr: "#ffffff",
  //       SidebartxtClr: "#717579",
  //     },
  //   },
  createClr: { clrs: userClrFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  intitialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
