import React, { useState } from "react";
import Course from "./imgSidebar/Course";
import Home from "./imgSidebar/Home";
import Assignment from "./imgSidebar/Assignment";
import Certificate from "./imgSidebar/Certificate";
import Profile from "./imgSidebar/Profile";
import Reports from "./imgSidebar/Reports";
import Training from "./imgSidebar/Training";
import Groups from "./imgSidebar/Groups";
import GroupEnrolments from "./imgSidebar/GroupEnrolments";
import { useSelector } from "react-redux";

function CardWrapper({ title, id, svgClr, handleclick, hidepage }) {
  const { clrs } = useSelector((state) => state.createClr);

  // useEffect(() => {
  //   console.log(clrs);
  // }, [clrs]);
  const [cntClr, setCntClr] = useState(
    clrs.DefSidebarcntClr ? clrs.DefSidebarcntClr : "#ffffff"
  );
  const [iconClr, seticonClr] = useState(
    clrs.DefSidebariconClr ? clrs.DefSidebariconClr : "#D7D7D7"
  );
  const [txtClr, setTxtClr] = useState(
    clrs.DefSidebartxtClr ? clrs.DefSidebartxtClr : "#717579"
  );

  const ChangeIconColor = () => {
    // iconClr === "#D7D7D7" ? seticonClr("#188775") : seticonClr("#D7D7D7");
    // cntClr === "#ffffff" ? setCntClr("#E6FCF5") : setCntClr("#ffffff");
    // txtClr === "#717579" ? setTxtClr("#188775") : setTxtClr("#717579");
    iconClr === (clrs.DefSidebariconClr ? clrs.DefSidebariconClr : "#D7D7D7")
      ? seticonClr(clrs.SidebariconClr)
      : seticonClr(clrs.DefSidebariconClr ? clrs.DefSidebariconClr : "#D7D7D7");
    cntClr === (clrs.DefSidebarcntClr ? clrs.DefSidebarcntClr : "#ffffff")
      ? setCntClr(clrs.SidebarcntClr)
      : setCntClr(clrs.DefSidebarcntClr ? clrs.DefSidebarcntClr : "#ffffff");
    txtClr === (clrs.DefSidebartxtClr ? clrs.DefSidebartxtClr : "#717579")
      ? setTxtClr(clrs.SidebartxtClr)
      : setTxtClr(clrs.DefSidebartxtClr ? clrs.DefSidebartxtClr : "#717579");
  };
  return (
    <div>
      <div
        className="sidebar-cnt-main"
        onClick={() => handleclick(id)}
        onMouseEnter={() => ChangeIconColor(id)}
        onMouseLeave={() => ChangeIconColor(id)}
        style={{
          background: `${
            svgClr[id - 1].cntColor ? svgClr[id - 1].cntColor : cntClr
          }`,
          color: `${svgClr[id - 1].txtClr ? svgClr[id - 1].txtClr : txtClr}`,
        }}
      >
        <div
          className="sidebar-cnt-main-cnt"
          style={{ gridTemplateColumns: `${hidepage ? "2vw" : "2vw 12vw"}` }}
        >
          <div
            className="sidebar-cnt-svg"
            style={{
              display: "grid",
              placeItems: "center",
            }}
          >
            {id === 1 && (
              <Home
                clr={
                  svgClr[id - 1].iconColor ? svgClr[id - 1].iconColor : iconClr
                }
              />
            )}
            {id === 2 && (
              <Course
                clr={
                  svgClr[id - 1].iconColor ? svgClr[id - 1].iconColor : iconClr
                }
              />
            )}
            {id === 3 && (
              <Training
                clr={
                  svgClr[id - 1].iconColor ? svgClr[id - 1].iconColor : iconClr
                }
              />
            )}
            {id === 4 && (
              <Profile
                clr={
                  svgClr[id - 1].iconColor ? svgClr[id - 1].iconColor : iconClr
                }
              />
            )}
            {id === 5 && (
              <Assignment
                clr={
                  svgClr[id - 1].iconColor ? svgClr[id - 1].iconColor : iconClr
                }
              />
            )}
            {id === 6 && (
              <Certificate
                clr={
                  svgClr[id - 1].iconColor ? svgClr[id - 1].iconColor : iconClr
                }
              />
            )}
            {id === 7 && (
              <Reports
                clr={
                  svgClr[id - 1].iconColor ? svgClr[id - 1].iconColor : iconClr
                }
              />
            )}
            {id === 8 && (
              <Groups
                clr={
                  svgClr[id - 1].iconColor ? svgClr[id - 1].iconColor : iconClr
                }
              />
            )}
            {id === 9 && (
              <GroupEnrolments
                clr={
                  svgClr[id - 1].iconColor ? svgClr[id - 1].iconColor : iconClr
                }
              />
            )}
          </div>
          {!hidepage && <div className="sidebar-cnt-txt">{title}</div>}
        </div>
      </div>{" "}
    </div>
  );
}

export default CardWrapper;
