import React, { useEffect, useState } from "react";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  deleteObject,
} from "@firebase/storage";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { db } from "../../../Firebase";
import { getStorage } from "firebase/storage";
import Select from "react-select";
import { getCookie } from "../../../Cookies";
import { useNavigate } from "react-router";

function QuestionForm() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  useEffect(() => {
    if (getCookie("UID") == null || getCookie("ROLE") != "admin") {
      goToHome();
      return <></>;
    }
  });
  const storage = getStorage();
  const [value, setValue] = useState("");
  const [qusName, setqusName] = useState("");
  const [qusMode, setqusMode] = useState("");
  const [qusPointscore, setqusPointscoreMode] = useState(0);
  const [qusCorrectAnswer, setqusCorrectAnswer] = useState("");
  const [quswrongAnswer, setquswrongAnswer] = useState("");
  const [qusnooflosthearts, setqusnooflosthearts] = useState(0);
  const [qushint, sethint] = useState("");
  const [quscategory, setquscategory] = useState("");
  const [quscategorypoint, setquscategorypoint] = useState(0);
  const [qustype, setqustype] = useState("Type what you hear");
  const [qus, setqus] = useState("");
  const [qusInstruction, setqusInstruction] = useState("");
  const [PointorScore, setPointorScore] = useState("");
  const [RewardAd, setRewardAd] = useState(false);
  const [NoOfRewardAd, setNoOfRewardAd] = useState(0);
  const [Dictionarybubble, setDictionarybubble] = useState(false);
  const [qus4, setqus4] = useState({ tts: " ", answer: "" });
  const [qus10, setqus10] = useState({
    textForTTS: "",
    textForDisplay: "",
    passingScore: 0,
    noOfTries: 0,
  });
  const [qus1, setqus1] = useState({ options: "", answer: "" });
  const [qus11, setqus11] = useState({ text: "", answer: "" });
  const [qus8, setqus8] = useState({ text: "", answer: "" });
  const [qus5_3_7, setqus5_3_7] = useState([{ option: "", iscorrect: "" }]);
  const [qus5, setqus5] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  const [qus3, setqus3] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  const [qus0, setqus0] = useState({ option1: "", option2: "", option3: "" });
  const [qus5Answer, setqus5Answer] = useState("");
  const [qus3Answer, setqus3Answer] = useState({
    Listentext: "",
    answer: "Option 1",
  });
  const [qus7lhs, setqus7lhs] = useState({
    option1: "",
    option2: "",
    option3: "",
  });
  const [qus7rhs, setqus7rhs] = useState({
    option1: "",
    option2: "",
    option3: "",
  });
  const [qus6, setqus6] = useState({ textForTts: "", minScore: 0 });
  const [qus2, setqus2] = useState({ word: "", correctImage: "" });
  const [progress1, setprogress1] = useState(0);
  const [progress2, setprogress2] = useState(0);
  const [progress3, setprogress3] = useState(0);
  const [progress4, setprogress4] = useState(0);
  const [url1, seturl1] = useState("");
  const [url2, seturl2] = useState("");
  const [url3, seturl3] = useState("");
  const [url4, seturl4] = useState("");
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [file1, setfile1] = useState(null);
  const [file2, setfile2] = useState(null);
  const [file3, setfile3] = useState(null);
  const [file4, setfile4] = useState(null);
  const [users, setusers] = useState([]);
  const [users1, setusers1] = useState([]);
  const [users2, setusers2] = useState([]);
  const [users3, setusers3] = useState([]);
  const [users4, setusers4] = useState([]);
  const [users5, setusers5] = useState([]);
  const [rootvalue, setrootvalue] = useState("");
  const [rootName, setrootName] = useState([]);
  const [lessonName, setlessonName] = useState([]);
  const [topicName, settopicName] = useState([]);
  const [quizName, setquizName] = useState([]);
  const [poolName, setpoolName] = useState([]);
  const [lessonName1, setlessonName1] = useState([]);
  const [topicName1, settopicName1] = useState([]);
  const [quizName1, setquizName1] = useState([]);
  const [poolName1, setpoolName1] = useState([]);
  const [userrootname, setuserrootname] = useState("");
  const [userlessonname, setuserlessonname] = useState("");
  const [usertopicname, setusertopicname] = useState("");
  const [userquizname, setuserquizname] = useState("");
  const [quizvalue, setquizvalue] = useState("");
  const [quizvaluecolumn, setquizvaluecolumn] = useState("");
  const [userLessonType, setuserLessonType] = useState("");
  const [userTopicType, setuserTopicType] = useState("");
  const [userquestionPool, setuserquestionPool] = useState("");
  const [dictionarybubbledisabled, setdictionarybubbledisabled] = useState(
    false
  );
  var root = [
    {
      value: "Courses",
      label: "General Course",
    },
    {
      value: "Grammar",
      label: "Grammar",
    },
    {
      value: "Practice",
      label: "Practice",
    },
    {
      value: "Test",
      label: "Test",
    },
    {
      value: "Story",
      label: "Story",
    },
  ];

  var [lesson, setlesson] = useState([
    {
      value: "Courses",
      label: "Lesson",
      isDisabled: false,
    },
    {
      value: "Objective",
      label: "Objective",
      isDisabled: false,
    },
    {
      value: "Arcade",
      label: "Arcade",
      isDisabled: false,
    },
  ]);
  var [topic, settopic] = useState([
    {
      value: "Lessons",
      label: "Topic",
      isDisabled: false,
    },
    {
      value: "Round",
      label: "Round",
      isDisabled: false,
    },
  ]);

  const getlessonType = async () => {
    const data = doc(db, `${rootvalue}/${userrootname}/extraInfo/infoDoc`);
    const docSnap = await getDoc(data);
    if (docSnap.exists()) {
      setusers1(docSnap.data());
      console.log("Document data:", docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };
  const getTopicType = async () => {
    const data = doc(db, `Lessons/${userlessonname}`);
    const docSnap = await getDoc(data);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setusers2(docSnap.data());
    } else {
      console.log("No such document!");
    }
  };
  const getquespool = async () => {
    const data = doc(db, `Quizs/${userquizname}`);
    const docSnap = await getDoc(data);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setusers4(docSnap.data());
      const data1 = doc(db, `QuestionPool/${docSnap.data().quizQuestionPool}`);
      const docSnap1 = await getDoc(data1);
      if (docSnap1.exists()) {
        console.log("Document data:", docSnap1.data());
        setusers5(docSnap1.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document! user 5");
      }
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document! user 4");
    }
  };
  const confirm = () => {
    setrootName([]);
    console.log(
      users.map((user) => {
        const query = { value: user.courseId, label: user.courseId };
        setrootName((rootName) => rootName.concat(query));
      })
    );
  };
  useEffect(() => {
    if (rootvalue == "Courses" || rootvalue == "Grammar") {
      setlesson((prev) => {
        return prev.filter((curvalue, idx) => {
          if (idx == 1 || idx == 2) {
            curvalue.isDisabled = true;
          } else {
            curvalue.isDisabled = false;
          }
          return curvalue;
        });
      });
      settopic((prev) => {
        return prev.filter((curvalue, idx) => {
          if (idx == 1) {
            curvalue.isDisabled = true;
          } else {
            curvalue.isDisabled = false;
          }
          return curvalue;
        });
      });
      setqusMode("QuizMode");
      setPointorScore("Score");
      setdictionarybubbledisabled(false);
    } else if (rootvalue == "Practice") {
      setlesson((prev) => {
        return prev.filter((curvalue, idx) => {
          if (idx == 0) {
            curvalue.isDisabled = true;
          } else {
            curvalue.isDisabled = false;
          }
          return curvalue;
        });
      });
      settopic((prev) => {
        return prev.filter((curvalue, idx) => {
          if (idx == 0) {
            curvalue.isDisabled = true;
          } else {
            curvalue.isDisabled = false;
          }
          return curvalue;
        });
      });
    } else if (rootvalue == "Test") {
      setlesson((prev) => {
        return prev.filter((curvalue, idx) => {
          if (idx == 0 || idx == 1 || idx == 2) {
            curvalue.isDisabled = true;
          } else {
            curvalue.isDisabled = false;
          }
          return curvalue;
        });
      });
      settopic((prev) => {
        return prev.filter((curvalue, idx) => {
          if (idx == 0 || idx == 1) {
            curvalue.isDisabled = true;
          } else {
            curvalue.isDisabled = false;
          }
          return curvalue;
        });
      });
      setqusMode("QuizMode");
      setPointorScore("Score");
      setdictionarybubbledisabled(false);
    } else if (rootvalue == "video") {
      setlesson((prev) => {
        return prev.filter((curvalue, idx) => {
          if (idx == 0 || idx == 1 || idx == 2) {
            curvalue.isDisabled = true;
          } else {
            curvalue.isDisabled = false;
          }
          return curvalue;
        });
      });
      settopic((prev) => {
        return prev.filter((curvalue, idx) => {
          if (idx == 0 || idx == 1) {
            curvalue.isDisabled = true;
          } else {
            curvalue.isDisabled = false;
          }
          return curvalue;
        });
      });
    }
  }, [rootvalue]);
  useEffect(() => {
    confirm();
  }, [users]);
  useEffect(() => {
    getlessonType();
    if (userLessonType == "Objective") {
      setqusMode("ObjectiveMode");
      setPointorScore("Point");
      setdictionarybubbledisabled(false);
    } else if (userLessonType == "Arcade") {
      setqusMode("ArcadeMode");
      setPointorScore("Score");
      setdictionarybubbledisabled(true);
    }
  }, [userLessonType]);
  useEffect(() => {
    getlessonName();
  }, [users1]);
  useEffect(() => {
    getTopicType();
  }, [userTopicType]);
  useEffect(() => {
    getTopicName();
  }, [users2]);
  useEffect(() => {
    quizsoln();
  }, [users3]);
  useEffect(() => {
    getquespool();
    quespool();
  }, [userquizname]);
  function getquestionPool() {
    getquespool();
  }
  const getlessonName = () => {
    if (userLessonType == "Arcade") {
      if (users1.courseFinalArcade != undefined) {
        setlessonName1([]);
        setlessonName1([
          { value: users1.courseFinalArcade, label: users1.courseFinalArcade },
        ]);
      }
    } else {
      setlessonName(users1.courseLessons);
      if (lessonName != undefined) {
        setlessonName1([]);
        console.log(
          lessonName.map((user) => {
            const query = { value: user, label: user };
            setlessonName1((lessonName1) => lessonName1.concat(query));
          })
        );
      }
    }
  };
  const getTopicName = () => {
    settopicName(users2.lessonTopics);
    if (topicName != undefined) {
      settopicName1([]);
      console.log(
        topicName.map((user) => {
          const query = { value: user, label: user };
          settopicName1((topicName1) => topicName1.concat(query));
        })
      );
    }
  };
  const getrootName = async () => {
    const usercollectionRef = collection(db, rootvalue);
    const data = await getDocs(usercollectionRef);
    console.log(data);
    setusers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };
  const rootvaluefromuser = (e) => {
    setrootvalue(e.value);
    setuserrootname("");
    setuserLessonType("");
    setuserlessonname("");
    setusertopicname("");
    setuserquizname("");
    setuserLessonType("");
    setuserTopicType("");
    setuserquestionPool("");
  };
  const rootnamefromuser = (e) => {
    setuserrootname(e.value);
    setuserLessonType("");
    setuserlessonname("");
    setusertopicname("");
    setuserquizname("");
    setuserLessonType("");
    setuserTopicType("");
    setuserquestionPool("");
  };
  const setuserlesson = (e) => {
    setuserlessonname(e.value);
    setusertopicname("");
    setuserquizname("");
    setuserTopicType("");
    setuserquestionPool("");
  };
  const setTopicValue = (e) => {
    setusertopicname(e.value);
    setuserquestionPool("");
    setuserquizname("");
  };
  const setquizValue = (e) => {
    setuserquizname(e.value);
    setusers5([]);
    setpoolName1([]);
    setuserquestionPool("");
    getquestionPool();
  };
  const getQuizdata = async () => {
    if (
      rootvalue == "Courses" ||
      rootvalue == "Grammar" ||
      rootvalue == "video" ||
      rootvalue == "Test" ||
      rootvalue == "Practice"
    ) {
      if (usertopicname != "" || rootvalue == "Practice") {
        const data = doc(db, `Topics/${usertopicname}/extraInfo/infoDoc`);
        const docSnap = await getDoc(data);
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          setusers3(docSnap.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      } else if (usertopicname == "" && userlessonname == "") {
        const data = doc(db, `${rootvalue}/${userrootname}/extraInfo/infoDoc`);
        const docSnap = await getDoc(data);
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          setusers3(docSnap.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      } else if (usertopicname == "" && userlessonname != "") {
        const data = doc(db, `Lessons/${userlessonname}/extraInfo/infoDoc`);
        const docSnap = await getDoc(data);
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          setusers3(docSnap.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }
    }
  };

  const quizsoln = () => {
    setquizName1([]);
    if (
      rootvalue == "Courses" ||
      rootvalue == "Grammar" ||
      rootvalue == "video" ||
      rootvalue == "Test" ||
      rootvalue == "Practice"
    ) {
      if (usertopicname != "" || rootvalue == "Practice") {
        setquizName(users3.topicQuiz);
        console.log(
          quizName.map((user) => {
            const query = { value: user, label: user };
            setquizName1((quizName1) => quizName1.concat(query));
          })
        );
      } else if (usertopicname == "" && userlessonname != "") {
        setquizName(users3.lessonQuiz);
        console.log(
          quizName.map((user) => {
            const query = { value: user, label: user };
            setquizName1((quizName1) => quizName1.concat(query));
          })
        );
      } else if (usertopicname == "" && userlessonname == "") {
        setquizName(users3.courseFinalQuiz);
        const query = { value: quizName, label: quizName };
        setquizName1((quizName1) => quizName1.concat(query));
      }
    }
  };
  const quespool = () => {
    setpoolName1([]);
    // setpoolName(users5.questionPoolId);
    const query = {
      value: users5.questionPoolId,
      label: users5.questionPoolId,
    };
    setpoolName1((poolName1) => poolName1.concat(query));
  };
  const setlessonType = (e) => {
    setuserLessonType(e.value);
    setuserlessonname("");
    setusertopicname("");
    setuserquizname("");
    setuserTopicType("");
    setuserquestionPool("");
  };
  const setTopicType = (e) => {
    setuserTopicType(e.value);
    setusertopicname("");
    setuserquizname("");
    setuserquestionPool("");
  };
  const setQuestionPool = (e) => {
    setuserquestionPool(e.value);
  };
  var Imagetype = [
    {
      value: 1,
      label: "Image 1",
    },
    {
      value: 2,
      label: "Image 2",
    },
    {
      value: 3,
      label: "Image 3",
    },
  ];
  var optiontype = [
    {
      value: 1,
      label: "option 1",
    },
    {
      value: 2,
      label: "Option 2",
    },
    {
      value: 3,
      label: "Option 3",
    },
  ];
  const [qus0Image, setqus0Image] = useState({});
  const [qus0option, setqus0option] = useState({});
  const added = (e) => {
    setqus0Image(Array.isArray(e) ? e.map((x) => x.label) : []);
  };
  const added1 = (e) => {
    setqus0option(Array.isArray(e) ? e.map((x) => x.label) : []);
  };
  const [pair1, setpair1] = useState("");
  const [pair2, setpair2] = useState("");
  const [pair3, setpair3] = useState("");

  const createPair = () => {
    setpair1(qus0Image[0] + ";" + qus0option[0]);
    setpair2(qus0Image[1] + ";" + qus0option[1]);
    setpair3(qus0Image[2] + ";" + qus0option[2]);
  };
  const clearPair = () => {
    setpair1("");
    setpair2("");
    setpair3("");
  };
  var lhstype = [
    {
      value: 1,
      label: "LHS 1",
    },
    {
      value: 2,
      label: "LHS 2",
    },
    {
      value: 3,
      label: "LHS 3",
    },
  ];
  var rhstype = [
    {
      value: 1,
      label: "RHS 1",
    },
    {
      value: 2,
      label: "RHS 2",
    },
    {
      value: 3,
      label: "RHS 3",
    },
  ];
  const [qus7lhstype, setqus7lhstype] = useState({});
  const [qus7rhstype, setqus7rhstype] = useState({});
  const addedlhs = (e) => {
    setqus7lhstype(Array.isArray(e) ? e.map((x) => x.label) : []);
  };
  const added1rhs = (e) => {
    setqus7rhstype(Array.isArray(e) ? e.map((x) => x.label) : []);
  };
  const createPairLhsRhs = () => {
    setpair1(qus7lhstype[0] + ";" + qus7rhstype[0]);
    setpair2(qus7lhstype[1] + ";" + qus7rhstype[1]);
    setpair3(qus7lhstype[2] + ";" + qus7rhstype[2]);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...qus5_3_7];
    list[index][name] = value;
    setqus5_3_7(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...qus5_3_7];
    list.splice(index, 1);
    setqus5_3_7(list);
  };
  const onImageChange1 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage1(URL.createObjectURL(event.target.files[0]));
    }
    setfile1(event.target.files[0]);
  };
  const onImageChange2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage2(URL.createObjectURL(event.target.files[0]));
    }
    setfile2(event.target.files[0]);
  };
  const onImageChange3 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage3(URL.createObjectURL(event.target.files[0]));
    }
    setfile3(event.target.files[0]);
  };
  const onImageChange4 = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage4(URL.createObjectURL(event.target.files[0]));
    }
    setfile4(event.target.files[0]);
  };
  const uploadFiles1 = (file) => {
    if (!file) return;
    const storageref = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageref, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setprogress1(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) =>
          console.log(seturl1(url))
        );
      }
    );
  };
  const uploadFiles2 = (file) => {
    if (!file) return;
    const storageref = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageref, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setprogress2(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) =>
          console.log(seturl2(url))
        );
      }
    );
  };
  const uploadFiles3 = (file) => {
    if (!file) return;
    const storageref = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageref, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setprogress3(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) =>
          console.log(seturl3(url))
        );
      }
    );
  };
  const uploadFiles4 = (file) => {
    if (!file) return;
    const storageref = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageref, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setprogress4(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) =>
          console.log(seturl4(url))
        );
      }
    );
  };
  const deleteImage1 = (file) => {
    const desertRef = ref(storage, file);
    deleteObject(desertRef)
      .then(() => {
        seturl1("");
        alert("deleted succefully");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteImage2 = (file) => {
    const desertRef = ref(storage, file);
    deleteObject(desertRef)
      .then(() => {
        seturl2("");
        alert("deleted succefully");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteImage3 = (file) => {
    const desertRef = ref(storage, file);
    deleteObject(desertRef)
      .then(() => {
        seturl3("");
        alert("deleted succefully");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteImage4 = (file) => {
    const desertRef = ref(storage, file);
    deleteObject(desertRef)
      .then(() => {
        seturl4("");
        alert("deleted succefully");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function submit(e) {
    e.preventDefault();
  }
  let questionCategoryTypeEnumfromuser;
  let questionTypeEnum;
  let questionCategoryPointfromuser;
  let managequestionData;
  let mode;
  let quscategorypointAsInt = parseInt(quscategorypoint, 10);
  let qusnooflostheartsAsInt = parseInt(qusnooflosthearts, 10);
  let NoOfRewardAdAsInt = parseInt(NoOfRewardAd, 10);
  let qusPointscoreAsInt = parseInt(qusPointscore, 10);
  let date = new Date();
  let ModifiedDate = date.toString().replaceAll(" ", "").substring(0, 20);
  let questionIdfromuser = qusName + "_" + ModifiedDate;
  const storedDataOnFirebase = async () => {
    if (qustype == "Type what you hear") {
      questionTypeEnum = 4;
      let questionDatafromUser = {
        answer: qus4.tts,
        question: qus,
        questionInstruction: qusInstruction,
        tts: qus4.answer,
      };
      managequestionData = questionDatafromUser;
    } else if (qustype == "Choose the correct option") {
      let setoptionFromuser;
      if (qus5Answer == "Option 1") {
        let optionsFromUser = [
          { iscorrect: true, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 2") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: true, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 3") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: true, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 4") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: true, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      }
      let questionDatafromUser = {
        options: setoptionFromuser,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;

      questionTypeEnum = 5;
    } else if (qustype == "Read Listen Speak") {
      let qusNoOfTriesAsInt = parseInt(qus10.noOfTries, 10);
      let qusPassingScoreAsInt = parseInt(qus10.passingScore, 10);
      let questionDatafromUser = {
        noOfTries: qusNoOfTriesAsInt,
        passingScore: qusPassingScoreAsInt,
        question: qus,
        questionInstruction: qusInstruction,
        textForDisplay: qus10.textForDisplay,
        textForTTS: qus10.textForTTS,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 10;
    } else if (qustype == "Fill in the sequence") {
      const myArray = qus1.options.split(";");
      let questionDatafromUser = {
        answer: qus1.answer,
        options: myArray,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 1;
    } else if (qustype == "Identify the image") {
      let qusMinScoreAsInt = parseInt(qus6.minScore, 10);
      let questionDatafromUser = {
        imageLink: url1,
        minScore: qusMinScoreAsInt,
        question: qus,
        questionInstruction: qusInstruction,
        textForTts: qus6.textForTts,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 6;
    } else if (qustype == "Choose the correct image of the given word") {
      let qusCorrectImageAsInt = parseInt(qus2.correctImage, 10);
      let imageOptionFromUser = [url1, url2, url3, url4];
      let questionDatafromUser = {
        correctImage: qusCorrectImageAsInt,
        imageOptions: imageOptionFromUser,
        question: qus,
        questionInstruction: qusInstruction,
        word: qus2.word,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 2;
    } else if (qustype == "Fill in the blank (type)") {
      const myAnswer = qus8.text.split(";");
      const myoption = qus8.answer.split(";");
      let questionDatafromUser = {
        answer: myAnswer,
        text: myoption,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 8;
    } else if (qustype == "Fill in the blank (voice)") {
      const myAnswer = qus11.text.split(";");
      const myoption = qus.answer.split(";");
      let questionDatafromUser = {
        answer: myAnswer,
        text: myoption,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 11;
    } else if (qustype == "Pairing (words to words)") {
      var res1 = qus7lhstype[0].charAt(qus7lhstype[0].length - 1);
      var lhs1 = parseInt(res1, 10) - 1;
      var res2 = qus7rhstype[0].charAt(qus7rhstype[0].length - 1);
      var rhs1 = parseInt(res2, 10) - 1;
      var res3 = qus7lhstype[1].charAt(qus7lhstype[1].length - 1);
      var lhs2 = parseInt(res3, 10) - 1;
      var res4 = qus7rhstype[1].charAt(qus7rhstype[1].length - 1);
      var rhs2 = parseInt(res4, 10) - 1;
      var res5 = qus7lhstype[2].charAt(qus7lhstype[2].length - 1);
      var lhs3 = parseInt(res5, 10) - 1;
      var res6 = qus7rhstype[2].charAt(qus7rhstype[2].length - 1);
      var rhs3 = parseInt(res6, 10) - 1;
      let pairFromUser = [
        { lhsId: lhs1, rhsId: rhs1 },
        { lhsId: lhs2, rhsId: rhs2 },
        { lhsId: lhs3, rhsId: rhs3 },
      ];
      let lhsFromUser = [qus7lhs.option1, qus7lhs.option2, qus7lhs.option3];
      let rhsFromUser = [qus7rhs.option1, qus7rhs.option2, qus7rhs.option3];
      let questionDatafromUser = {
        LHS: lhsFromUser,
        RHS: rhsFromUser,
        pair: pairFromUser,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 7;
    } else if (qustype == "Pairing (word to images)") {
      let ans1;
      let ans2;
      let ans3;
      if (qus0Image[0] == "Image 1") {
        var res = qus0option[0].charAt(qus0option[0].length - 1);
        ans1 = parseInt(res, 10) - 1;
      } else if (qus0Image[1] == "Image 1") {
        var res = qus0option[1].charAt(qus0option[1].length - 1);
        ans1 = parseInt(res, 10) - 1;
      } else if (qus0Image[2] == "Image 1") {
        var res = qus0option[2].charAt(qus0option[2].length - 1);
        ans1 = parseInt(res, 10) - 1;
      }
      if (qus0Image[0] == "Image 2") {
        var res = qus0option[0].charAt(qus0option[0].length - 1);
        ans2 = parseInt(res, 10) - 1;
      } else if (qus0Image[1] == "Image 2") {
        var res = qus0option[1].charAt(qus0option[1].length - 1);
        ans2 = parseInt(res, 10) - 1;
      } else if (qus0Image[2] == "Image 2") {
        var res = qus0option[2].charAt(qus0option[2].length - 1);
        ans2 = parseInt(res, 10) - 1;
      }
      if (qus0Image[0] == "Image 3") {
        var res = qus0option[0].charAt(qus0option[0].length - 1);
        ans3 = parseInt(res, 10) - 1;
      } else if (qus0Image[1] == "Image 3") {
        var res = qus0option[1].charAt(qus0option[1].length - 1);
        ans3 = parseInt(res, 10) - 1;
      } else if (qus0Image[2] == "Image 3") {
        var res = qus0option[2].charAt(qus0option[2].length - 1);
        ans3 = parseInt(res, 10) - 1;
      }
      let imageOptionFromUser = [
        { answer: ans1, imageLink: url1 },
        { answer: ans2, imageLink: url2 },
        { answer: ans3, imageLink: url3 },
      ];
      let testOptionFromUser = [
        { option: qus0.option1 },
        { option: qus0.option2 },
        { option: qus0.option3 },
      ];
      let questionDatafromUser = {
        imageoptions: imageOptionFromUser,
        textoptions: testOptionFromUser,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 0;
    } else if (qustype == "Tap what you hear") {
      let setoptionFromuser;
      if (qus3Answer.answer == "Option 1") {
        let optionsFromUser = [
          { iscorrect: true, option: qus3.option1 },
          { iscorrect: false, option: qus3.option2 },
          { iscorrect: false, option: qus3.option3 },
          { iscorrect: false, option: qus3.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus3Answer.answer == "Option 2") {
        let optionsFromUser = [
          { iscorrect: false, option: qus3.option1 },
          { iscorrect: true, option: qus3.option2 },
          { iscorrect: false, option: qus3.option3 },
          { iscorrect: false, option: qus3.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus3Answer.answer == "Option 3") {
        let optionsFromUser = [
          { iscorrect: false, option: qus3.option1 },
          { iscorrect: false, option: qus3.option2 },
          { iscorrect: true, option: qus3.option3 },
          { iscorrect: false, option: qus3.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus3Answer.answer == "Option 4") {
        let optionsFromUser = [
          { iscorrect: false, option: qus3.option1 },
          { iscorrect: false, option: qus3.option2 },
          { iscorrect: false, option: qus3.option3 },
          { iscorrect: true, option: qus3.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      }
      let questionDatafromUser = {
        listenText: qus3Answer.Listentext,
        options: setoptionFromuser,
        question: qus,
        questionInstruction: qusInstruction,
      };
      console.log(JSON.stringify(qus3Answer.answer));
      managequestionData = questionDatafromUser;
      questionTypeEnum = 3;
    }
    const docData = {
      questionName: questionName,
      questionId: questionId,
      assoLessonId: userlessonname,
      assoParentId: userrootname,
      assoQuizId: userquizname,
      assoTopicId: usertopicname,
      associatedRootType: rootvalue,
      associatedLessonType: userLessonType,
      associatedTopicType: userTopicType,
      questionCorrectMessage: qusCorrectAnswer,
      questionPoolId: userquestionPool,
      questionWrongMessage: quswrongAnswer,
    };
    await setDoc(doc(db, "Questions", questionIdfromuser), docData);
    const washingtonRef = doc(db, "QuestionPool", userquestionPool);
    if (qustype == "Type what you hear") {
      await updateDoc(washingtonRef, {
        questionsId: arrayUnion(questionIdfromuser),
        availQuestionsForTypeWhatYouHear: increment(1),
        availQuestionsInPool: increment(1),
      });
    } else if (qustype == "Choose the correct option") {
      await updateDoc(washingtonRef, {
        questionsId: arrayUnion(questionIdfromuser),
        availQuestionsForMcq: increment(1),
        availQuestionsInPool: increment(1),
      });
    } else if (qustype == "Read Listen Speak") {
      await updateDoc(washingtonRef, {
        questionsId: arrayUnion(questionIdfromuser),
        availQuestionsForReadListenSpeak: increment(1),
        availQuestionsInPool: increment(1),
      });
    } else if (qustype == "Fill in the sequence") {
      await updateDoc(washingtonRef, {
        questionsId: arrayUnion(questionIdfromuser),
        availQuestionsForFillTheSequence: increment(1),
        availQuestionsInPool: increment(1),
      });
    } else if (qustype == "Identify the image") {
      await updateDoc(washingtonRef, {
        questionsId: arrayUnion(questionIdfromuser),
        availQuestionsForIdentifyTheImage: increment(1),
        availQuestionsInPool: increment(1),
      });
    } else if (qustype == "Choose the correct image of the given word") {
      await updateDoc(washingtonRef, {
        questionsId: arrayUnion(questionIdfromuser),
        availQuestionsForCorrectImageFromWord: increment(1),
        availQuestionsInPool: increment(1),
      });
    } else if (qustype == "Fill in the blank (type)") {
      await updateDoc(washingtonRef, {
        questionsId: arrayUnion(questionIdfromuser),
        availQuestionsForFillTheBlanksType: increment(1),
        availQuestionsInPool: increment(1),
      });
    } else if (qustype == "Fill in the blank (voice)") {
      await updateDoc(washingtonRef, {
        questionsId: arrayUnion(questionIdfromuser),
        availQuestionsForFillTheBlanksVoice: increment(1),
        availQuestionsInPool: increment(1),
      });
    } else if (qustype == "Pairing (words to words)") {
      await updateDoc(washingtonRef, {
        questionsId: arrayUnion(questionIdfromuser),
        availQuestionsForWordsToWords: increment(1),
        availQuestionsInPool: increment(1),
      });
    } else if (qustype == "Pairing (word to images)") {
      await updateDoc(washingtonRef, {
        questionsId: arrayUnion(questionIdfromuser),
        availQuestionsForWordsToImage: increment(1),
        availQuestionsInPool: increment(1),
      });
    } else if (qustype == "Tap what you hear") {
      await updateDoc(washingtonRef, {
        questionsId: arrayUnion(questionIdfromuser),
        availQuestionsForTapWhatYouHear: increment(1),
        availQuestionsInPool: increment(1),
      });
    }
    alert("data saved successfully");
    document.getElementById("question-form").reset();
    setDictionarybubble(false);
    setRewardAd(false);
    setrootName([]);
    setlessonName1([]);
    settopicName1([]);
    setpoolName1([]);
    setquizName1([]);
    seturl1("");
    seturl2("");
    seturl3("");
    seturl4("");
  };

  const [questionName, setQuestionName] = useState("");
  const [questionId, setQuestionId] = useState("");
  const fetchCourse = () => {
    db.collection("course")
      .get()
      .then((snapshot) => {
        var ins = "";
        snapshot.forEach((doc) => {
          ins += "<option value='" + doc.id + "'>" + doc.id + "</option>";
        });
        document.getElementById("courseQuiz").innerHTML = ins;
      });
  };
  const fetchLesson = () => {
    db.collection("lesson")
      .where(
        "courseAssociated",
        "==",
        document.getElementById("courseQuiz").value
      )
      .get()
      .then((snapshot) => {
        var ins = "";
        snapshot.forEach((doc) => {
          ins += "<option value='" + doc.id + "'>" + doc.id + "</option>";
        });
        document.getElementById("lessonQuiz").innerHTML = ins;
      });
  };
  const fetchTopic = () => {
    db.collection("topic")
      .where(
        "lessonAssociated",
        "==",
        document.getElementById("lessonQuiz").value
      )
      .get()
      .then((snapshot) => {
        var ins = "";
        snapshot.forEach((doc) => {
          ins += "<option value='" + doc.id + "'>" + doc.id + "</option>";
        });
        document.getElementById("topicQuiz").innerHTML = ins;
      });
  };
  const fetchSet = () => {
    db.collection("questionSet")
      .where(
        "topicAssociated",
        "==",
        document.getElementById("topicQuiz").value
      )
      .where(
        "lessonAssociated",
        "==",
        document.getElementById("lessonQuiz").value
      )
      .where(
        "courseAssociated",
        "==",
        document.getElementById("courseQuiz").value
      )
      .get()
      .then((snapshot) => {
        var ins = "";
        snapshot.forEach((doc) => {
          ins += "<option value='" + doc.id + "'>" + doc.id + "</option>";
        });
        document.getElementById("questionSet").innerHTML = ins;
      });
  };

  return (
    <>
      <h1 style={{ textAlign: "center" }}>Question Form</h1>
      <div>
        <p className="inputLabel">Qestion Name</p>
        <input
          type="text"
          className="inputBar"
          id="questionName"
          onKeyUp={(event) => {
            setQuestionName(event.target.value);
            var today = new Date();
            var time =
              today.getDate() +
              "" +
              today.getMonth() +
              "" +
              today.getFullYear() +
              today.getHours() +
              "" +
              today.getMinutes() +
              "" +
              today.getSeconds();
            setQuestionId(
              document.getElementById("questionName").value + "_" + time
            );
            document.getElementById("questionId").value =
              document.getElementById("questionName").value + "_" + time;
          }}
        />
      </div>
      <div>
        <p className="inputLabel">Question Id</p>
        <input
          disabled
          type="text"
          className="inputBar"
          id="questionId"
          onChange={(event) => {
            setQuestionId(event.target.value);
          }}
        />
      </div>
      <p className="inputLabel">Associated Course</p>
      <button onClick={fetchCourse}>Get Courses</button>
      <br></br>
      <select id="courseQuiz"></select>
      <p className="inputLabel">Associated Lesson</p>
      <button onClick={fetchLesson}>Get Lessons</button>
      <br></br>
      <select id="lessonQuiz"></select>
      <p className="inputLabel">Associated Topic</p>
      <button onClick={fetchTopic}>Get Topics</button>
      <br></br>
      <select id="topicQuiz"></select>
      <p className="inputLabel">Associated Question Set</p>
      <button onClick={fetchSet}>Get Question Set</button>
      <br></br>
      <select id="questionSet"></select>
      <form id="question-form" onSubmit={submit}>
        <div>
          <label>Type of question : </label>
          <select
            value={qustype}
            onChange={(event) => {
              setqustype(event.target.value);
            }}
          >
            {/* <option value="Type what you hear">Type what you hear</option> */}
            <option value="Choose the correct option">
              Choose the correct option
            </option>
            {/* <option value="Read Listen Speak">Read Listen Speak</option> */}
            <option value="Fill in the sequence">Fill in the sequence</option>
            {/* <option value="Identify the image">Identify the image</option> */}
            <option value="Choose the correct image of the given word">
              Choose the correct image of the given word
            </option>
            <option value="Fill in the blank (type)">
              Fill in the blank (type)
            </option>
            {/*   <option value="Fill in the blank (voice)">Fill in the blank (voice)</option> */}
            <option value="Pairing (words to words)">
              Pairing (words to words)
            </option>
            <option value="Pairing (word to images)">
              Pairing (word to images)
            </option>
            {/* <option value="Tap what you hear">Tap what you hear</option> */}
          </select>
          <br />
          <br />
          {qustype == "Type what you hear" ? (
            <>
              <label>Text for TTS : </label>
              <textarea
                cols="30"
                rows="10"
                placeholder="Text For TTS"
                onChange={(e) => setqus4({ ...qus4, tts: e.target.value })}
              ></textarea>
              <br />
              <br />
              <label>Answer : </label>
              <textarea
                cols="30"
                rows="10"
                placeholder="Answer"
                onChange={(event) => {
                  setqus4({ ...qus4, answer: event.target.value });
                }}
              ></textarea>
            </>
          ) : (
            " "
          )}
          {qustype == "Read Listen Speak" ? (
            <>
              <label>Text for TTS : </label>
              <textarea
                cols="30"
                rows="10"
                type="text"
                placeholder="Text For TTS"
                onChange={(event) => {
                  setqus10({ ...qus10, textForTTS: event.target.value });
                }}
              />
              <br />
              <br />
              <label>Text For Display : </label>
              <textarea
                cols="30"
                rows="10"
                type="text"
                placeholder="Text For Display"
                onChange={(event) => {
                  setqus10({ ...qus10, textForDisplay: event.target.value });
                }}
              />
              <br />
              <br />
              <label>Passing Score : </label>
              <input
                type="text"
                placeholder="Passing Score"
                onChange={(event) => {
                  setqus10({ ...qus10, passingScore: event.target.value });
                }}
              />
              <br />
              <br />
              <label>No Of Tries : </label>
              <input
                type="text"
                placeholder="No Of Tries "
                onChange={(event) => {
                  setqus10({ ...qus10, noOfTries: event.target.value });
                }}
              />
            </>
          ) : (
            " "
          )}
          {qustype == "Fill in the sequence" ? (
            <>
              <label>Optios (Enter the otion seperated by semicolion) : </label>
              <textarea
                cols="30"
                rows="10"
                type="text"
                placeholder="Ex - you;where;going;are"
                onChange={(event) => {
                  setqus1({ ...qus1, options: event.target.value });
                }}
              />
              <br />
              <br />
              <label>Correct Sequence : </label>
              <textarea
                cols="30"
                rows="10"
                type="text"
                placeholder="Correct Sequence"
                onChange={(event) => {
                  setqus1({ ...qus1, answer: event.target.value });
                }}
              />
            </>
          ) : (
            " "
          )}
          {qustype == "Fill in the blank (type)" ? (
            <>
              <label>Optios (Enter the otion seperated by semicolion) : </label>
              <textarea
                cols="30"
                rows="10"
                type="text"
                placeholder="Ex - you;where;going;are"
                onChange={(event) => {
                  setqus8({ ...qus8, text: event.target.value });
                }}
              />
              <br />
              <br />
              <label>Text : </label>
              <textarea
                cols="30"
                rows="10"
                type="text"
                placeholder="Text For Display"
                onChange={(event) => {
                  setqus8({ ...qus8, answer: event.target.value });
                }}
              />
            </>
          ) : (
            " "
          )}
          {qustype == "Choose the correct option" ? (
            <>
              <label>Option 1 : </label>
              <input
                name="text"
                placeholder="Enter Option"
                onChange={(event) => {
                  setqus5({ ...qus5, option1: event.target.value });
                }}
              />
              <br />
              <br />
              <label>Option 2 : </label>
              <input
                name="text"
                placeholder="Enter Option"
                onChange={(event) => {
                  setqus5({ ...qus5, option2: event.target.value });
                }}
              />
              <br />
              <br />
              <label>Option 3 : </label>
              <input
                name="text"
                placeholder="Enter Option"
                onChange={(event) => {
                  setqus5({ ...qus5, option3: event.target.value });
                }}
              />
              <br />
              <br />
              <label>Option 4 : </label>
              <input
                type="text"
                placeholder="Enter Option"
                onChange={(event) => {
                  setqus5({ ...qus5, option4: event.target.value });
                }}
              />
              <br />
              <br />
              <label> Choose the correct Option : </label>
              <select
                value={qus5Answer}
                onChange={(event) => {
                  setqus5Answer(event.target.value);
                }}
              >
                <option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
                <option value="Option 3">Option 3</option>
                <option value="Option 4">Option 4</option>
              </select>
            </>
          ) : (
            " "
          )}
          {qustype == "Tap what you hear" ? (
            <>
              <label>listen Text : </label>
              <textarea
                cols="30"
                rows="10"
                type="text"
                placeholder="Listen Text"
                onChange={(event) => {
                  setqus3Answer({
                    ...qus3Answer,
                    Listentext: event.target.value,
                  });
                }}
              />
              <br /> <br />
              <label>Option 1 : </label>
              <input
                name="text"
                placeholder="Enter Option"
                onChange={(event) => {
                  setqus3({ ...qus3, option1: event.target.value });
                }}
              />
              <br />
              <br />
              <label>Option 2 : </label>
              <input
                name="text"
                placeholder="Enter Option"
                onChange={(event) => {
                  setqus3({ ...qus3, option2: event.target.value });
                }}
              />
              <br />
              <br />
              <label>Option 3 : </label>
              <input
                name="text"
                placeholder="Enter Option"
                onChange={(event) => {
                  setqus3({ ...qus3, option3: event.target.value });
                }}
              />
              <br />
              <br />
              <label>Option 4 : </label>
              <input
                type="text"
                placeholder="Enter Option"
                onChange={(event) => {
                  setqus3({ ...qus3, option4: event.target.value });
                }}
              />
              <br />
              <br />
              <label> Choose the correct Option : </label>
              <select
                value={qus3Answer.answer}
                onChange={(event) => {
                  setqus3Answer({ ...qus3Answer, answer: event.target.value });
                }}
              >
                <option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
                <option value="Option 3">Option 3</option>
                <option value="Option 4">Option 4</option>
              </select>
            </>
          ) : (
            " "
          )}
          {qustype === "Pairing (words to words)" ? (
            <>
              <label>LHS 1 : </label>
              <input
                name="text"
                placeholder="Enter Option"
                onChange={(event) => {
                  setqus7lhs({ ...qus7lhs, option1: event.target.value });
                }}
              />
              &nbsp;
              <label>RHS 1 : </label>
              <input
                name="text"
                placeholder="Enter Option"
                onChange={(event) => {
                  setqus7rhs({ ...qus7rhs, option1: event.target.value });
                }}
              />
              <br />
              <br />
              <label>LHS 2 : </label>
              <input
                name="text"
                placeholder="Enter Option"
                onChange={(event) => {
                  setqus7lhs({ ...qus7lhs, option2: event.target.value });
                }}
              />
              &nbsp;
              <label>RHS 2 : </label>
              <input
                name="text"
                placeholder="Enter Option"
                onChange={(event) => {
                  setqus7rhs({ ...qus7rhs, option2: event.target.value });
                }}
              />
              <br />
              <br />
              <label>LHS 3 : </label>
              <input
                name="text"
                placeholder="Enter Option"
                onChange={(event) => {
                  setqus7lhs({ ...qus7lhs, option3: event.target.value });
                }}
              />
              &nbsp;
              <label>RHS 3 : </label>
              <input
                name="text"
                placeholder="Enter Option"
                onChange={(event) => {
                  setqus7rhs({ ...qus7rhs, option3: event.target.value });
                }}
              />
              <br />
              <br />
              <label> Choose the correct Pair : </label>
              <Select isMulti options={lhstype} onChange={addedlhs}></Select>
              <Select isMulti options={rhstype} onChange={added1rhs}></Select>
              <button onClick={createPairLhsRhs}>Add Pair</button>
              <button onClick={clearPair}>Reset Pair</button>
              <h3>Pair 1 - {` ${pair1}`} </h3>
              <h3>Pair 2 - {` ${pair2}`}</h3>
              <h3> Pair 3 - {` ${pair3} `}</h3>
            </>
          ) : (
            " "
          )}
          {qustype == "Pairing (word to images)" ? (
            <>
              <label>Image 1 :</label>
              <input type="file" onChange={onImageChange1} />
              <button onClick={() => uploadFiles1(file1)}>upload</button>
              <button onClick={() => deleteImage1(url1)}>Delete</button>
              <img src={url1} alt="" width="193" height="130" />
              <label> Option 1 : </label>
              <input
                name="text"
                placeholder="Enter Option"
                onChange={(event) => {
                  setqus0({ ...qus0, option1: event.target.value });
                }}
              />
              <h3>uploaded {progress1}%</h3>
              <br />
              <br />
              <label>Image 2 :</label>
              <input type="file" onChange={onImageChange2} />
              <button onClick={() => uploadFiles2(file2)}>upload</button>
              <button onClick={() => deleteImage2(url2)}>Delete</button>
              <img src={url2} alt="" width="193" height="130" />
              <label> Option 2 : </label>
              <input
                name="text"
                placeholder="Enter Option"
                onChange={(event) => {
                  setqus0({ ...qus0, option2: event.target.value });
                }}
              />
              <h3>uploaded {progress2}%</h3>
              <br />
              <br />
              <label>Image 3 :</label>
              <input type="file" onChange={onImageChange3} />
              <button onClick={() => uploadFiles3(file3)}>upload</button>
              <button onClick={() => deleteImage3(url3)}>Delete</button>
              <img src={url3} alt="" width="193" height="130" />
              <label> Option 3 : </label>
              <input
                name="text"
                placeholder="Enter Option"
                onChange={(event) => {
                  setqus0({ ...qus0, option3: event.target.value });
                }}
              />
              <h3>uploaded {progress3}%</h3>
              <label> Choose the correct Pair : </label>
              <Select isMulti options={Imagetype} onChange={added}></Select>
              <Select isMulti options={optiontype} onChange={added1}></Select>
              <button onClick={createPair}>Add Pair</button>
              <button onClick={clearPair}>Reset Pair</button>
              <h3>Pair 1 - {` ${pair1}`} </h3>
              <h3>Pair 2 - {` ${pair2}`}</h3>
              <h3> Pair 3 - {` ${pair3} `}</h3>
            </>
          ) : (
            " "
          )}
          {qustype == "Identify the image" ? (
            <>
              <label>Text for tts : </label>
              <input
                type="text"
                placeholder="Ex - you;where;going;are"
                onChange={(event) => {
                  setqus6({ ...qus6, textForTts: event.target.value });
                }}
              />
              <br />
              <br />
              <label>Minmium Score : </label>
              <input
                type="number"
                style={{ width: "50px" }}
                min="0"
                onChange={(event) => {
                  setqus6({ ...qus6, minScore: event.target.value });
                }}
              />
              <br />
              <br />
              <label>Image 1 :</label>
              <input type="file" onChange={onImageChange1} />
              <button onClick={() => uploadFiles1(file1)}>upload</button>
              <button onClick={() => deleteImage1(url1)}>Delete</button>
              <img src={url1} alt="" width="193" height="130" />
              <h3>uploaded {progress1}%</h3>
            </>
          ) : (
            " "
          )}
          {qustype == "Choose the correct image of the given word" ? (
            <>
              <label>Words: </label>
              <textarea
                cols="30"
                rows="10"
                type="text"
                placeholder="Ex - you;where;going;are"
                onChange={(event) => {
                  setqus2({ ...qus2, word: event.target.value });
                }}
              />
              <br />
              <br />
              <label> Choose the correct Image : </label>
              <select
                value={qus5Answer.answer}
                onChange={(event) => {
                  setqus2({ ...qus2, correctImage: event.target.value });
                }}
              >
                <option value="0">Image1</option>
                <option value="1">Image2</option>
                <option value="2">Image3</option>
                <option value="3">Image4</option>
              </select>
              <br />
              <br />
              <label>Image 1 :</label>
              <input type="file" onChange={onImageChange1} />
              <button onClick={() => uploadFiles1(file1)}>upload</button>
              <button onClick={() => deleteImage1(url1)}>Delete</button>
              <img src={url1} alt="" width="193" height="130" />
              <h3>uploaded {progress1}%</h3>
              <br />
              <br />
              <label>Image 2 :</label>
              <input type="file" onChange={onImageChange2} />
              <button onClick={() => uploadFiles2(file2)}>upload</button>
              <button onClick={() => deleteImage2(url2)}>Delete</button>
              <img src={url2} alt="" width="193" height="130" />
              <h3>uploaded {progress2}%</h3>
              <br />
              <br />
              <label>Image 3 :</label>
              <input type="file" onChange={onImageChange3} />
              <button onClick={() => uploadFiles3(file3)}>upload</button>
              <button onClick={() => deleteImage3(url3)}>Delete</button>
              <img src={url3} alt="" width="193" height="130" />
              <h3>uploaded {progress3}%</h3>
              <label>Image 4 :</label>
              <br />
              <br />
              <h3>uploaded {progress4}%</h3>
              <input type="file" onChange={onImageChange4} />
              <button onClick={() => uploadFiles4(file4)}>upload</button>
              <button onClick={() => deleteImage4(url4)}>Delete</button>
              <img src={url4} alt="" width="193" height="130" />
            </>
          ) : (
            " "
          )}
          <br /> <br />
          <label>Question : </label>
          <textarea
            cols="30"
            rows="10"
            placeholder="Question"
            onChange={(event) => {
              setqus(event.target.value);
            }}
          ></textarea>
          <br />
          <br />
          <label>Instruction : </label>
          <textarea
            cols="30"
            rows="10"
            placeholder="Instruction"
            onChange={(event) => {
              setqusInstruction(event.target.value);
            }}
          ></textarea>
          <br />
          <br />
        </div>
      </form>
      <button onClick={storedDataOnFirebase}> Submit </button>
      {/*  <p>{`You selected ${Dictionarybubble} ${RewardAd} ${url2} ${url3}  ${url4} ${qus2.correctImage} ${qus2.word}  ${qusCorrectAnswer} ${qus5_3_7.length} ${qusnooflosthearts} ${qushint} ${qusPointscore} ${quscategory} ${quscategorypoint} ${qustype} ${qus} ${qusInstruction}`}</p>
       */}
      {/*    {
         `${qus4.tts}`
       } */}
      {/*  {`${JSON.stringify(qus3Answer.answer)}`} */}
    </>
  );
}

export default QuestionForm;
