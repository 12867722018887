import { React, useEffect, useState } from "react";
import $ from "jquery";
import { Timestamp } from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import "./styles/item.css";
import Papa from "papaparse";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { fontSize } from "@mui/system";
import { IP } from "../../../config";
import axios from "axios";
import { getCookie } from "../../../Cookies";
import DeleteLesson from "./lessonDeletePopUp";

const Item = ({ courseId, stageId, unitId, levelId, itemsArr, editData }) => {
  const storage = getStorage();
  var docURL = [];
  var files = [];
  const [itemName, setItemName] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [deleteQuestionId, setDeleteQuestionId] = useState("");

  const initialList = [];
  const [list, setList] = useState(initialList);
  const [editable, setEditable] = useState(false);
  const createItem = httpsCallable(functions, "createItem");

  const addUnitItemOrder = httpsCallable(functions, "addUnitItemOrder");
  const addStageItemOrder = httpsCallable(functions, "addStageItemOrder");
  const addLevelItemOrder = httpsCallable(functions, "addLevelItemOrder");
  const deleteItem = httpsCallable(functions, "deleteItem");
  const updateItem = httpsCallable(functions, "updateItem");
  const [lengthPro, setLengthPro] = useState(true);
  const [flagForIndex, setFlagForIndex] = useState();
  //deleteItem
  const [flasglistdataarr, setFlashListDataArr] = useState([]);
  // addLessonItemOrder addTopicItemOrder
  const [itemDetails, setItemDetails] = useState({
    itemName: "",
    pdfBase64: "",
    videoLink: "",
    htmlLink: "",
    externalLink: "",
    audioLink: "",
    videothumbnail: "",
    engText: "",
    flashCardInstructionText: "",
    arabicText: "",
    audiolrc: "",
    flashimg: "",
    itemDescription: "",
  });

  const [editdataitemflag, setEditdataItemFlag] = useState(false);

  const [itemId, setItemId] = useState("");
  const [itemType, setItemType] = useState("pdf");

  const [pdfLink, setPDFLink] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [htmlLink, setHTMLLink] = useState("");
  const [extLink, setExtLink] = useState("");
  const [audioLink, setAudioLink] = useState("");
  const [checkFailed, setCheckFailed] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const [maxLengthForInputBox, setMaxLengthForInputBox] = useState(15);

  const [audiodataURL, setAudiodataURL] = useState("");

  const [htmlData, setHtmlData] = useState("");

  const [pdfData, setPdfData] = useState("");
  const [newTypestage, setNewtypeStage] = useState("");

  const [flashCardList, setFlashCardList] = useState("");
  const [flashTestTospeech, setflashTestTospeech] = useState("");
  const [flashIMG, setFlashIMG] = useState("");
  const [flashaudio, setFlashaudio] = useState("");

  const [newflashcarddata, setNewflashcardData] = useState([]);

  const [submitButten, setSubmitButten] = useState(false);

  const [parsedCsvData, setParsedCsvData] = useState("");

  const [editdataitemForCheckFlag, setEditdataitemForCheckFlag] =
    useState(false);

  useEffect(() => {
    if (itemsArr) {
      setEditdataItemFlag(true);
      // console.log("itemsArr", itemsArr);
      // alert("sy");
      for (let i = 0; i < itemsArr.length; i++) {
        setEditdataItemFlag(true);

        setList((prev) =>
          prev.concat({
            id: itemsArr[i].itemId,
            name: itemsArr[i].itemName,

            type: itemsArr[i].itemType,
            audioURL: itemsArr[i].audioURL,
            flashCardList: itemsArr[i].flashCardList,
            audioLRCfile: itemsArr[i].audioLRCfile,
            htmlURL: itemsArr[i].htmlURL,
            pdfURL: itemsArr[i].pdfURL,
            videoThumbnail: itemsArr[i].videoThumbnail,
            videoURL: itemsArr[i].videoURL,
            itemDescription: itemsArr[i].itemDescription
              ? itemsArr[i].itemDescription
              : "",
          })
        );
      }
    }
  }, [itemsArr]);

  const handleInput = (event) => {
    const { value, name } = event.target;
    console.log(name, value);
    setItemDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const fileHandler = (event) => {
    const { files } = event.target;
    let reader = new FileReader();
    reader.onloadend = () => {
      setItemDetails((prev) => ({ ...prev, pdfBase64: reader.result }));
    };
    reader.readAsDataURL(files[0]);
  };

  function checkForm() {
    if (
      document.getElementById(stageId + unitId + levelId + "itemName").value ===
      ""
    )
      return "itemName";
    if (
      itemType !== "0" ||
      itemType !== "1" ||
      itemType !== "2" ||
      itemType !== "3" ||
      itemType !== "4"
    ) {
      return "allIsWell";
    }

    return "dfff";
  }

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    if (stageId != null) {
      // addStageItemOrder({
      //   stageId: stageId,
      //   stageItems: orderList,
      //   edit: true,
      // });

      let configProfile = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/addStageItemOrder`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          stageId: stageId,
          stageItems: orderList,
          edit: true,
        }),
      };

      axios(configProfile);
    } else if (levelId != null) {
      // addLevelItemOrder({
      //   levelId: levelId,
      //   levelItems: orderList,
      //   edit: true,
      // });

      let configProfile = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/addLevelItemOrder`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          levelId: levelId,
          levelItems: orderList,
          edit: true,
        }),
      };

      axios(configProfile);
    } else if (unitId != null) {
      let configProfile = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/addUnitItemOrder`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          unitId: unitId,
          unitItems: orderList,
          edit: true,
        }),
      };

      axios(configProfile);
    }
  }
  const toggleForm = (e) => {
    document.getElementById(
      stageId + unitId + levelId + "itemForm"
    ).style.display =
      document.getElementById(stageId + unitId + levelId + "itemForm").style
        .display == "none"
        ? "block"
        : "none";
  };

  const handleCourseImage = async (e, type) => {
    console.log(e);
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      console.log("rahul" + reader.result);

      if (type == "img") {
        setThumbnail(reader.result);
        setNewtypeStage(3);
        setFlashIMG("");
        setFlashaudio("");
        setAudiodataURL("");
        setPdfData("");
        setHtmlData("");
      } else if (type == "audio") {
        let ddd = reader.result;
        // setThumbnail(ddd)

        setAudiodataURL(ddd);

        setNewtypeStage(1);
        setThumbnail("");
        setFlashIMG("");
        setFlashaudio("");
        setPdfData("");
        setHtmlData("");
      } else if (type == "HTML") {
        setHtmlData("");
        setFlashIMG("");
        setFlashaudio("");
        setNewtypeStage(2);
        setThumbnail("");
        setAudiodataURL("");
        setPdfData("");
      } else if (type == "pdf") {
        setFlashIMG("");
        setFlashaudio("");
        setNewtypeStage(5);
        setPdfData(reader.result);
        setThumbnail("");
        setAudiodataURL("");

        setHtmlData("");
      } else if (type == "flashIMG") {
        setFlashIMG(reader.result);

        setNewtypeStage(4);
        setPdfData("");
        setThumbnail("");
        setAudiodataURL("");

        setHtmlData("");
      } else if (type == "flashAudio") {
        setFlashaudio(reader.result);
        setNewtypeStage(4);
        setPdfData("");
        setThumbnail("");
        setAudiodataURL("");

        setHtmlData("");
      }
      //setBase64Img(reader.result);
    };
  };

  const addFlashCradDataInArr = () => {
    setNewflashcardData([
      ...newflashcarddata,
      {
        imgURL: flashIMG,
        ttsText: flashTestTospeech,
        engText: itemDetails.engText,
        arabicText: itemDetails.arabicText,
        flashCardInstructionText: itemDetails.flashCardInstructionText,
      },
    ]);
    if (newTypestage == 4) {
    }
    console.log(newflashcarddata);
    setFlashListDataArr(newflashcarddata.length);
  };

  const addItem = async () => {
    let check = checkForm();
    if (check != "allIsWell") {
      setCheckFailed(true);
      return;
    }

    if (
      itemDetails.itemDescription.length < 35 ||
      itemDetails.itemDescription > 45
    ) {
      return;
    }

    setCheckFailed(false);
    console.log("called Add");
    docURL = [];
    files = [];
    const newList = list.concat({
      id: itemId,
      name: itemDetails.itemName,
      type: itemType,
      videoURL: itemDetails.videoLink,
      pdfURL: itemDetails.pdfLink,
      htmlURL: itemDetails.htmlLink,
      externalURL: itemDetails.externalLink,
      audioURL: itemDetails.audioLink,
      itemDescription: itemDetails.itemDescription,
    });

    let flashdata = [];
    if (newTypestage == 4) {
      console.log("i am new flash card data", newflashcarddata);
      flashdata.push(newflashcarddata);
    } else {
      flashdata = "";
    }

    if (editdataitemForCheckFlag) {
      let configProfile = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/item`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          itemId: itemId,
          courseAssociated: courseId,
          unitAssociated: unitId,
          levelAssociated: levelId,
          stageAssociated: stageId,
          audioLRCfile: parsedCsvData,
          externalURL: itemDetails.externalLink,
          htmlURL: htmlData,
          pdfURL: pdfData,
          audioURL: audiodataURL,
          videoURL: itemDetails.videoLink,
          itemName: itemDetails.itemName,
          itemType: itemType == "HTML" ? "2" : newTypestage,
          itemDescription: itemDetails.itemDescription,
          videoThumbnail: thumbnail,
          flashCardList: newflashcarddata,
          createDate: new Date(),
          lastUpdateDate: new Date(),
        }),
      };

      await axios(configProfile);
      setItemType("");
      setEditdataitemForCheckFlag(false);
      alert("itemUpdated");
    } else {
      let configProfile = {
        method: "post",
        // params: { userId: uid },
        url: `${IP}/courseBuilder/item`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          itemId: itemId,
          courseId: courseId,
          unitAssociated: unitId,
          levelAssociated: levelId,
          stageAssociated: stageId,
          audioLRCfile: parsedCsvData,
          externalURL: itemDetails.externalLink,
          htmlURL: htmlData,
          pdfURL: pdfData,
          audioURL: audiodataURL,
          videoURL: itemDetails.videoLink,
          itemName: itemDetails.itemName,
          itemType: itemType == "HTML" ? 2 : newTypestage,

          itemDescription: itemDetails.itemDescription,
          videoThumbnail: thumbnail,
          flashCardList: newflashcarddata,
          createDate: new Date(),
          lastUpdateDate: new Date(),
        }),
      };

      axios(configProfile)
        .then((res) => {
          console.log("inside the item data", res);
        })
        .catch((err) => console.log(err));
      setList(newList);
      saveOrder(newList);
      alert("itemaddeed");
    }

    setParsedCsvData("");
    setPdfData("");
    setThumbnail("");
    setAudiodataURL("");

    setHtmlData("");
    setThumbnail("");
    setItemDetails({
      itemName: "",
      pdfBase64: "",
      videoLink: "",
      htmlLink: "",
      externalLink: "",
      audioLink: "",
      itemDescription: "",
    });
    setItemId("");
    setItemType("pdf");

    toggleForm();
  };

  const upload = (pdf, i, link, referDoc) => {
    const metadata = {
      contentType: pdf.type,
    };
    const fileName = pdf.name;
    const storageRef = ref(
      storage,
      link + i + fileName.substring(fileName.lastIndexOf("."))
    );
    const uploadTask = uploadBytesResumable(storageRef, pdf, metadata);
    files.push(levelId + fileName.substring(fileName.lastIndexOf(".")));
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          docURL.push(downloadURL);
          referDoc.update({ documentURL: docURL });
        });
      }
    );
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  const handleLRCFileUpload = (item) => {
    console.log("rahul");

    let reader = new FileReader();
    reader.readAsDataURL(item.target.files[0]);

    reader.onload = function () {
      // console.log("rahul" + reader.result);
      setParsedCsvData(reader.result);
    };
    // setParsedCsvData([]);
    // Papa.parse(item.target.files[0], {
    //   header: false,
    //   complete: (results) => {
    //     // converarrtostring(parsedCsvData)

    //     let finalArr = [];
    //     for (let i = 0; i < results.data.length; i++) {
    //       if (results.data[i].length > 1) {
    //         let tempdata = `${results.data[i][0]}${results.data[i][1]}`;
    //         finalArr.push(tempdata);
    //       }
    //     }
    //     setParsedCsvData(finalArr);
    //   },
    // });
  };

  console.log(parsedCsvData);

  function converarrtostring(parsedCsvData) {}

  const hhhhhhh = () => {
    let arr = [];
    for (let i = 0; i < newflashcarddata.length; i++) {
      if (i == flagForIndex) {
        arr[i] = {
          imgURL: flashIMG ? flashIMG : newflashcarddata[i].imgURL,
          ttsText: flashTestTospeech,
          engText: itemDetails.engText,
          arabicText: itemDetails.arabicText,
          flashCardInstructionText: itemDetails.flashCardInstructionText,
        };
      } else {
        arr.push(newflashcarddata[i]);
      }
    }
    console.log(arr);
    setNewflashcardData(arr);
  };

  console.log(newflashcarddata);

  function editflash(item, index) {
    setFlagForIndex(index);
    // engText:itemDetails.engText,
    // arabicText:itemDetails.arabicText,
    // flashCardInstructionText:itemDetails.flashCardInstructionText
    setItemDetails((prev) => ({
      ...prev,
      engText: item.engText,
      arabicText: item.arabicText,
      flashCardInstructionText: item.flashCardInstructionText,
      flashimg: item.imgURL,
    }));
    setflashTestTospeech(item.ttsText);
  }

  function edit(item) {
    setEditable(true);
    setEditdataItemFlag(true);
    setEditdataitemForCheckFlag(true);
    // <option value="pdf">PDF</option>
    // <option value="video">Video</option>
    // <option value="HTML">HTML</option>
    // <option value="flashCard">FlashCard</option>
    // <option value="audio">audio</option>

    setItemName(item.name);
    setItemId(item.id);
    setVideoLink(item.videoURL);
    setExtLink(item.externalURL);
    setHTMLLink(item.htmlURL);
    setAudioLink(item.audioURL);
    setPDFLink(item.pdfURL);
    setNewflashcardData(item.flashCardList);

    // id: itemsArr[i].itemId,
    // name:itemsArr[i].itemName,

    // type: itemsArr[i].itemType,
    // audioURL:itemsArr[i].audioURL,
    // flashCardList:itemsArr[i].flashCardList,
    // audioLRCfile:itemsArr[i].audioLRCfile,
    // htmlURL:itemsArr[i].htmlURL,
    // pdfURL:itemsArr[i].pdfURL,
    // videoThumbnail:itemsArr[i].videoThumbnail,
    // videoURL:itemsArr[i].videoURL

    setItemDetails((prev) => ({
      ...prev,
      itemName: item.name,
      videoLink: item.videoURL,
      videothumbnail: item.videoThumbnail,
      htmlLink: item.htmlURL,
      audiolrc: item.audioLRCfile,
      audioLink: item.audioURL,
      pdfLink: item.pdfURL,
      itemDescription: item.itemDescription,
    }));

    document.getElementById(
      stageId + unitId + levelId + "itemForm"
    ).style.display = "";

    if (item.type == 1) {
      setItemType("audio");
      setNewtypeStage(1);
    } else if (item.type == 2) {
      setNewtypeStage(2);
      setItemType("HTML");
    } else if (item.type == 3) {
      setNewtypeStage(3);
      setItemType("video");
    } else if (item.type == 5) {
      setNewtypeStage(5);
      setItemType("pdf");
    } else if (item.type == 4) {
      setNewtypeStage(4);
      setItemType("flashCard");
    }
    // document.getElementById(stageId + unitId + levelId + "itemType").value =
    //   item.type;
    // document.getElementById(stageId + unitId + levelId + "itemName").value =
    //   item.name;
    // document.getElementById(stageId + unitId + levelId + "videoLink").value =
    //   item.videoURL;
    // document.getElementById(stageId + unitId + levelId + "htmlLink").value =
    //   item.htmlURL;
    // document.getElementById(stageId + unitId + levelId + "extLink").value =
    //   item.externalURL;
    // document.getElementById(stageId + unitId + levelId + "pdfLink").value =
    //   item.pdfURL;
    // document.getElementById(stageId + unitId + levelId + "audioLink").value =
    //   item.audioURL;
  }

  // const updateItem = () => {
  //   let check = checkForm();
  //   if (check != "allIsWell") {
  //     setCheckFailed(true);
  //     return;
  //   }

  //   docURL = [];
  //   files = [];

  //   for (let i = 0; i < list.length; i++) {
  //     if (itemId === list[i].id) {
  //       list[i] = {
  //         id: itemId,
  //         name: itemName,
  //         type: itemType,
  //         videoURL: videoLink,
  //         pdfURL: pdfLink,
  //         htmlURL: htmlLink,
  //         externalURL: extLink,
  //         audioURL: audioLink,
  //       };
  //     }
  //   }
  //   setList(list);

  //   document.getElementById(
  //     stageId + unitId + levelId + "itemForm"
  //   ).style.display = "none";
  //   setEditable(false);
  // };

  var lengthpro = true;
  if (
    itemDetails.itemDescription.length >= 35 &&
    itemDetails.itemDescription.length <= 45
  ) {
    lengthpro = false;
  }
  if (itemDetails.itemDescription.length == 0) {
    lengthpro = false;
  }
  async function del(item, index) {
    setDeleteQuestionId(item);
    setopenLessonPopUp(true);
    // let newArr = [...list];

    // deleteItem({ itemId: item.id });
    // newArr.splice(index, 1);
    // setList(newArr);
    // saveOrder(newArr);
  }

  const delfunction = async (question) => {
    // db.collection("question").doc(question.id).delete();

    let configCreateExtraInfo = {
      method: "delete",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/item`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        itemId: question.id,
      }),
    };
    await axios(configCreateExtraInfo);
    let newArr = list.filter((el) => el.id != question.id);

    setList(newArr);

    saveOrder(newArr);
    alert("Deleted ");
  };
  return (
    <>
      <section className="section" style={{ margin: "0px", width: "100%" }}>
        <DeleteLesson
          open={openLessonPopUp}
          onClose={() => setopenLessonPopUp(false)}
          delfun={(topic) => {
            delfunction(topic);
          }}
          topicId={deleteQuestionId}
        />
        <button
          className="addItem-btn"
          onClick={() => {
            setEditable(false);
            toggleForm();
            setEditdataItemFlag(false);
            setEditdataitemForCheckFlag(false);
          }}
        >
          Add Item
        </button>
        <div
          className="addItem-container"
          id={stageId + unitId + levelId + "itemForm"}
          style={{ display: "none" }}
        >
          <div className="addItem-heading">Item</div>
          <div className="addItem-row">
            <div className="addItem-inputLabel">Item Name</div>
            <input
              type="text"
              maxLength={maxLengthForInputBox}
              className="addItem-inputBar"
              id={stageId + unitId + levelId + "itemName"}
              name="itemName"
              value={itemDetails.itemName}
              onChange={(event) => {
                handleInput(event);
                if (!editable) {
                  let value = event.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();
                  setItemId(value + "_" + time);
                }
              }}
            />

            <div className="addItem-inputLabel">item Description </div>
            <div style={{ display: "flex" }}>
              <textarea
                name="itemDescription"
                value={itemDetails.itemDescription}
                type="text"
                maxLength={45}
                minLength={35}
                required={true}
                className="addItem-textarea"
                id={stageId + unitId + levelId + "itemDescription"}
                onChange={handleInput}
              />

              {lengthpro && (
                <p
                  style={{ color: "red", fontSize: "12px", marginLeft: "20%" }}
                >
                  lengtn should be between 35 to 45
                </p>
              )}
            </div>
          </div>
          <div className="addItem-row">
            <div className="addItem-inputLabel">Type :</div>
            {/* {JSON.stringify(itemType)} */}
            <select
              className="addItem-inputSelect"
              id={stageId + unitId + levelId + "itemType"}
              value={itemType}
              name="itemType"
              onChange={(e) => setItemType(e.target.value)}
            >
              <option value="pdf">PDF</option>
              <option value="video">Video</option>
              <option value="HTML">HTML</option>
              <option value="flashCard">FlashCard</option>
              <option value="audio">audio</option>
            </select>
          </div>
          {itemType}
          {itemType == "video" && (
            <div
              className="addItem-row"
              id={stageId + unitId + levelId + "videoLinkBlock"}
            >
              <div className="addItem-inputLabel">Video Link</div>

              <textarea
                name="videoLink"
                value={itemDetails.videoLink}
                type="text"
                className="addItem-textarea"
                id={stageId + unitId + levelId + "videoLink"}
                onChange={handleInput}
              />
              <br></br>
              <div></div>
              <div className="addItem-inputLabel">Video thumbnail</div>
              {editdataitemflag && (
                <input
                  name="videothumbnail"
                  value={itemDetails.videothumbnail}
                  type="text"
                  className="addItem-textarea"
                  id={stageId + unitId + levelId + "videoLink"}
                  // onChange={handleInput}
                  onChange={(e) => {
                    handleCourseImage(e, "img");
                  }}
                />
              )}
              <input
                name="videothumbnail"
                type="file"
                className="addItem-textarea"
                id={stageId + unitId + levelId + "videoLink"}
                // onChange={handleInput}
                onChange={(e) => {
                  handleCourseImage(e, "img");
                }}
              />
            </div>
          )}

          {itemType == "flashCard" && (
            // imgURL:imgres?imgres:"",
            //      ttsText:audiores?audiores:"",
            //      engText:data.flashCardList.engText?data.flashCardList.engText:"",
            //      arabicText:data.flashCardList.arabicText?data.flashCardList.arabicText:"",
            //      flashCardInstructionText:

            <div
              className="addItem-row"
              id={stageId + unitId + levelId + "videoLinkBlock"}
            >
              <div className="addItem-inputLabel">flash Card Instruction </div>
              <textarea
                name="flashCardInstructionText"
                value={itemDetails.flashCardInstructionText}
                type="text"
                className="addItem-textarea"
                id={stageId + unitId + levelId + "videoLink"}
                onChange={handleInput}
              />

              <div></div>
              <br />
              <div className="addItem">image URL</div>

              {editdataitemflag && (
                <input
                  name="flashCardimgURL"
                  value={itemDetails.flashimg}
                  type="text"
                  className="addItem-textarea"
                  id={stageId + unitId + levelId + "videoLink"}
                  // onChange={handleInput}
                />
              )}
              <input
                name="flashCardimgURL"
                type="file"
                className="addItem-textarea"
                id={stageId + unitId + levelId + "imgURL"}
                // onChange={handleInput}
                onChange={(e) => {
                  handleCourseImage(e, "flashIMG");
                }}
              />
              <br />

              <div
                className="addItem"
                id={stageId + unitId + levelId + "videoLinkB"}
              >
                <div className="addItem-">Text to speak</div>
                <br />
                <textarea
                  name="flashCardtts"
                  value={flashTestTospeech}
                  type="text"
                  className="addItem-textarea"
                  id={stageId + unitId + levelId + "tts"}
                  // onChange={handleInput}
                  onChange={(e) => {
                    setflashTestTospeech(e.target.value);
                  }}
                />
                <br />
                <br />
                <div className="addItem"> arabic Text</div>
                <br />
                <textarea
                  name="arabicText"
                  value={itemDetails.arabicText}
                  type="text"
                  className="addItem-textarea"
                  id={stageId + unitId + levelId + "arabicText"}
                  onChange={handleInput}
                />
              </div>
              <div
                className="addItem-row"
                id={stageId + unitId + levelId + "pdfLinkBlock"}
              >
                <div className="addItem-inputLabel"> english Text</div>
                <br />
                <textarea
                  name="engText"
                  value={itemDetails.engText}
                  type="text"
                  className="addItem-textarea"
                  id={stageId + unitId + levelId + "arabicText"}
                  onChange={handleInput}
                />
              </div>

              {editdataitemflag ? (
                <button
                  className="addItem-btn"
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    hhhhhhh();
                  }}
                >
                  update data in flashCard
                </button>
              ) : (
                <button
                  onClick={() => {
                    addFlashCradDataInArr();
                  }}
                  className="addItem-btn"
                  style={{ marginLeft: "auto" }}
                >
                  {" "}
                  Add data in flashCard
                </button>
              )}

              <div
                style={{
                  width: "60%",
                  height: "100px",
                  border: "13px solid #bed5cd",
                  overflowX: "scroll",
                  overflowY: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {newflashcarddata?.map((e, i) => (
                  <button
                    className="addItem-btn"
                    style={{
                      marginLeft: "auto",
                      display: "inline",
                      padding: "10px",
                    }}
                    onClick={() => {
                      editflash(e, i);
                    }}
                  >
                    {i}
                  </button>
                ))}
              </div>
            </div>
          )}

          {itemType == "pdf" && (
            <div
              className="addItem-row"
              id={stageId + unitId + levelId + "pdfLinkBlock"}
            >
              <div className="addItem-inputLabel">PDF Link</div>

              {editdataitemflag && (
                <textarea
                  type="text"
                  className="addItem-inputBar"
                  value={itemDetails.pdfLink}
                  name="htmlLink"
                  id={stageId + unitId + levelId + "htmlLink"}
                  //onChange={handleInput}
                />
              )}
              <input
                className="addItem-inputLabel"
                type="file"
                accept="application/pdf"
                // onChange={fileHandler}
                onChange={(e) => {
                  handleCourseImage(e, "pdf");
                }}
              />
            </div>
          )}
          {itemType == "external" && (
            <div
              className="addItem-row"
              id={stageId + unitId + levelId + "extLinkBlock"}
            >
              <div className="addItem-inputLabel">External Link</div>
              <textarea
                type="text"
                className="addItem-inputBar"
                value={itemDetails.externalLink}
                name="externalLink"
                id={stageId + unitId + levelId + "extLink"}
                onChange={handleInput}
              />
            </div>
          )}
          {itemType == "HTML" && (
            <div
              className="addItem-row"
              id={stageId + unitId + levelId + "htmlLinkBlock"}
            >
              <div className="addItem-inputLabel">HTML Link</div>

              <input
                type="text"
                className="addItem-inputBar"
                value={htmlLink}
                name="htmlLink"
                id={stageId + unitId + levelId + "htmlLink"}
                onChange={(e) => {
                  setItemDetails((prev) => ({
                    ...prev,
                    htmlLink: e.target.value,
                  }));
                  setHtmlData(e.target.value);
                  setHTMLLink(e.target.value);
                }}
              />
            </div>
          )}
          {itemType == "audio" && (
            <div
              className="addItem-row"
              id={stageId + unitId + levelId + "audioLinkBlock"}
            >
              <div className="addItem-inputLabel">Audio file</div>

              {editdataitemflag && (
                <input
                  type="text"
                  className="addItem-inputBar"
                  value={itemDetails.audioLink}
                  name="audioLink"
                  id={stageId + unitId + levelId + "htmlLink"}
                  //onChange={handleInput}
                />
              )}
              <input
                type="file"
                className="addItem-inputBar"
                name="audioLink"
                id={stageId + unitId + levelId + "audioLink"}
                // onChange={handleInput}
                onChange={(e) => {
                  handleCourseImage(e, "audio");
                }}
              />
              <br />
              <div className="addItem-inputLabel">Audio LRC file</div>
              {editdataitemflag && (
                <textarea
                  type="text"
                  className="addItem-inputBar"
                  value={itemDetails.audiolrc}
                  name="htmlLink"
                  id={stageId + unitId + levelId + "htmlLink"}
                  // onChange={handleInput}
                />
              )}
              <input
                type="file"
                className="addItem-inputBar"
                name="audioLRC"
                id={stageId + unitId + levelId + "audioLRC"}
                // onChange={handleInput}
                onChange={(e) => {
                  handleLRCFileUpload(e);
                }}
              />
            </div>
          )}
          {checkFailed && <p>Fill all the fields.</p>}
          <div className="addItem-row">
            <button
              className="addItem-btn"
              style={{ marginLeft: "auto" }}
              onClick={addItem}
            >
              Save Item
            </button>
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                id="itemList"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  listStyleType: "none",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {list.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={item.id}
                        className="draggableItem-li"
                      >
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreSharp />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              backgroundColor: "#004577",
                              color: "white",
                              borderRadius: "5px",
                            }}
                          >
                            <Typography>{item.name}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div onClick={() => del(item, index)}>
                              <MdDelete
                                style={{ float: "right", margin: "20px" }}
                                size={28}
                              />
                            </div>

                            {/* <MdEdit
                              style={{ float: "right", margin: "20px" }}
                              size={28}
                              onClick={() => edit(item)}
                            /> */}
                            <p>Id- {item.id}</p>
                            <p>name-{item.name}</p>
                            {stageId != "" ? (
                              <p>Associated Topic- {stageId}</p>
                            ) : (
                              <></>
                            )}
                            {unitId != "" ? (
                              <p>Associated Course- {unitId}</p>
                            ) : (
                              <></>
                            )}
                            {levelId != "" ? (
                              <p>Associated Lesson- {levelId}</p>
                            ) : (
                              <></>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>
    </>
  );
};

export default Item;
