import React, { useEffect, useState } from "react";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadBytes,
  deleteObject,
} from "@firebase/storage";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import "./createQuiz.css";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";

const CreateQuiz = ({ questionPoolId ,editData }) => {
  const storage = getStorage();

  const createQuestion = httpsCallable(functions, "createQuestion");
  const updateQustionPool = httpsCallable(functions, "updateQustionPool");

  const getQuestionPoolInfo=httpsCallable(functions,"getQuestionPoolInfo")
  const updateQuestion=httpsCallable(functions,"updateQuestion")

  //addQuestionSetOrder
  // added by Himanshu


  console.log("questionPoolId",questionPoolId)

 useState(()=>{
  console.log("questionPoolId",questionPoolId)
  if(editData){

  //  questionData=[]
    // courseAssociated: ""
    // creationDate: {seconds: 1659093486, nanoseconds: 670000000}
    // hint: ""
    // lastUpdatedDate: {_seconds: 1659093495, _nanoseconds: 380000000}
    // levelAssociated: ""
    // messageAfterCorrectSubmit: ""
    // messageAfterWrongSubmit: ""
    // questionData: {questionInstruction: 'dgdg', RHS: Array(3), question: 'gfdgdg', LHS: Array(3)}
    // questionId: "newques_1659093472213"
    // questionInstruction: ""
    // questionName: "newques"
    // questionPoolAssociated: "NEWDATA_1659093462510"
    // questionType: 3
    // scheduledDateOfRelease: ""
    // stageAssociated: ""
    // unitAssociated: ""


    getQuestionPoolInfo({"questionPoolId":questionPoolId}).then((res)=>{
      
       let qdata=res.data.questionData
    
     for(let i=0;i<qdata.length;i++){

      console.log(qdata)
      setList(prev=>prev.concat({

     
      
      
        messageAfterCorrectSubmit: qdata[i].messageAfterWrongSubmit,
        messageAfterWrongSubmit: qdata[i].messageAfterWrongSubmit,
        questionData:qdata[i].questionData,
       
          id:qdata[i].questionId,
      
      
        name: qdata[i].questionName,
       
        type: qdata[i].questionType,
    

      }))

    console.log("i am question list ",list)

     }

    })

    

  }
 },[editData])
 



  const [picMCQ, setPicMCQ] = useState({
    url: "",
    base64: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: 0,
  });

  function onPicMCQChange(e) {
    const { value, name, files } = e.target;
    setPicMCQ((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  function setPicMCQFile(e) {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPicMCQ((prev) => ({ ...prev, base64: reader.result }));
      console.log("setBase64Image done", reader.result);
    };
    reader.readAsDataURL(files[0]);

    setPicMCQ((prev) => ({ ...prev, url: url }));
  }

  function uploadPicMCQ(file) {
    if (!file) return;
    let time = JSON.stringify(new Date().getTime());
    const storageref = ref(storage, `/files/${file.name + "_" + time}`);
    const uploadTask = uploadBytesResumable(storageref, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // can set progress.
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) =>
          setPicMCQ((prev) => ({ ...prev, url: url }))
        );
      }
    );
  }

  const [fillUpsAns, setFillUpsAns] = useState("");
  // rearrange
  const [editdataForm,setEditdataForm]=useState(false)
  const [canEditRearrange, setCanEditRearrange] = useState(true);
  const [rearrangeOptions, setRearrangeOptions] = useState([
    {
      text: "First",
      position: 1,
    },
    {
      text: "Second",
      position: 2,
    },
    {
      text: "Third",
      position: 3,
    },
    {
      text: "Fourth",
      position: 4,
    },
  ]);
  /* arr element example
    {
      text:"rearrange",
      position: 1
    }
  */
  const handleRearrange = (e, order) => {
    console.log("onChange");
    if (!canEditRearrange) return;
    setRearrangeOptions((prev) => {
      let item = {
        text: e.target.value,
        position: order,
      };
      let res = Array.from(prev);
      res[order - 1] = item;
      return res;
    });
  };
  const handleScramble = () => {
    setCanEditRearrange(false);
    setRearrangeOptions((prev) => {
      let res = scrambleArr(prev);
      console.log(res);
      return res;
    });
  };
  const scrambleArr = (arr) => {
    console.log(arr[0]);
    let res = Array.from(arr);
    return res.sort((a, b) => 0.5 - Math.random());
  };

  const [canEditMatchPair, setCanEditMatchPair] = useState(true);
  const [leftOptions, setLeftOptions] = useState(new Array(3).fill(""));
  const [rightOptions, setRightOptions] = useState([
    {
      text: "",
      match: 1,
    },
    {
      text: "",
      match: 2,
    },
    {
      text: "",
      match: 3,
    },
   
  ]);

  const handleMatchPair = (e, order, side) => {
    console.log("onChange");
    if (!canEditMatchPair) return;
    if (side == "left") {
      setLeftOptions((prev) => {
        let item = e.target.value;

        let res = Array.from(prev);
        res[order - 1] = item;
        return res;
      });
    } else {
      setRightOptions((prev) => {
        let item = {
          text: e.target.value,
          match: order,
        };
        let res = Array.from(prev);
        res[order - 1] = item;
        return res;
      });
    }
  };

  const handleMatchUpScramble = () => {
    setCanEditMatchPair(false);
    setRightOptions((prev) => {
      let res = scrambleArr(prev);
      console.log(res);
      return res;
    });
  };

  // match words to image

  const [imageOptions, setImageOptions] = useState(
    new Array(3).fill("options")
  );
  const [imageFiles, setImageFiles] = useState([
    {
      base64: "",
      match: 1,
      url: "",
    },
    {
      base64: "",
      match: 2,
      url: "",
    },
    {
      base64: "",
      match: 3,
      url: "",
    },
   
  ]);
  const [disableImage, setDisableImage] = useState(false);
  const [uploading, setUploading] = useState(false);
  const handleOptionsChange = (e, index) => {
    let { value } = e.target;
    setImageOptions((prev) => {
      let newArr = Array.from(prev);
      newArr[index] = value;
      return newArr;
    });
  };
  const handleImageChange = (e, index) => {
    let { files } = e.target;
    let reader = new FileReader();
    reader.onloadend = () => {
      setImageFiles((prev) => {
        let newArr = Array.from(prev);
        let item = {
          match: index + 1,
          url: URL.createObjectURL(files[0]),
          base64: reader.result,
        };
        newArr[index] = item;
        return newArr;
      });
      console.log("Image converted ", index);
    };
    reader.readAsDataURL(files[0]);
  };

  const scrambleImage = () => {
    setDisableImage(true);
    setImageFiles((prev) => scrambleArr(prev));
  };

  async function uplaodMatchImage(file) {
    if (!file) return;
    let resURL = "";
    let time = JSON.stringify(new Date().getTime());
    const storageref = ref(storage, `/files/${time + "_" + file.name}`);
    const uploadTask = await uploadBytes(storageref, file);
    resURL = await getDownloadURL(storageref);
    console.log(resURL);
    return resURL;
  }

  const [imageMcq, setImageMcq] = useState([
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
   
  ]);
  const [imageMcqAnswer, setImageMcqAnswer] = useState(0);

  const handleImageMcqInput = (e, index) => {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageMcq((prev) => {
        let newArr = Array.from(prev);
        newArr[index].base64 = reader.result;
        return newArr;
      });
      console.log("setBase64Image done", reader.result);
    };
    reader.readAsDataURL(files[0]);

    setImageMcq((prev) => {
      let newArr = Array.from(prev);
      newArr[index].blobUrl = url;
      return newArr;
    });
  };

  //

  const [qusMode, setqusMode] = useState("");
  const [qustype, setqustype] = useState("MCQ");
  const [qus, setqus] = useState("");
  const [qusInstruction, setqusInstruction] = useState("");
  const [PointorScore, setPointorScore] = useState("");
  const [qus5, setqus5] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4:""  
  });
  const [qus5Answer, setqus5Answer] = useState("0");
  const [qus2, setqus2] = useState({ word: "", correctImage: "" });
  const [progress1, setprogress1] = useState(0);
  const [progress2, setprogress2] = useState(0);
  const [progress3, setprogress3] = useState(0);
  const [progress4, setprogress4] = useState(0);
  const [url1, seturl1] = useState("");
  const [url2, seturl2] = useState("");
  const [url3, seturl3] = useState("");
  const [url4, seturl4] = useState("");
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [file1, setfile1] = useState(null);
  const [file2, setfile2] = useState(null);
  const [file3, setfile3] = useState(null);
  const [file4, setfile4] = useState(null);
  const [users, setusers] = useState([]);
  const [users1, setusers1] = useState([]);
  const [users2, setusers2] = useState([]);
  const [users3, setusers3] = useState([]);
  const [users4, setusers4] = useState([]);
  const [users5, setusers5] = useState([]);
  const [rootvalue, setrootvalue] = useState("");
  const [rootName, setrootName] = useState([]);
  const [lessonName, setlessonName] = useState([]);
  const [topicName, settopicName] = useState([]);
  const [quizName, setquizName] = useState([]);
  const [lessonName1, setlessonName1] = useState([]);
  const [topicName1, settopicName1] = useState([]);
  const [quizName1, setquizName1] = useState([]);
  const [poolName1, setpoolName1] = useState([]);
  const [userlessonname, setuserlessonname] = useState("");
  const [usertopicname, setusertopicname] = useState("");
  const [userLessonType, setuserLessonType] = useState("");
  const [dictionarybubbledisabled, setdictionarybubbledisabled] = useState(
    false
  );

  var [lesson, setlesson] = useState([
    {
      value: "Courses",
      label: "Lesson",
      isDisabled: false,
    },
    {
      value: "Objective",
      label: "Objective",
      isDisabled: false,
    },
    {
      value: "Arcade",
      label: "Arcade",
      isDisabled: false,
    },
  ]);
  var [topic, settopic] = useState([
    {
      value: "Lessons",
      label: "Topic",
      isDisabled: false,
    },
    {
      value: "Round",
      label: "Round",
      isDisabled: false,
    },
  ]);

  const confirm = () => {
    setrootName([]);
    console.log(
      users.map((user) => {
        const query = { value: user.courseId, label: user.courseId };
        setrootName((rootName) => rootName.concat(query));
      })
    );
  };
  useEffect(() => {
    if (rootvalue == "Courses" || rootvalue == "Grammar") {
      setlesson((prev) => {
        return prev.filter((curvalue, idx) => {
          if (idx == 1 || idx == 2) {
            curvalue.isDisabled = true;
          } else {
            curvalue.isDisabled = false;
          }
          return curvalue;
        });
      });
      settopic((prev) => {
        return prev.filter((curvalue, idx) => {
          if (idx == 1) {
            curvalue.isDisabled = true;
          } else {
            curvalue.isDisabled = false;
          }
          return curvalue;
        });
      });
      setqusMode("QuizMode");
      setPointorScore("Score");
      setdictionarybubbledisabled(false);
    } else if (rootvalue == "Practice") {
      setlesson((prev) => {
        return prev.filter((curvalue, idx) => {
          if (idx == 0) {
            curvalue.isDisabled = true;
          } else {
            curvalue.isDisabled = false;
          }
          return curvalue;
        });
      });
      settopic((prev) => {
        return prev.filter((curvalue, idx) => {
          if (idx == 0) {
            curvalue.isDisabled = true;
          } else {
            curvalue.isDisabled = false;
          }
          return curvalue;
        });
      });
    } else if (rootvalue == "Test") {
      setlesson((prev) => {
        return prev.filter((curvalue, idx) => {
          if (idx == 0 || idx == 1 || idx == 2) {
            curvalue.isDisabled = true;
          } else {
            curvalue.isDisabled = false;
          }
          return curvalue;
        });
      });
      settopic((prev) => {
        return prev.filter((curvalue, idx) => {
          if (idx == 0 || idx == 1) {
            curvalue.isDisabled = true;
          } else {
            curvalue.isDisabled = false;
          }
          return curvalue;
        });
      });
      setqusMode("QuizMode");
      setPointorScore("Score");
      setdictionarybubbledisabled(false);
    } else if (rootvalue == "video") {
      setlesson((prev) => {
        return prev.filter((curvalue, idx) => {
          if (idx == 0 || idx == 1 || idx == 2) {
            curvalue.isDisabled = true;
          } else {
            curvalue.isDisabled = false;
          }
          return curvalue;
        });
      });
      settopic((prev) => {
        return prev.filter((curvalue, idx) => {
          if (idx == 0 || idx == 1) {
            curvalue.isDisabled = true;
          } else {
            curvalue.isDisabled = false;
          }
          return curvalue;
        });
      });
    }
  }, [rootvalue]);
  useEffect(() => {
    confirm();
  }, [users]);

  useEffect(() => {
    getlessonName();
  }, [users1]);
  useEffect(() => {
    getTopicName();
  }, [users2]);
  useEffect(() => {
    quizsoln();
  }, [users3]);

  const getlessonName = () => {
    if (userLessonType == "Arcade") {
      if (users1.courseFinalArcade != undefined) {
        setlessonName1([]);
        setlessonName1([
          { value: users1.courseFinalArcade, label: users1.courseFinalArcade },
        ]);
      }
    } else {
      setlessonName(users1.courseLessons);
      if (lessonName != undefined) {
        setlessonName1([]);
        console.log(
          lessonName.map((user) => {
            const query = { value: user, label: user };
            setlessonName1((lessonName1) => lessonName1.concat(query));
          })
        );
      }
    }
  };
  const getTopicName = () => {
    settopicName(users2.lessonTopics);
    if (topicName != undefined) {
      settopicName1([]);
      console.log(
        topicName.map((user) => {
          const query = { value: user, label: user };
          settopicName1((topicName1) => topicName1.concat(query));
        })
      );
    }
  };

  const quizsoln = () => {
    setquizName1([]);
    if (
      rootvalue == "Courses" ||
      rootvalue == "Grammar" ||
      rootvalue == "video" ||
      rootvalue == "Test" ||
      rootvalue == "Practice"
    ) {
      if (usertopicname != "" || rootvalue == "Practice") {
        setquizName(users3.topicQuiz);
        console.log(
          quizName.map((user) => {
            const query = { value: user, label: user };
            setquizName1((quizName1) => quizName1.concat(query));
          })
        );
      } else if (usertopicname == "" && userlessonname != "") {
        setquizName(users3.lessonQuiz);
        console.log(
          quizName.map((user) => {
            const query = { value: user, label: user };
            setquizName1((quizName1) => quizName1.concat(query));
          })
        );
      } else if (usertopicname == "" && userlessonname == "") {
        setquizName(users3.courseFinalQuiz);
        const query = { value: quizName, label: quizName };
        setquizName1((quizName1) => quizName1.concat(query));
      }
    }
  };
  function submit(e) {
    e.preventDefault();
  }

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    ////////
    updateQustionPool({
      questionPoolId: questionPoolId,
      questionarr: orderList,
      edit: true,
    });



    /////////////////////////////////////
    // db.collection("quiz")
    //   .doc(questionPoolId)
    //   .collection("extraInfo")
    //   .doc("infoDoc")
    //   .update({
    //     questions: orderList,
    //   });
    // db.collection("questionSet")
    //   .doc(questionPoolId)
    //   .collection("extraInfo")
    //   .doc("infoDoc")
    //   .update({
    //     questions: orderList,
    //   });
  }

  const storedDataOnFirebase = async () => {
    let questionTypeEnum;
    let managequestionData;
    setDisableImage(false);
    setCanEditMatchPair(true)
    if (qustype == "MCQ") {
      let setoptionFromuser;
      if (qus5Answer == "Option 1") {
        let optionsFromUser = [
          { iscorrect: true, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 2") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: true, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 3") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: true, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 4") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: true, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      }
      let questionDatafromUser = {
        options: setoptionFromuser,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;

      questionTypeEnum = 0;
    } else if (qustype == "Choose the correct sequence") {
      if (canEditRearrange) return; // didn't scramble before submit
      const myArray = Array.from(rearrangeOptions);
      let questionDatafromUser = {
        options: myArray,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 1;
    } else if (qustype == "Picture MCQ") {
      let optionsFromUser = [
        {
          iscorrect: Boolean(picMCQ.answer === "0"),
          option: picMCQ.option1,
        },
        {
          iscorrect: Boolean(picMCQ.answer === "1"),
          option: picMCQ.option2,
        },
        {
          iscorrect: Boolean(picMCQ.answer === "2"),
          option: picMCQ.option3,
        },
       
      ];
      let questionDatafromUser = {
        options: optionsFromUser,
        base64: picMCQ.base64,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      // mcq with one pic
    } else if (qustype == "Choose the correct image") {
      let imageOptionFromUser = imageMcq.map((val) => val.base64);
      let questionDatafromUser = {
        correctImage: imageMcqAnswer,
        imageOptions: imageOptionFromUser,
        question: qus,
        questionInstruction: qusInstruction,
        word: qus2.word,
      };
      managequestionData = questionDatafromUser;
  
    } else if (qustype == "Fill in the blank") {
      const myAnswer = fillUpsAns.split(",");
      let questionDatafromUser = {
        answer: myAnswer,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 2;
    } else if (qustype == "Match the pair") {
      if (canEditMatchPair) return;

      let questionDatafromUser = {
        LHS: leftOptions,
        RHS: rightOptions,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 3;
    }
    
    
    
    
    
    
    else if (qustype == "Match the pairs picture based") {

  
      if (!disableImage) return;
      let imageArr = [];

      //     hffjffhf
      console.log( "rahul"+ imageFiles)


      imageFiles.forEach((item) => {
        let ele = {
          base64: item.base64,
          match: item.match,
        };
        imageArr.push(ele);
      });

      let questionDatafromUser = {
        textOptions: imageOptions,
        imageOptions: imageArr,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 1;
    }

    else if (qustype == "Match the pairs audio based") {

  
      if (!disableImage) return;
      let imageArr = [];

      //     hffjffhf
      console.log( "rahul"+ imageFiles)


      imageFiles.forEach((item) => {
        let ele = {
          base64: item.base64,
          match: item.match,
        };
        imageArr.push(ele);
      });

      let questionDatafromUser = {
        textOptions: imageOptions,
        imageOptions: imageArr,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 4;
    }
 


    const docData = {
      questionName: questionName,
      questionId: questionId,
      associatedQuiz: questionPoolId,
      questionSet: questionPoolId,
      questionData: managequestionData,
      questionType: qustype,
      created: Timestamp.now(),
    };
    console.log(  "rahul"+ docData);
    console.log(managequestionData)
console.log("data"+ managequestionData);
    ///    
  //   questionPoolAssociated:data.questionPoolAssociated?data.questionPoolAssociated:"",	
 
  //   questionName:data.questionName?data.questionName:"",
  //   questionType:data.questionType?data.questionType:0,
  //  // questionData:data.questionData?qdata:{data:[]},
  //   questionId:data.questionId?data.questionId:"",
  //   hint:data.hint?data.hint:"",
  //   messageAfterCorrectSubmit:data.messageAfterCorrectSubmit?data.messageAfterCorrectSubmit:"",	
  //   messageAfterWrongSubmit:data.messageAfterWrongSubmit?data.messageAfterWrongSubmit:"",	
  //   questionInstruction:data.questionInstruction?data.questionInstruction:"",
  //   levelAssociated:data.levelAssociated?data.levelAssociated:"",
  //   unitAssociated:data.unitAssociated?data.unitAssociated:"",
  //   courseAssociated:data.courseAssociated?data.courseAssociated:"",	
  //   stageAssociated:data.stageAssociated?data.stageAssociated:"",
    
  //  creationDate:data.creationDate,
  //    lastUpdatedDate:new Date(),
  //     scheduledDateOfRelease:data.scheduledDateOfRelease?data.scheduledDateOfRelease:""

  if(editdataForm){
    updateQuestion({
      questionId: questionId,
      questionName: questionName,
      questionType: questionTypeEnum,

      lastUpdateTime:Timestamp.fromDate(new Date()),
      questionData: managequestionData,
     
    })
  }else{


    createQuestion({
      questionId: questionId,
      questionName: questionName,
      questionType: questionTypeEnum,

      creationDate: Timestamp.fromDate(new Date()),
      questionData: managequestionData,
      questionPoolAssociated:questionPoolId,
    });
    const newList = list.concat({
      id: questionId,
      name: questionName,
      type: qustype,
    });
    setList(newList);
    saveOrder(newList);
  }

    // await setDoc(doc(db, "question", questionId), docData);
    // const washingtonRef = doc(
    //   db.collection("quiz").doc(questionPoolId),
    //   "extraInfo",
    //   "infoDoc"
    // );
    // if (qustype == "MCQ") {
    //   await updateDoc(washingtonRef, {
    //     questionsId: arrayUnion(questionId),
    //     availQuestionsForMcq: increment(1),
    //     availQuestionsInPool: increment(1),
    //   });
    // } else if (qustype == "Choose the correct sequence") {
    //   await updateDoc(washingtonRef, {
    //     questionsId: arrayUnion(questionId),
    //     availQuestionsForFillTheSequence: increment(1),
    //     availQuestionsInPool: increment(1),
    //   });
    // } else if (qustype == "Picture MCQ") {
    //   await updateDoc(washingtonRef, {
    //     questionsId: arrayUnion(questionId),
    //     availQuestionsForIdentifyTheImage: increment(1),
    //     availQuestionsInPool: increment(1),
    //   });
    // } else if (qustype == "Choose the correct image") {
    //   await updateDoc(washingtonRef, {
    //     questionsId: arrayUnion(questionId),
    //     availQuestionsForCorrectImageFromWord: increment(1),
    //     availQuestionsInPool: increment(1),
    //   });
    // } else if (qustype == "Fill in the blank") {
    //   await updateDoc(washingtonRef, {
    //     questionsId: arrayUnion(questionId),
    //     availQuestionsForFillTheBlanksType: increment(1),
    //     availQuestionsInPool: increment(1),
    //   });
    // } else if (qustype == "Match the pair") {
    //   await updateDoc(washingtonRef, {
    //     questionsId: arrayUnion(questionId),
    //     availQuestionsForWordsToWords: increment(1),
    //     availQuestionsInPool: increment(1),
    //   });
    // } else if (qustype == "Match the pairs picture based") {
    //   await updateDoc(washingtonRef, {
    //     questionsId: arrayUnion(questionId),
    //     availQuestionsForWordsToImage: increment(1),
    //     availQuestionsInPool: increment(1),
    //   });
    // }

    alert("data saved successfully");
   
  
    document.getElementById(questionPoolId + "question-form").reset();
    document.getElementById(questionPoolId + "questionName").value = "";
    toggleQuestionForm();
  };

  const [questionName, setQuestionName] = useState("");
  const [questionId, setQuestionId] = useState("");

  const initialList = [];
  const [list, setList] = useState(initialList);

  const toggleQuestionForm = (e) => {
    document.getElementById(questionPoolId + "questionForm").style.display =
      document.getElementById(questionPoolId + "questionForm").style.display == "none"
        ? "block"
        : "none";
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(lesson) {
    setEditdataForm(true)
   

    setQuestionName(lesson.name)
    setqus(lesson.questionData.question)
    setQuestionId(lesson.id)
    setqusInstruction(lesson.questionData.questionInstruction)

console.log("i ma question less",lesson)
    // question: "hghghghj"
    // questionInstruction: "ihoioihoih"
if(lesson.type==0){
  setqustype("MCQ")
    setqus5((prev)=>({...prev,option1:lesson.questionData.options[0].option,
      option2:lesson.questionData.options[1].option,
      option3:lesson.questionData.options[2].option,
      option4:lesson.questionData.options[3].option})
             
         
      )
console.log("set ques",qus5)

  


      for(let i=0;i<4;i++){
        if(lesson.questionData.options[i].iscorrect){
          setqus5Answer(`Option ${i+1}`)
        }
      }
    }
    else if(lesson.type==2){

      setqustype("Fill in the blank");
      let ans="";
      for(let i=0;i<lesson.questionData.answer.length;i++){
        if(i==lesson.questionData.answer.length-1){
        ans=ans+lesson.questionData.answer[i]
        }else{
          ans=ans+lesson.questionData.answer[i]+","
        }
      }
      setFillUpsAns(ans)



    }
    else if(lesson.type==3){
      setqustype("Match the pair");
      setLeftOptions(lesson.questionData.LHS)
    
      setRightOptions(lesson.questionData.RHS)
    }




      toggleQuestionForm();
  }
  async function del(question) {



    // db.collection("question").doc(question.id).delete();

    // alert("Deleted " + question.name);
    // const item1 = [];
    // for (let i = 0; i < list.length; i++) {
    //   if (question.id !== list[i].id) {
    //     item1.push(list[i]);
    //   }
    // }
    // setList(item1);
    // saveOrder(item1);
  }
  return (
    <section className="quizForm-container">
      <button className="quizFrom-button" onClick={()=>{toggleQuestionForm() ;  setEditdataForm(false)}}>
        Add Question
      </button>
      <div
        className="quizFrom-mainFrom"
        id={questionPoolId + "questionForm"}
        style={{ display: "none" }}
      >
        <div className="quizFrom-mainFrom-head">Question Form</div>

        <form id={questionPoolId + "question-form"} onSubmit={submit}>
          <div className="quizForm-mainForm-infoRow">
            <p className="quizForm-mainForm-label">Question Name</p>
            <input
              type="text"
              className="quizForm-mainForm-inputBar"
              id={questionPoolId + "questionName"}
              value={questionName}
              onChange={(e) => {
                setQuestionName(e.target.value);

                let value = e.target.value.replaceAll(" ", "");
                let time = new Date().getTime();

                if(!editdataForm){setQuestionId(value + "_" + time)}
       
              }}
            />
          </div>
          <div className="quizForm-mainForm-infoRow">
            <label className="quizForm-mainForm-label">
              Type of question :{" "}
            </label>
            <select
              className="quizForm-mainForm-select"
              value={qustype}
              onChange={(event) => {
                setqustype(event.target.value);
              }}
            >
              <option value="MCQ">Multiple Choice Question</option>
              <option value="Choose the correct sequence">
                Choose the correct sequence
              </option>
              <option value="Match the pair">Match the pair</option>
              <option value="Fill in the blank">Fill in the blank</option>
              <option value="Picture MCQ">Picture MCQ</option>
              <option value="Match the pairs picture based">
                Pairing (word to images)
              </option>
              <option value="Choose the correct image">
                Choose the correct image
              </option>
              <option value="Match the pairs audio based">
              Match the pairs audio based
              </option>

            </select>
          </div>
          <div className="quizForm-mainForm-infoRow">
            <label className="quizForm-mainForm-label">Question : </label>
            <textarea
              className="quizForm-mainForm-textarea"
              value={qus}
              onChange={(event) => {
                setqus(event.target.value);
              }}
            ></textarea>
          </div>
          <div className="quizForm-mainForm-infoRow">
            <label className="quizForm-mainForm-label">Instruction : </label>
            <textarea
              className="quizForm-mainForm-textarea"
              value={qusInstruction}
              onChange={(event) => {
                setqusInstruction(event.target.value);
              }}
            ></textarea>
          </div>
          {qustype == "Choose the correct sequence" && (
            <>
              <label className="quizForm-sequence-label">
                Options (Enter the options in sequence)
              </label>
              <div className="quizForm-sequence-options">
                {rearrangeOptions &&
                  rearrangeOptions.map((item, id) => (
                    <div key={id}>
                      <input
                        className="quizForm-mainForm-inputBar"
                        style={{ marginBottom: "1em", textAlign: "center" }}
                        disabled={canEditRearrange ? "" : "disabled"}
                        value={item.text}
                        onChange={(e) => handleRearrange(e, id + 1)}
                      />

                      <br />
                    </div>
                  ))}
                <button className="quizFrom-button" onClick={handleScramble}>
                  Scramble
                </button>
              </div>
            </>
          )}
          {qustype == "Fill in the blank" ? (
            <>
              <label className="quizForm-sequence-label">
                Note: '_' (under score) is treated as blank in the Question.
                Seprate the answers with a comma.
              </label>
              <div className="quizForm-mainForm-infoRow">
                <label className="quizForm-mainForm-label">Answers : </label>
                <textarea
                  type="text"
                  placeholder="Answer 1, Answer 2"
                  className="quizForm-mainForm-textarea"
                  value={fillUpsAns}
                  onChange={(e) => setFillUpsAns(e.target.value)}
                />
              </div>
            </>
          ) : (
            " "
          )}
          {qustype == "MCQ" ? (
            <>
              <div className="quizForm-mainForm-infoRow">
                <label className="quizForm-mainForm-label">Option 1 : </label>
                <input
                  className="quizForm-mainForm-inputBar"
                  name="text"
                  value={qus5.option1}
                  placeholder="Enter Option"
                  onChange={(event) => {
                    setqus5({ ...qus5, option1: event.target.value });
                  }}
                />
              </div>
              <div className="quizForm-mainForm-infoRow">
                <label className="quizForm-mainForm-label">Option 2 : </label>
                <input
                  className="quizForm-mainForm-inputBar"
                  name="text"
                  placeholder="Enter Option"
                  value={qus5.option2}
                  onChange={(event) => {
                    setqus5({ ...qus5, option2: event.target.value });
                  }}
                />
              </div>
              <div className="quizForm-mainForm-infoRow">
                <label className="quizForm-mainForm-label">Option 3 : </label>
                <input
                  className="quizForm-mainForm-inputBar"
                  name="text"
                  placeholder="Enter Option"
                  value={qus5.option3}
                  onChange={(event) => {
                    setqus5({ ...qus5, option3: event.target.value });
                  }}
                />
              </div>
              <div className="quizForm-mainForm-infoRow">
                <label className="quizForm-mainForm-label">Option 4 : </label>
                <input
                  className="quizForm-mainForm-inputBar"
                  type="text"
                  placeholder="Enter Option"
                  value={qus5.option4}
                  onChange={(event) => {
                    setqus5({ ...qus5, option4: event.target.value });
                  }}
                />
              </div>
              <div className="quizForm-mainForm-infoRow">
                <label className="quizForm-mainForm-label">
                  {" "}
                  Choose the correct Option :{" "}
                </label>
                <select
                  className="quizForm-mainForm-select"
                  value={qus5Answer}
                  onChange={(event) => {
                    setqus5Answer(event.target.value);
                  }}
                >
                  <option value="Option 1">Option 1</option>
                  <option value="Option 2">Option 2</option>
                  <option value="Option 3">Option 3</option>
                  <option value="Option 4">Option 4</option>
                </select>
              </div>
            </>
          ) : (
            " "
          )}
          {qustype === "Match the pair" ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    marginLeft: "1em",
                  }}
                >
                  {leftOptions.map((item, index) => (
                    <>
                      <p className="quizForm-matchUp-label">
                        Option {index + 1}:{" "}
                      </p>
                      <input
                        disabled={canEditMatchPair ? "" : "disabled"}
                        className="quizForm-matchUp-inputBar"
                        value={item}
                        placeholder={"Left option " + (index + 1)}
                        onChange={(e) => handleMatchPair(e, index + 1, "left")}
                      />
                      <br />
                    </>
                  ))}
                </div>

                <div>
                  {rightOptions.map((item, index) => (
                    <>
                      <input
                        disabled={canEditMatchPair ? "" : "disabled"}
                        className="quizForm-matchUp-inputBar"
                        value={item.text}
                        placeholder={"Answer " + (index + 1)}
                        onChange={(e) => handleMatchPair(e, index + 1, "right")}
                      />
                      <br />
                    </>
                  ))}
                </div>
              </div>
              <button
                className="quizFrom-button"
                style={{
                  marginLeft: "1em",
                }}
                onClick={handleMatchUpScramble}
              >
                Scramble
              </button>
            </>
          ) : (
            " "
          )}
          {qustype == "Match the pairs picture based" ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                }}
              >
                {imageOptions.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: "1em",
                    }}
                  >
                    <label className="quizForm-matchUpPic-label">
                      Option {index + 1}
                    </label>
                    <input
                      className="quizForm-matchUpPic-inputBar"
                      disabled={disableImage ? "disabled" : ""}
                      value={item}
                      onChange={(e) => handleOptionsChange(e, index)}
                    />
                    {!disableImage && (
                      <input
                        type={"file"}
                        onChange={(e) => handleImageChange(e, index)}
                      />
                    )}
                    <img
                      style={{
                        width: "100px",
                        height: "auto",
                        borderRadius: "5px",
                        backgroundColor: "#D9D9D9",
                      }}
                      src={imageFiles[index].url}
                    />
                  </div>
                ))}
                <div>
                  <button
                    className="quizFrom-button"
                    style={{ margin: "1em" }}
                    onClick={scrambleImage}
                  >
                    Scramble
                  </button>
                </div>
              </div>
            </>
          ) : (
            " "
          )}





















{qustype == "Match the pairs audio based" ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                }}
              >
                {imageOptions.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: "1em",
                    }}
                  >
                    <label className="quizForm-matchUpPic-label">
                      Option {index + 1}
                    </label>
                    <input
                      className="quizForm-matchUpPic-inputBar"
                      disabled={disableImage ? "disabled" : ""}
                      value={item}
                      onChange={(e) => handleOptionsChange(e, index)}
                    />
                    {!disableImage && (
                      <input
                        type={"file"}
                        onChange={(e) => handleImageChange(e, index)}
                      />
                    )}
                    {/* <img
                      style={{
                        width: "100px",
                        height: "auto",
                        borderRadius: "5px",
                        backgroundColor: "#D9D9D9",
                      }}
                      src={imageFiles[index].url}
                    /> */}
                    <p>audio file {index}</p>
                  </div>
                ))}
                <div>
                  <button
                    className="quizFrom-button"
                    style={{ margin: "1em" }}
                    onClick={scrambleImage}
                  >
                    Scramble
                  </button>
                </div>
              </div>
            </>
          ) : (
            " "
          )}












          {qustype == "Choose the correct image" && (
            <>
              <div className="quizForm-mainForm-infoRow">
                <label className="quizForm-mainForm-label">
                  Correct Image:{" "}
                </label>
                <select
                  className="quizForm-mainForm-select"
                  value={imageMcqAnswer}
                  onChange={(e) => setImageMcqAnswer(e.target.value)}
                >
                  <option value={0}>Image 1</option>
                  <option value={1}>Image 2</option>
                  <option value={2}>Image 3</option>
                
                </select>
              </div>
              {imageMcq.map((val, index) => (
                <div className="quizForm-chooseImage-infoRow">
                  <label className="quizForm-chooseImage-label">
                    Image {index + 1}
                  </label>
                  <input
                    type={"file"}
                    onChange={(e) => handleImageMcqInput(e, index)}
                  />
                  <img
                    className="quizForm-chooseImage-image"
                    src={
                      val.blobUrl != ""
                        ? val.blobUrl
                        : "https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc="
                    }
                  />
                </div>
              ))}
            </>
          )}
          {qustype == "Picture MCQ" && (
            <>
              <div className="quizForm-mainForm-infoRow">
                <label className="quizForm-mainForm-label">
                  Choose the correct Option :
                </label>
                <select
                  className="quizForm-mainForm-select"
                  value={picMCQ.answer}
                  name={"answer"}
                  onChange={onPicMCQChange}
                >
                  <option value="0">Option 1</option>
                  <option value="1">Option 2</option>
                  <option value="2">Option 3</option>
                 
                </select>
              </div>
              <div className="quizForm-mainForm-infoRow">
                <label className="quizForm-mainForm-label">Select Image</label>
                <input type={"file"} onChange={setPicMCQFile} />
                {picMCQ.url && (
                  <img
                    src={picMCQ.url}
                    style={{
                      width: "250px",
                      height: "200px",
                      borderRadius: "5px",
                    }}
                  />
                )}
              </div>
              <div className="quizForm-mainForm-infoRow">
                <label className="quizForm-mainForm-label">Option 1 : </label>
                <input
                  className="quizForm-mainForm-inputBar"
                  name="option1"
                  value={picMCQ.option1}
                  placeholder="Enter Option"
                  onChange={onPicMCQChange}
                />
              </div>
              <div className="quizForm-mainForm-infoRow">
                <label className="quizForm-mainForm-label">Option 2 : </label>
                <input
                  className="quizForm-mainForm-inputBar"
                  name="option2"
                  value={picMCQ.option2}
                  placeholder="Enter Option"
                  onChange={onPicMCQChange}
                />
              </div>
              <div className="quizForm-mainForm-infoRow">
                <label className="quizForm-mainForm-label">Option 3 : </label>
                <input
                  className="quizForm-mainForm-inputBar"
                  name="option3"
                  value={picMCQ.option3}
                  placeholder="Enter Option"
                  onChange={onPicMCQChange}
                />
              </div>
              {/* <div className="quizForm-mainForm-infoRow">
                <label className="quizForm-mainForm-label">Option 4 : </label>
                <input
                  className="quizForm-mainForm-inputBar"
                  name="option4"
                  value={picMCQ.option4}
                  placeholder="Enter Option"
                  onChange={onPicMCQChange}
                />
              </div> */}
            </>
          )}
        </form>
        <div className="quizForm-mainForm-infoRow">
          <button
            className="quizFrom-button"
            style={{ marginLeft: "auto" }}
            onClick={storedDataOnFirebase}
          >
            Submit
          </button>
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <ul
              id="questionList"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                display: "block",
                overflow: "hidden",
                width: "100%",
                listStyleType: "none",
                margin: "0px",
                padding: "0px",
              }}
            >
              {list.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      key={item.id}
                    >
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreSharp />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{
                            backgroundColor: "#0B2D2D",
                            marginTop: "10px",
                            color: "white",
                            borderRadius: "5px",
                          }}
                        >
                          <Typography> {item.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <MdDelete
                            style={{ float: "right", margin: "20px" }}
                            size={28}
                            onClick={() => del(item)}
                          />
                          <MdEdit style={{float:"right", margin:"20px"}} size={28} onClick={()=>edit(item)}/>
                          <p> type: {item.type}</p>
                          <p> name : {item.name}</p>
                        </AccordionDetails>
                      </Accordion>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </section>
  );
};

export default CreateQuiz;
