import { t } from "i18next";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import "./CourseList.css";
import { getCookie } from "../../Cookies";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Cdata } from "./CourseData";
import { Checkbox } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../Firebase";
import { IP } from "../../config";
import axios from "axios";

function TrainInternalTrainEn() {
  const [courseArray, setCourseArray] = useState(null);
  const fetchCourses = httpsCallable(functions, "fetchCourses");
  const { clrs } = useSelector((state) => state.createClr);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const goToBack = () => navigate("/admin/training");

  console.log(useLocation());
  const ddddtata = useLocation();
  console.log(ddddtata);
  // useEffect(() => {
  //   if (getCookie("UID") == null || getCookie("ROLE") != "admin") {
  //     goToHome();
  //     return <></>;
  //   }
  // });

  const [arrLength, setArrLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    let configProfile = {
      method: "get",
      // params: { userId: uid },
      url: `${IP}/courseContent`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };

    axios(configProfile).then((res) => {
      ///  console.log(res.data)
      setCourseArray(res.data?.map((item) => ({ ...item, selected: false })));
      //  console.log(courseArray)
      setArrLength(res.data?.length);
    });
  }, []);

  const gotoEditPage = (state) => {
    console.log(state);
    navigate("/admin/courseBuilder", { state: { ...state } });
  };
  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };

  const SelectHand = (i) => {
    let newData;
    newData = courseArray.map((item, id) => {
      if (id === i) {
        item.selected = item.selected ? false : true;
        return item;
      } else {
        return item;
      }
    });
    if (newData) setCourseArray(newData);
  };
  return (
    <div className="courselist">
      <Navbar page={t("Training")} />
      <div
        className="courselist-main"
        style={{ gridTemplateColumns: "6vw 1fr" }}
      >
        <Sidebar page={"training"} hidepage={true} />
        <div className="courselist-main-cnt">
          <div className="courselist-main-cnt-top">
            <div className="courselist-main-cnt-top-txt"></div>

            <div>
              <Link to={"/admin/courseBuilder"}>
                <button
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                >
                  {t("AddNewCourses")}
                </button>
              </Link>
            </div>
          </div>
          <div className="course-head">{t("Courses")}</div>
          <div className="courselist-main-cnt-bottom">
            <div
              className="courselist-main-cnt-bottom-heading"
              style={{ textAlign: "center", padding: "0" }}
            >
              <div style={{ textAlign: "left", paddingLeft: "3vw" }}>
                {t("SNO")}
              </div>
              <div style={{ paddingLeft: "2vw" }}>{t("Trainings")}</div>
              <div>{t("Action")}</div>
            </div>
            <div className="admin-overdue-bottom">
              <div className="admin-overdue-bottom-table">
                {courseArray?.map((item, id) => {
                  if (id < pageSize * currentPage) return;
                  if (id >= pageSize * (currentPage + 1)) return;
                  return (
                    <div
                      className="admin-overdue-bottom-table-cnt-c "
                      style={{
                        backgroundColor: `${
                          item.selected
                            ? "rgba(0, 69, 119, 0.1)"
                            : "rgba(255, 255, 255, 1)"
                        }`,
                      }}
                      key={id}
                    >
                      <div className="admin-overdue-bottom-table-td">
                        <Checkbox
                          checked={item.selected}
                          onClick={() => SelectHand(id)}
                        />
                      </div>
                      <div
                        className="admin-overdue-bottom-table-td"
                        style={{
                          display: "grid",
                          justifyContent: "left",
                          alignItems: "center",
                        }}
                        id="c-div"
                      >
                        {id + 1}
                      </div>
                      <div className="admin-overdue-bottom-table-td" id="c-div">
                        {item.courseName}
                      </div>
                      <div className="admin-overdue-bottom-table-td" id="c-div">
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "3vw 3vw",
                          }}
                        >
                          <div>
                            <select
                              style={{
                                border: "none",
                                backgroundColor: "#F2F2F2",
                                height: "4vh",
                                width: "7vw",
                                padding: "0 1vh",
                                fontWeight: "700",
                                color: "#717579",
                                borderRadius: "6px",
                              }}
                              onChange={() => {
                                gotoEditPage(item);
                              }}
                            >
                              <option>select</option>
                              <option>Edit</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="admin-overdue-bottom-pagination">
                <div
                  className="admin-overdue-bottom-pagination-cnt"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "15px",
                  }}
                >
                  <div
                    className="admin-overdue-bottom-pagination-cnt-item"
                    style={{ marginRight: "10px" }}
                  >
                    <svg
                      onClick={() => clickhandler(currentPage - 1)}
                      width="auto"
                      height="22"
                      viewBox="0 0 14 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                        fill="#717579"
                      />
                    </svg>
                  </div>
                  <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                    <div className="admin-overdue-bottom-pagination-cnt-item">
                      {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                    </div>
                  </div>
                  <div
                    className="admin-overdue-bottom-pagination-cnt-item"
                    style={{ marginLeft: "10px" }}
                  >
                    <svg
                      onClick={() => clickhandler(currentPage + 1)}
                      width="auto"
                      height="20"
                      viewBox="0 0 13 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                        fill="#717579"
                      />
                    </svg>
                  </div>
                  <div
                    style={{ marginRight: "-10vw", marginLeft: "60px" }}
                    className="admin-overdue-bottom-pagination-cnt-item"
                  >
                    <label className="admin-row">{t("Show")}</label>
                    {/* className="rowInput" */}
                    <select
                      style={{
                        width: "4vw",
                        margin: "0.5vw",
                        marginBottom: "1.5vw",
                        height: "1.5vw",
                      }}
                      value={pageSize}
                      onChange={(e) => setPageSize(e.target.value)}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={15}>15</option>
                      <option value={20}>20</option>
                    </select>
                    <label className="admin-row"> {t("PagePerItem")}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="courselist-main-cnt-back">
            <div className="courselist-main-cnt-back-btn">
              <button
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                onClick={goToBack}
              >
                Back
              </button>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainInternalTrainEn;
