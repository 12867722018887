import React from "react";
import { NavbarData } from "./NavbarData";
import "./Navbar.css";

function Navbar() {
  return (
    <>
      <div className="Navbar">
        <ul className="SidebarList">
          {NavbarData.map((val, key) => {
            return (
              <li
                key={key}
                className="row"
                id={window.location.pathname == val.link ? "active" : ""}
                onClick={() => {
                  window.location.pathname = val.link;
                }}
              >
                <div id="title">{val.title}</div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default Navbar;
