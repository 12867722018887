import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, CircularProgress } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
// import { functions } from "../../../../Firebase";

const LoadingScreen = (props) => {
  const { onClose, open } = props;
  const handleClose = () => {
    onClose();
  };
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [loading, setLoading] = useState(false);

  const [inputId, setInputId] = useState("");

  let navigater = useNavigate();
  const runGenerateCertificateTraining = () => {
    /// navigater("/admin/course-reports")
  };

  return (
    <Dialog
      fullWidth={false}
      width="300px"
      PaperProps={{
        style: {
          borderRadius: "20px",
          height: "270px",
          padding: "16px",
          width: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // background: "",
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
        <div className="admin-overdue-bottom-table-td"></div>
        <div className="admin-overdue-bottom-table-td"></div>
        <div className="admin-overdue-bottom-table-td"></div>
        <div className="admin-overdue-bottom-table-td"></div>
        <div className="admin-overdue-bottom-table-td">
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        </div>
        <div className="admin-overdue-bottom-table-td"></div>
        <div className="admin-overdue-bottom-table-td"></div>
        <div className="admin-overdue-bottom-table-td"></div>
        <div
          className="admin-overdue-bottom-table-td"
          style={{ color: "#004577" }}
        ></div>
      </div>
    </Dialog>
  );
};

export default LoadingScreen;
