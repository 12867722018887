import React, { useEffect, useState } from "react";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadBytes,
  deleteObject,
} from "@firebase/storage";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import "./createQuiz.css";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import { ExpandMoreSvg } from "./icons";
import DeleteLesson from "./lessonDeletePopUp";
import { IP } from "../../../config";
import axios from "axios";
import { getCookie } from "../../../Cookies";
const ReadListenSpeck = ({
  quizId,
  questionData,
  editQuestion,
  setfetchDataFromApi,
  fetchDataFromApi,
  flagForSeconstEdit,
}) => {
  const [questionName, setQuestionName] = useState("");
  const [questionId, setQuestionId] = useState("");

  const initialList = [];
  const [list, setList] = useState(initialList);

  const [qustype, setqustype] = useState("9");
  const [qus, setqus] = useState("");
  const [qusInstruction, setqusInstruction] = useState("");
  const [PointorScore, setPointorScore] = useState("");
  const [qus5, setqus5] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });

  const [checkErrorInForm, setCheckErrorInForm] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    questionName: "",
    qusInstruction: "",
    qus: "",
    qus5Answer: "",
  });
  const [qus5Answer, setqus5Answer] = useState("Option 1");
  const [qus2, setqus2] = useState({ word: "", correctImage: "" });
  const [formCheck, setFormCheck] = useState("");
  function submit(e) {
    e.preventDefault();
  }

  useEffect(() => {
    if (!editQuestion) return;
    //  console.log(questionData)
    setqus(questionData.data?.question);
    setQuestionName(questionData?.name);
    setQuestionId(questionData?.id);
    setqusInstruction(questionData.data?.questionInstruction);

    if (questionData.data.options) {
      let arr = {};

      // textToSpeck: qus5.option4,
      // textToDisplay: qus5.option3,
      // npOfTries: qus5.option2,
      // passing: qus5.option1,
      setqus5({
        option1: questionData.data.options?.passing,
        option2: questionData.data.options?.npOfTries,
        option3: questionData.data.options?.textToDisplay,
        option4: questionData.data.options?.textToSpeck,
      });
    }
  }, [flagForSeconstEdit]);

  const formCheckFunction = () => {
    if (questionName === "") {
      setCheckErrorInForm((prev) => ({ ...prev, questionName: "error" }));
      return "question Name";
    }

    if (qus === "") {
      setCheckErrorInForm((prev) => ({ ...prev, qus: "error" }));
      return "question ";
    }
    if (qusInstruction === "") {
      setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "error" }));
      return "question ";
    }

    if (qus5.option1 === "") {
      setCheckErrorInForm((prev) => ({ ...prev, option1: "error" }));
      return "question option1";
    }
    if (qus5.option2 === "") {
      setCheckErrorInForm((prev) => ({ ...prev, option2: "error" }));
      return "question option2";
    }
    if (qus5.option3 === "") {
      setCheckErrorInForm((prev) => ({ ...prev, option3: "error" }));
      return "question option3";
    }
    if (qus5.option4 === "") {
      setCheckErrorInForm((prev) => ({ ...prev, option4: "error" }));
      return "question option4";
    }
    if (qus5Answer === "") {
      setCheckErrorInForm((prev) => ({ ...prev, qus5Answer: "error" }));
      return "question Answer";
    }

    return "alliswell";
  };

  const storedDataOnFirebase = async () => {
    let check = formCheckFunction();

    let nsn = questionName.replaceAll(" ", "");

    let dat = /\//i.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any (/) characters");
      return;
    }
    if (check != "alliswell") {
      setFormCheck("check");
      return;
    }

    setFormCheck("");
    let questionTypeEnum;
    let managequestionData;
    let updatedOptions = {
      textToSpeck: qus5.option4,
      textToDisplay: qus5.option3,
      npOfTries: qus5.option2,
      passing: qus5.option1,
    };
    let questionDatafromUser = {
      options: updatedOptions,
      question: qus,
      questionInstruction: qusInstruction,
    };
    const docData = {
      questionName: questionName,
      questionId: questionId,
      associatedQuiz: quizId,
      questionSet: quizId,
      data: managequestionData,
      questionType: qustype,
      created: Timestamp.now(),
    };
    //console.log(docData);

    let configCreateExtraInfo = {
      method: "post",
      // params: { questionSetId: quizId },
      url: `${IP}/courseBuilder/question`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify({
        questionId: questionId,
        questionName: questionName,
        questionType: qustype,
        created: new Date().toString(),
        questionData: questionDatafromUser,
        questionPoolAssociated: quizId,
      }),
    };
    axios(configCreateExtraInfo).then((res) => {
      alert("data saved successfully");
      setfetchDataFromApi(fetchDataFromApi + 1);
    });

    toggleQuestionForm();

    document.getElementById(quizId + "question-form").reset();
    document.getElementById(quizId + "questionName").value = "";
  };

  const toggleQuestionForm = (e) => {
    resetData();
    document.getElementById(quizId + "questionForm").style.display =
      document.getElementById(quizId + "questionForm").style.display == "none"
        ? "block"
        : "none";
  };

  function resetData() {
    setQuestionName("");
    setQuestionId("");
    setqustype("9");
    setqus("");
    setqusInstruction("");
    setqus5({
      option1: "",
      option2: "",
      option3: "",
      option4: "",
    });
    setqus5Answer("Option 1");
  }

  return (
    <section className="quizForm-container">
      <form id={quizId + "question-form"} onSubmit={submit}>
        <div className="quizForm-mainForm-infoRow">
          <p className="quizForm-mainForm-label">Question Name :</p>
          <input
            type="text"
            className="quizForm-mainForm-inputBar"
            value={questionName}
            id={quizId + "questionName"}
            style={{
              border: `${
                checkErrorInForm.questionName == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(e) => {
              setQuestionName(e.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, questionName: "" }));
              let value = e.target.value.replaceAll(" ", "");
              let time = new Date().getTime();
              if (!editQuestion) {
                setQuestionId(value + "_" + time);
              }
            }}
          />

          {checkErrorInForm.questionName == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Name
            </span>
          )}
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Question : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qus}
            style={{
              border: `${
                checkErrorInForm.qus == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqus(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qus: "" }));
            }}
          ></textarea>

          {checkErrorInForm.qus == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question
            </span>
          )}
        </div>
        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Instruction : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qusInstruction}
            style={{
              border: `${
                checkErrorInForm.qusInstruction == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqusInstruction(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "" }));
            }}
          ></textarea>

          {checkErrorInForm.qusInstruction == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Instruction
            </span>
          )}
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Text to speak : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qus5.option4}
            style={{
              border: `${
                checkErrorInForm.option4 == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqus5({ ...qus5, option4: event.target.value });
              setCheckErrorInForm((prev) => ({ ...prev, option4: "" }));
            }}
          ></textarea>

          {checkErrorInForm.option4 == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Text to speak
            </span>
          )}
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Text to display : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qus5.option3}
            style={{
              border: `${
                checkErrorInForm.option3 == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqus5({ ...qus5, option3: event.target.value });
              setCheckErrorInForm((prev) => ({ ...prev, option3: "" }));
            }}
          ></textarea>

          {checkErrorInForm.option3 == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Text to display
            </span>
          )}
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Passing Score : </label>
          <input
            className="quizForm-mainForm-inputBar"
            name="text"
            value={qus5.option1}
            placeholder="Enter Passing Score"
            style={{
              border: `${
                checkErrorInForm.option1 == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqus5({ ...qus5, option1: event.target.value });
              setCheckErrorInForm((prev) => ({ ...prev, option1: "" }));
            }}
          />
          {checkErrorInForm.option1 == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Passing Score
            </span>
          )}
        </div>
        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">No Of Tries : </label>
          <input
            className="quizForm-mainForm-inputBar"
            name="text"
            value={qus5.option2}
            placeholder="Enter No Of Tries"
            style={{
              border: `${
                checkErrorInForm.option2 == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqus5({ ...qus5, option2: event.target.value });
              setCheckErrorInForm((prev) => ({ ...prev, option2: "" }));
            }}
          />
          {checkErrorInForm.option1 == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question No Of Tries
            </span>
          )}
        </div>
      </form>
      {formCheck && <div> Fill all the Required fields.</div>}
      <div className="quizForm-mainForm-infoRow">
        <button
          className="quizFrom-button"
          style={{ marginLeft: "auto" }}
          onClick={storedDataOnFirebase}
        >
          Submit
        </button>
      </div>
    </section>
  );
};

export default ReadListenSpeck;
