import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setCookie } from "../../../Cookies";
import { db } from "../../../Firebase";
import loginImg from "../../../utilities/images/login-img.png";
import classes from "../../styles/LoginpageStyle/LoginPage.module.css";
import loginLogo from "../img/logo/logo.png";
import { createClrs } from "../../Redux/Actions";
import { blue } from "../../Redux/Colors";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IP } from "../../../config";
import axios from "axios";
import { useDispatch } from "react-redux";

const { createTheme } = require("@mui/material");

const loginTheme = createTheme({
  typography: {
    fontFamily: ["Nunito", "sans-serif"].join(","),
  },
});

function LoginPage() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const goToUserDashboard = () => navigate("/user/home");
  const goToAdminDashboard = () => navigate("/admin");
  const goToInstructorDashboard = () => navigate("/instructor");
  const [role, setRole] = useState("");
  const [user, setUser] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [userNameError, setUserNameError] = useState("");

  ///========visibility password=======//
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const clearInputs = () => {
    setUserNameError("");
    setPassword("");
  };

  const clearErrors = () => {
    setUserNameError("");
    setPasswordError("");
  };

  const handleLogin = () => {
    clearErrors();
    // const auth = getAuth();
    let configProfile = {
      method: "post",
      // params: { userId: uid },
      url: `${IP}/auth/login`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.idToken}`,
      },
      data: JSON.stringify({
        email: userName,
        password: password,
      }),
    };

    axios(configProfile)
      .then((userCredential) => {
        const user = userCredential.data;
        setCookie("bearer", user.idToken);
        setCookie("rfToken", user.refreshToken);

        let configProfile = {
          method: "get",
          // params: { userId: uid },
          url: `${IP}/user/profile`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.idToken}`,
          },
          data: "dataexp",
        };

        axios(configProfile).then((user) => {
          setCookie("UID", user.uid);
          const role = user.data.role;
          const name = user.data.name;
          setCookie("ROLE", role);
          setCookie("NAME", name);
          // setRole(role);
          // const name = user.data().name;
          // setCookie("NAME", name);
          // const empId = user.data().employeeId;
          // setCookie("EMPID", empId);
          // // console.log("Role=" + role);
          goToAdminDashboard();
          // else if (role == "instructor") goToInstructorDashboard();
          // else if (role == "user") goToUserDashboard();
        });
      })
      .catch((err) => {
        switch (err.code) {
          case "auth/invalid-username":
          case "auth/user-disabled":
          case "auth/user-not-found":
            setUserNameError(err.message);
            break;
          case "auth/wrong-password":
            setPasswordError(err.message);
            break;
        }
      });
  };

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        goToHome();
      })
      .catch((error) => {
        // An error happened.
      });
    //   fire.auth().signOut();
  };

  useEffect(() => {
    // authListener();

    dispatch(createClrs(blue));
  }, []);
  const authListener = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        clearInputs();
        const uid = user.uid;
        // console.log(uid);
        setUser(user);
      } else {
        setUser("");
      }
    });
  };

  useEffect(() => {
    authListener();
  }, []);

  return (
    <ThemeProvider theme={loginTheme}>
      <Box>
        <Container
          style={{ height: "100vh" }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "50%",
              border: "3px solid #007848",
              borderRadius: "10px",
              minHeight: "650px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img className={classes.logo} src={loginLogo} alt="" />
            </Box>
            <Box sx={{ p: 7 }}>
              <Box>
                <Typography
                  variant="h3"
                  sx={{ color: "#007848", fontWeight: 700 }}
                >
                  Log in
                </Typography>
                <Typography sx={{ my: 2, color: "#007848" }}>
                  Log in with your credentials provided by admin
                </Typography>
              </Box>
              <form action="">
                <Box>
                  <Typography
                    sx={{ fontWeight: "700", mb: 1, color: "#007848" }}
                  >
                    Enter Your Username
                  </Typography>
                  <div
                    style={{
                      width: "100%",
                      borderRadius: "6px",
                      padding: "10px 15px",
                      border: "1px solid #007848",
                      outline: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="text"
                      required
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      placeholder="name@domain.com"
                      style={{
                        width: "100%",
                        padding: "7px 15px",
                        border: "none",
                        outline: "none",
                      }}
                    />
                  </div>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Typography
                    sx={{ fontWeight: "700", mb: 1, color: "#007848" }}
                  >
                    Enter Your Password
                  </Typography>
                  <div
                    style={{
                      width: "100%",
                      borderRadius: "6px",
                      padding: "10px 15px",
                      border: "1px solid #007848",
                      outline: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{ border: "none", outline: "none", width: "90%" }}
                    />
                    <div onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <VisibilityIcon sx={{ color: "#007848" }} />
                      ) : (
                        <VisibilityOffIcon sx={{ color: "#007848" }} />
                      )}
                    </div>
                  </div>
                </Box>

                <Box sx={{ mt: 3, display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox color="success" sx={{ color: "#007848" }} />
                    }
                  />
                  <label
                    style={{
                      fontWeight: 700,
                      color: "#007848",
                      fontSize: "16px",
                      marginLeft: "-20px",
                    }}
                  >
                    Keep me logged in
                  </label>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Button
                    onClick={handleLogin}
                    style={{
                      background: "linear-gradient(to right, #007848, #004577)",
                      color: "#fff",
                    }}
                    sx={{
                      textTransform: "none",
                      width: "100%",
                      py: 1,
                      borderRadius: "15px",
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    Log in
                  </Button>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Typography
                    style={{ color: "#007848", fontWeight: 600 }}
                    sx={{ textAlign: "center" }}
                  >
                    Forgot Password?
                  </Typography>
                </Box>
              </form>
            </Box>
          </Box>
          <Box sx={{ width: "50%" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={loginImg}
              alt=""
            />
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default LoginPage;

/* <div className="loginpage">
  <div className="loginpage-main">
    <div className="loginpage-main-left">
      <div className="loginpage-main-left-cnt">
        <div className="loginpage-main-left-cnt-items">
          <div className="loginpage-main-left-cnt-items-text-1">Log in</div>
          <div className="loginpage-main-left-cnt-items-text-2">
            Log in with your credentials provided by admin
          </div>
          <div className="loginpage-main-left-cnt-items-text-3">
            Enter Your Username
          </div>
          <div className="loginpage-main-left-cnt-items-input">
            <input type="text" placeholder="name@domain.com" required
              value={userName}
              onChange={(e) => setUserName(e.target.value)} />
            <p className="errorMsg">{userNameError}</p>
          </div>
          <div className="loginpage-main-left-cnt-items-text-4">
            Enter Your Password
          </div>
          <div className="loginpage-main-left-cnt-items-input">
            <input type="password" placeholder="At least 8 charactor" required
              value={password}
              onChange={(e) => setPassword(e.target.value)} />
            <p className="errorMsg"> {passwordError}</p>
          </div>
          <div className="loginpage-main-left-cnt-items-text-5">
            <FormControlLabel
              control={<Checkbox color="success" />}
              label="Keep me logged in"
            />
          </div>
          <div className="loginpage-main-left-cnt-items-login-btn">
            <button onClick={handleLogin}>Log in</button>
          </div>
          <div className="loginpage-main-left-cnt-items-text-6">
            Forgot Password?
          </div>
        </div>
      </div>
    </div>
    <div className="loginpage-main-right">
      <div className="loginpage-main-right-cnt">
        <img src={loginImg} alt="loading" />
      </div>
    </div>
  </div>
</div> */
